import GaugeChart from "react-gauge-chart";
import { makeStyles } from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles(() => ({
  container: {
    height: "80%",
  },
}));

export default function Gauge() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <GaugeChart id="gauge-chart6" animate={false} nrOfLevels={15} percent={0.5} needleColor="#345243" />
    </div>
  );
}
