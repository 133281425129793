import React, { useState } from "react";

//Material UI
import { Box, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

interface Props {
  onNameChange: (value: string) => void;
  onValueChange: (value: string) => void;
}

export default function Attribute({ onNameChange, onValueChange }: Props) {
  const { t } = useTranslation();
  const [value, setValue] = useState("");
  const [name, setName] = useState("");

  const handleNameChange = (value: string) => {
    setName(value);
    onNameChange(value);
  };

  const handleValueChange = (value: string) => {
    setValue(value);
    onValueChange(value);
  };

  return (
    <>
      <Box component="div">
        <Box component="div" p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.addproperty.value.label")}
        </Box>
        <TextField
          size="small"
          sx={Style.inputTextfield}
          id="propertyValue"
          onChange={(e) => {
            handleValueChange(e.target.value as string);
          }}
          value={value}
        />
      </Box>
      <Box component="div">
        <Box component="div" p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.addproperty.name.label")}
        </Box>
        <TextField
          size="small"
          sx={Style.inputTextfield}
          id="propertyName"
          onChange={(e) => {
            handleNameChange(e.target.value as string);
          }}
          value={name}
        />
      </Box>
    </>
  );
}
