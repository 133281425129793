import { FixedWidgetLayout } from "../../components/Dashboard";
import { PartPickerWidget } from "./PartPickerWidget";
import { Caster3DWidget } from "./Caster3DWidget";

export const Caster3DWithPartPickerWidget = FixedWidgetLayout({
  displayName: "Caster3DWithPartPickerWidget",
  cols: 1,
  rows: 80,
  margin: [0, 0],
  containerPadding: [10, 10],
  widgetConfigs: {
    Caster3DWithPartPickerWidget_w1: {
      widget: PartPickerWidget,
      layout: { x: 0, y: 0, h: 5, w: 1 },
      config: { mapProps: {}, constants: {} },
    },
    Caster3DWithPartPickerWidget_w2: {
      widget: Caster3DWidget,
      layout: { x: 0, y: 1, h: 75, w: 1 },
      config: { mapProps: {}, constants: {} },
    },
  },
});
