/*
    Runner Cockits and Dashboards
    Receive an object and display properly
*/
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import GridLayout from "react-grid-layout";
import { Pannel, PannelLocation, Presentation } from "../../app/models/presentation";

import { RUNNER_COLS, RUNNER_ROW_HEIGHT, RUNNER_WIDTH } from "../../constants";

// this is used to select cockpit items.
const useStyles = makeStyles({
  container: {
    width: "100%",
    color: "#BBB",
    borderWidth: "1.5px",
    borderStyle: "solid",
  },
  header: {
    width: "100%",
    textAlign: "center",
  },
  title: {
    paddingLeft: "15px",
    fontStretch: "bold",
  },
  cardHeader: {
    textAlign: "center",
    color: "#000",
  },
});

interface Props {
  renderChildren: (panel: Pannel) => {};
  presentation: Presentation;
  isDraggable?: boolean;
  isResizable?: boolean;
  isBounded?: boolean;
}

const Runner = ({ renderChildren, presentation, isDraggable, isResizable }: Props) => {
  const [layout, setLayout] = useState<PannelLocation[]>([]);
  // set specific items on dashboard.
  const prepareLayout = (presentation: Presentation) => {
    setLayout(
      presentation.pannels.map(
        (pannel: Pannel) =>
          ({
            ...pannel.pannelLocation,
          } as unknown as PannelLocation)
      )
    );
  };

  useEffect(() => {
    prepareLayout(presentation);
  }, [presentation]);

  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6}>
        <GridLayout
          className="layout"
          margin={[1, 1]}
          layout={layout}
          cols={RUNNER_COLS}
          rowHeight={RUNNER_ROW_HEIGHT}
          width={RUNNER_WIDTH}
          isDraggable={isDraggable}
          isResizable={isResizable}
        >
          {presentation.pannels.length > 0 &&
            presentation.pannels.map((pannel) => {
              return (
                <div id="divPanel" className={classes.container} key={pannel.id}>
                  <div id="cardHeader" className={classes.cardHeader}>
                    {pannel.name}
                  </div>
                  {renderChildren(pannel)}
                </div>
              );
            })}
        </GridLayout>
      </Grid>
    </>
  );
};

export default Runner;
