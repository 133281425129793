import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Autocomplete, Typography, TextField, CircularProgress, Button, Grid, Box } from "@mui/material";
import PositionHistoryTable from "./components/PositionHistory";
import { installationTreeResource } from "../../../../services/suspenders";
import { useTranslation } from "react-i18next";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Style } from "../../../../themes/Style";
import TextfieldDiv from "../../../../components/textfieldDiv";

interface Props {
  partId: string;
  position: string | null;
  setPosition: (position: string | null) => void;
}

export default function InstallationPlaces({ position, setPosition, partId }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { installableParts } = installationTreeResource.read();
  const part = installableParts[partId];
  const installationPositions = part.installationPostions;
  const positionArray = Object.values(installationPositions).map((x) => ({
    label: x.name,
    id: x.id,
  }));

  const selectedPosition = positionArray.find((x) => x.id === position);

  const positionAttr = installationPositions.find((x) => x.id === position);

  return (
    <Box sx={{ width: "100%", height: "100%" }} component="div">
      <Autocomplete
        sx={Style.Autocomplete}
        popupIcon={<KeyboardArrowDown />}
        onChange={(event: any, newValue: any) => {
          if (newValue != null) {
            setPosition(newValue.id);
          } else {
            setPosition(null);
          }
        }}
        value={selectedPosition}
        disablePortal
        id="combo-box-demo"
        options={positionArray}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("castersetup.installationPlaces.selectAPosition")}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
          ></TextField>
        )}
      />
      {positionAttr ? (
        <div
          style={{
            marginTop: 5,
            overflow: "auto",
          }}
        >
          <Grid container item>
            <TextField
              style={{ width: "100%" }}
              variant="standard"
              label={t("grid.label.id")}
              value={positionAttr.id}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
            <TextfieldDiv
              style={{ width: "100%" }}
              label={t("castersetup.installationPlaces.currentlyInstalled")}
              sx={Style.Textfield2}
            >
              {positionAttr.currentlyInstalled ? (
                <Button
                  sx={Style.linkButton}
                  size="large"
                  onClick={() => {
                    history.push(`../caster/${positionAttr.currentlyInstalled!.id}`);
                    setPosition(null);
                  }}
                >
                  {`${positionAttr.currentlyInstalled.name}`}
                </Button>
              ) : (
                <p>{t("castersetup.installationPlaces.noPartInstalled")}</p>
              )}
            </TextfieldDiv>
            <TextfieldDiv
              style={{ width: "100%" }}
              label={t("castersetup.installationPlaces.canBeInstalled")}
              sx={Style.Textfield2}
            >
              {positionAttr.installableParts.length !== 0 ? (
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  {positionAttr.installableParts.map((inspart) => (
                    <Grid key={inspart.id} item>
                      <Button
                        sx={Style.linkButton}
                        size="large"
                        onClick={() => {
                          if (positionAttr.installableParts) {
                            history.push(`../caster/${inspart.id}`);
                            setPosition(null);
                          }
                        }}
                      >
                        {inspart.name}
                        {positionAttr.name}
                      </Button>
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <p>{t("castersetup.installationPlaces.noPartAvailable")}</p>
              )}
            </TextfieldDiv>
            <TextfieldDiv
              style={{ width: "100%", height: "100%" }}
              label={t("castersetup.installationPlaces.installationHistory")}
              sx={Style.Textfield2}
            >
              <Suspense fallback={<CircularProgress />}>
                <PositionHistoryTable positionId={position} />
              </Suspense>
            </TextfieldDiv>
          </Grid>
        </div>
      ) : (
        <Typography>{t("castersetup.installationPlaces.noPositionSelected")}</Typography>
      )}
    </Box>
  );
}

InstallationPlaces.widgetPropTypes = {
  position: PropTypes.string,
  setPosition: PropTypes.func.isRequired,
  partId: PropTypes.string.isRequired,
};
