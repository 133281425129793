import { createTheme } from "@material-ui/core/styles";
import "./app.css";

export const Colors = {
  blue: "#0075be",
  black: "#000000",
  interactivePrimary: "#2B7CD8",
  interactiveSecondary: "#4D4D4D",
  pageBackground: "#F2F2F2",
  pageBackgroundSecondary: "#FFFFFF",
  pageBackgroundTertiary: "#E5E5E5",
  lightBackground: "#F2F2F2",
  border: "#E5E5E5",
  selectedElement: "#2B7CD8",
  textPrimary: "#333333",
  textSecondary: "#666666",
  textPlaceholder: "#CCCCCC",
  textInteractiveElement: "#FFFFFF",
  textTertiary: "#8C8C8C",
  mediumContrastBorder: "#BFBFBF",
  link: "#2B7CD8",
  buttonHover: "#d3d3d3",
};

const blue = "#0075be";
const black = "#000000";
const interactivePrimary = "#2B7CD8";
const interactiveSecondary = "#4D4D4D";
const pageBackground = "#F2F2F2";
const pageBackgroundSecondary = "#FFFFFF";
const pageBackgroundTertiary = "#E5E5E5";
const lightBackground = "#F2F2F2";
const border = "#E5E5E5";
const selectedElement = "#2B7CD8";
const textPrimary = "#333333";
const textSecondary = "#666666";
const textPlaceholder = "#CCCCCC";
const textInteractiveElement = "#FFFFFF";
const textTertiary = "#8C8C8C";
const mediumContrastBorder = "#BFBFBF";
const link = "#2B7CD8";

const theme = {
  palette: {
    common: {
      black: `${black}`,
    },
    primary: {
      main: `${blue}`,
    },
    secondary: {
      main: `${black}`,
    },
  },
  overrides: {
    MuiDrawer: {
      paper: {
        background: "#18202c",
      },
    },
    MuiButton: {
      root: {
        borderRadius: 0,
      },
    },
  },
  props: {
    MuiTextField: {
      margin: "dense",
      variant: "outlined",
    },
    MuiSelect: {
      margin: "dense",
      variant: "outlined",
    },
  },
  drawerWidth: 240,
  interactivePrimary: interactivePrimary,
  interactiveSecondary: interactiveSecondary,
  pageBackground: pageBackground,
  pageBackgroundSecondary: pageBackgroundSecondary,
  pageBackgroundTertiary: pageBackgroundTertiary,
  lightBackground: lightBackground,
  border: border,
  selectedElement: selectedElement,
  textPrimary: textPrimary,
  textSecondary: textSecondary,
  textPlaceholder: textPlaceholder,
  textInteractiveElement: textInteractiveElement,
  textTertiary: textTertiary,
  mediumContrastBorder: mediumContrastBorder,
  link: link,

  darkButton: {
    textTransform: "none",
    backgroundColor: interactiveSecondary,
    color: textInteractiveElement,
    fontWeight: "normal",
  },

  tab: {
    "&.MuiTab-root": {
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: pageBackgroundTertiary,
      "&.MuiTab-textColorPrimary": {
        color: textSecondary,
      },
      "&.Mui-selected": {
        fontWeight: "normal",
        textTransform: "none",
        backgroundColor: pageBackgroundSecondary,
        "&.MuiTab-textColorPrimary": {
          color: textPrimary,
        },
      },
    },
  },
  innerTab: {
    "&.MuiTab-root": {
      fontWeight: "normal",
      textTransform: "none",
      backgroundColor: pageBackgroundTertiary,
      borderRight: `1px solid ${mediumContrastBorder}`,
      borderLeft: `1px solid ${mediumContrastBorder}`,
      "&.MuiTab-textColorPrimary": {
        color: textSecondary,
      },
      "&.Mui-selected": {
        fontWeight: "normal",
        textTransform: "none",
        backgroundColor: pageBackgroundSecondary,
        borderRight: `1px solid ${mediumContrastBorder}`,
        borderLeft: `1px solid ${mediumContrastBorder}`,
        "&.MuiTab-textColorPrimary": {
          color: textPrimary,
        },
      },
    },
  },
  autocompleteTextField: {
    backgroundColor: lightBackground,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        borderBottom: `2px solid ${mediumContrastBorder}`,
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: selectedElement,
      },
    },
  },
  selectInput: {
    backgroundColor: lightBackground,

    "& fieldset": {
      borderRadius: 0,
      borderBottom: `2px solid ${mediumContrastBorder}`,
      borderTopColor: "transparent",
      borderLeftColor: "transparent",
      borderRightColor: "transparent",
    },

    "&.Mui-focused fieldset": {
      borderColor: selectedElement,
    },
  },

  dataGrid: {
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: pageBackgroundTertiary,
    },
    "& .MuiDataGrid-row": {
      backgroundColor: pageBackground,
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: pageBackground,
    },
    "& .MuiDataGrid-main": {
      backgroundColor: pageBackground,
    },
  },

  actionButton: {
    textTransform: "none",
    fontWeight: "normal",
    color: link,
  },
} as const;

type CustomTheme = {
  [Key in keyof typeof theme]: typeof theme[Key];
};

declare module "@material-ui/core/styles" {
  interface Theme extends CustomTheme {}
  interface ThemeOptions extends CustomTheme {}
}

export default createTheme(theme);
