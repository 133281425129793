/* eslint-disable react/prop-types */
import React, { useEffect, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TrafficLigth from "./traffic";
import { colorScheme } from "../../../../components/ResizeblePanels/components/panels/colorScheme";
import { RUNNER_ROW_HEIGHT } from "../../../../constants";
import { Line } from "react-chartjs-2";

interface Props {
  alarmCode?: number;
  chartData: any;
  nodeName: string;
  panel: any;
}

const CardChart = ({ alarmCode, chartData, nodeName, panel }: Props) => {
  const useStyles = makeStyles(() => ({
    root: {
      flexGrow: 1,
    },
    lighContainer: {
      width: "100%",
      minWidth: "220px",
      height: "25%",
      paddingTop: "10px",
      paddingBottom: "20px",
    },
    chartContainer: {
      width: "98%",
      maxHeight: panel.h * RUNNER_ROW_HEIGHT,
      height: "60%",
    },
    title: {
      color: "black",
      width: "100%",
      textAlign: "center",
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();
  const { data } = chartData ?? [];
  const [alarmText, setAlarmText] = useState("");

  const [chartContent, setChartContent] = useState({});

  const prepareDataSet = useCallback(
    (day: any, datasets: any[]) => {
      if (!chartData) return [];

      for (let index = 0; index <= day.length; index++) {
        if (!datasets[index]) {
          let datasetItem = {
            data: [day[index + 1]],
            fill: false,
            key: index,
            label: chartData.header[index + 1],
            borderColor: colorScheme[index],
            option: {
              legend: false,
            },
          };
          if (datasetItem.label) {
            datasets.push(datasetItem);
          }
        } else {
          datasets[index].data.push(day[index + 1]);
        }
      }
      return datasets;
    },
    [chartData]
  );

  const prepareDatasources = useCallback(() => {
    if (!chartData) return;
    let currentLabels = [];
    let datasets: any[] = [];

    if (data && Array(data).length > 0) {
      currentLabels = data.map((data: any) => data[0]);
      data.forEach((day: any) => {
        if (day[1] !== undefined) {
          if (Array(day) && !day[1].length) {
            datasets = prepareDataSet(day, datasets);
          } else {
            datasets = prepareDataSet(day[1], datasets);
          }
        }
      });
    }

    setChartContent({
      labels: currentLabels,
      datasets: datasets,
    });
  }, [chartData, data, prepareDataSet]);

  useEffect(() => {
    prepareDatasources();
  }, [data, prepareDatasources]);

  const updateAlarmText = useCallback(() => {
    if (alarmCode === undefined) setAlarmText("-");
    switch (alarmCode) {
      case 0:
        setAlarmText("ok");
        break;
      case 1:
        setAlarmText("caution, part might have problems");
        break;
      case 2:
        setAlarmText("problem with part");
        break;
      default:
        setAlarmText("");
    }
  }, [alarmCode]);

  useEffect(() => {
    updateAlarmText();
  }, [alarmCode, updateAlarmText]);

  const options = {
    legend: {
      display: true,
    },
  };

  return (
    <>
      <Grid container className={classes.root} spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="center" spacing={2}>
            <Grid item>
              <TrafficLigth active={alarmCode} />
            </Grid>
            <Grid item>
              <div className={classes.lighContainer}>{alarmText}</div>
            </Grid>
          </Grid>
        </Grid>{" "}
      </Grid>
      <div className={classes.chartContainer}>
        <div className={classes.title}>{nodeName}</div>
        <Line
          key={nodeName}
          options={options as any}
          height={RUNNER_ROW_HEIGHT * panel.h * 0.25}
          data={chartContent as any}
        />
      </div>
    </>
  );
};

export default CardChart;
