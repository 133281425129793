import { Presentation } from "../app/models/presentation";
import axios, { AxiosResponse } from "axios";
import { enPermission, enRoles } from "../app/models/permissions";
import PermissionsService from "../services/permissionsService";
import { loggedUser } from "../services/auth";

const baseUrl = "/Presentations/Presentation";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

export interface PresentationAPiResponseError {
  succeeded: boolean;
  data: boolean;
  message: string;
  errors: {
    key: string;
  };
}

const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Presentations = {
  details: async (id: string) => request.get<Presentation>(`${baseUrl}/${id}`),
  list: async (ContentType: string) => {
    if (
      PermissionsService.isAdmin() ||
      enRoles.DASHBOARDS_ADMIN ||
      enPermission.DASHBOARDS_READ ||
      enPermission.COCKPITS_READ ||
      enRoles.COCKPITS_ADMIN
    ) {
      return request.get<Presentation[]>(`${baseUrl}?ContentType=${ContentType}`);
    } else {
      let userId = loggedUser().id;
      return request.get<Presentation[]>(`${baseUrl}/user/${userId}?ContentType=${ContentType}`);
    }
  },
  create: (presentation: Presentation) => request.post<void>(`${baseUrl}/`, presentation),
  update: (presentation: Presentation) => request.put<void>(`${baseUrl}/${presentation._id}`, presentation),
  delete: (id: string) => request.delete<void>(`${baseUrl}/${id}`),
};

const Agent = {
  Presentations,
};

export default Agent;
