import React, { useState, useEffect, useCallback } from "react";
import GridLayout from "react-grid-layout";

import { Pannel, PannelLocation, Presentation } from "../../../app/models/presentation";

import { RUNNER_COLS, RUNNER_ROW_HEIGHT, RUNNER_WIDTH } from "../../../constants";
import CardItem from "./cardItem";

interface Props {
  items: Pannel[];
  presentation: Presentation;
  onItemRemoved: (id: string) => void;
  onNeedSave: (needChange: boolean) => {};
  onLayoutChange: (sender: any) => {};
  setInitialLayout: boolean;
  editable: boolean;
  removable: boolean;
}

const Prepare = ({
  items,
  onItemRemoved,
  onNeedSave,
  onLayoutChange,
  editable = true,
  removable = true,
}: Props) => {
  const [layout, setLayout] = useState<PannelLocation[]>([]);

  const [layoutReady, setLayoutReady] = useState(false);

  const getNewItemPosition = (id: string, visualization_type: string, lastPosition: number) => {
    return {
      i: id,
      x: (lastPosition * 2) % 12,
      y: Infinity, // puts it at the bottom
      w: visualization_type === "e-doc" ? 9 : 3,
      h: visualization_type === "e-doc" ? 12 : 4,
      minW: visualization_type === "e-doc" ? 9 : 3,
      minH: visualization_type === "e-doc" ? 12 : 4,
    };
  };

  const handleRemoveItem = (id: string) => {
    const filterd = layout.filter((l) => l.i !== id);
    setLayout([...filterd]);
    onItemRemoved(id);
  };

  const prepareLayout = useCallback(() => {
    setLayoutReady(false);
    const newLayout: PannelLocation[] = [];
    items.forEach((item) => {
      const itemExists = layout.find((prev) => prev.i === item.id);
      if (itemExists) {
        newLayout.push(itemExists);
      } else {
        const newItem = item.pannelLocation
          ? item.pannelLocation
          : getNewItemPosition(item.id, item.visualization_type, newLayout.length);
        newLayout.push(newItem);
      }
    });
    setLayout(newLayout);
    setLayoutReady(true);
  }, [items, layout]);

  useEffect(() => {
    if (Array.isArray(items) && items.length > 0) {
      prepareLayout();
    }
  }, [items]);

  const onLayoutChanged = (sender: any) => {
    setLayout(sender);
    onLayoutChange(sender);
    onNeedSave(true);
  };

  return (
    <>
      {items && Array(items).length > 0 && layoutReady && (
        <>
          <GridLayout
            onLayoutChange={onLayoutChanged}
            className="layout"
            layout={layout}
            cols={RUNNER_COLS}
            rowHeight={RUNNER_ROW_HEIGHT}
            width={RUNNER_WIDTH}
            isDraggable={editable}
            isResizable={editable}
            margin={[1, 1]}
          >
            {items.map((item) => {
              return (
                <div key={item.id}>
                  <CardItem
                    cockpitItem={item}
                    key={item.id}
                    onRemoveItem={handleRemoveItem}
                    removable={removable}
                  />
                </div>
              );
            })}
          </GridLayout>
        </>
      )}
    </>
  );
};

Prepare.defaultProps = {
  onItemRemoved: () => {},
};

export default Prepare;
