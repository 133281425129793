import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Snackbar,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from "@mui/material";
import PlantviewBody from "./Body";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

interface Props {
  iconLaunch: boolean;
}
export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function Plantviews({ iconLaunch }: Props) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const handleSave = async () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  function launchButton() {
    if (iconLaunch) {
      return (
        <IconButton aria-label="addprop" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      );
    } else {
      return (
        <Button sx={Style.darkButton} variant="contained" onClick={handleClickOpen}>
          {t("page.plantview.button")}
        </Button>
      );
    }
  }

  return (
    <div>
      {launchButton()}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="customized-dialog-title">{t("page.plantview.button")}</DialogTitle>
        <DialogContent dividers>
          <PlantviewBody />
        </DialogContent>
        <DialogActions>
          <Button sx={Style.textButton} autoFocus onClick={handleSave} color="primary">
            {t("button.close")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          {t("page.plantview.alert")}
        </Alert>
      </Snackbar>
    </div>
  );
}
