import { useLocation } from "react-router-dom";
import { login } from "../../services/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OAuth = () => {
  let query = useQuery();
  const token = query.get("token");

  if (token) {
    login(token);
    // redirect to path for current user
    window.location.href = "/";
  }
};

export default OAuth;
