import React, { useState, useEffect, useMemo } from "react";
import { useStore } from "./stores/store";
import { observer } from "mobx-react-lite";
//Material-UI
import { Box, Grid, Paper } from "@mui/material";

//Components
import { installationTreeResource } from "../../services/suspenders";
import { Colors } from "../../themes/DefaultTheme";

import { Caster3DWithPartPickerWidget } from "./Caster3DWithPartPickerWidget";
import { MaintenanceNavigationTabsWidget } from "./MaintenanceNavigationTabsWidget";

interface Props {
  partId: string;
}

export default observer(function CasterSetup({ partId }: Props) {
  const {
    casterSetupStore: { load_eDocs },
  } = useStore();
  const [outlinePosition, setOutlinePosition] = useState<string | null>();

  const { installableParts } = installationTreeResource.read();
  const part = installableParts[partId];

  window.onpopstate = function (event: any) {
    setOutlinePosition(null);
  };

  const partInOutlinedPosition = useMemo(() => {
    return outlinePosition
      ? part.installationPostions.find((p) => p.id === outlinePosition)?.currentlyInstalled?.id
      : null;
  }, [outlinePosition, part]);

  useEffect(() => {
    load_eDocs(partInOutlinedPosition || partId);
  }, [partInOutlinedPosition, partId]);

  return (
    /* "visibility": true ? "visible" : "hidden" */
    <Grid
      container
      direction="row"
      justifyContent="space-evenly"
      alignItems="flex-start"
      sx={{
        /** TODO: hard-coded app bar height and padding */ height: "100%",
      }}
    >
      <Grid
        item
        xs={6}
        sx={{
          height: "100%",
          background: "transparent",
        }}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%",
            background: Colors.pageBackgroundSecondary,
            boxShadow: "inset 0px 0px 250px #A5A5A5",
          }}
        >
          {/* TODO: TS will not notice if you miss required properties for
              Caster3DWithPartPickerWidget will take the union of properties of
              PartPickerWidget and Caster3DWidget. However, we decide not to provide
              alarm/setAlarm as properties. FixedWidgetLayout will automatically create
              a property in thelocal store for us.
          */}
          <Box sx={{ width: "100%", height: "100%" }} component="div">
            <Caster3DWithPartPickerWidget
              partId={partId}
              outlinePosition={outlinePosition}
              setOutlinePosition={setOutlinePosition}
            />
          </Box>
        </Paper>
      </Grid>
      <Grid
        item
        xs={6}
        sx={{
          height: "100%",
          background: "transparent",
        }}
      >
        <Paper
          style={{
            width: "100%",
            height: "100%",
            background: Colors.pageBackgroundSecondary,
          }}
          elevation={0}
        >
          <Box sx={{ width: "100%", height: "100%" }} component="div">
            <MaintenanceNavigationTabsWidget
              partId={partId}
              selectedPart={partInOutlinedPosition}
              position={outlinePosition}
              setPosition={setOutlinePosition}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
});
