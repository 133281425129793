import React, { useCallback, useMemo, useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useLocation, useHistory } from "react-router-dom";
//Material-UI
import { Button, Modal, Grid } from "@mui/material";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import NotificationsActiveRoundedIcon from "@mui/icons-material/NotificationsActiveRounded";
import NotificationsOffRoundedIcon from "@mui/icons-material/NotificationsOffRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import SettingsIcon from "@mui/icons-material/Settings";
//Components
import Part3d from "./components/Part3d";
import Alarmreport from "./components/Alarmreport";
import { installationTreeResource } from "../../services/suspenders";

import { Style } from "../../themes/Style";
import { Colors } from "../../themes/DefaultTheme";

export function Caster3DWidget({
  partId,
  outlinePosition,
  setOutlinePosition,
  alarm,
  setAlarm,
}: InferProps<typeof Caster3DWidget.widgetPropTypes>) {
  const history = useHistory();
  const location = useLocation();
  const [notification, setNotification] = useState<boolean>(false);

  const { installableParts } = installationTreeResource.read();

  const doubleClick = (position: string | null) => {
    if (position) {
      const part = installableParts[partId];
      const installationPositions = part.installationPostions;
      const pos = installationPositions.find((x) => x.id === outlinePosition);

      if (pos?.currentlyInstalled) {
        history.push(`./${pos.currentlyInstalled.id}`);
        setOutlinePosition(null);
      } else {
        setOutlinePosition(position);
      }
    }
  };

  //Todo: Can be deleted if back/forward method is correctly implemented
  var disableBack = location.pathname === "/app/caster/c90afb55-7edd-4003-87ab-00ec73b9d65a";

  const drillDownHome = useCallback(() => {
    setOutlinePosition(null);
    history.push(".");
  }, [history, setOutlinePosition]);

  const goBack = useCallback(() => {
    setOutlinePosition(null);
    history.goBack();
  }, [history, setOutlinePosition]);

  const goForward = useCallback(() => {
    setOutlinePosition(null);
    history.goForward();
  }, [history, setOutlinePosition]);

  const outlinedPositionInfo = useMemo(() => {
    const part = installableParts[partId];
    const installationPositions = part.installationPostions;
    const pos = installationPositions.find((x) => x.id === outlinePosition);

    return {
      position: outlinePosition,
      belongs: pos?.belongsTo.id,
      part: pos?.currentlyInstalled ? pos.currentlyInstalled.id : undefined,
    };
  }, [outlinePosition, installableParts, partId]);

  return (
    <Grid
      container
      item
      style={{
        height: "100%",
        width: "100%",
        border: "1px solid transparent",
        borderRadius: 10,
      }}
      justifyContent="center"
    >
      <Part3d
        partId={partId}
        outlinePos={outlinedPositionInfo}
        onPositionClick={setOutlinePosition}
        onPositionDClick={doubleClick}
        setAlarm={setAlarm}
        notification={notification}
      />
      <Grid container item direction="row" justifyContent="center" alignItems="center">
        <Grid
          item
          style={{
            zIndex: 10,
            padding: 6,
            backgroundColor: Colors.pageBackground,
            border: `1px solid ${Colors.pageBackground}`,
            borderRadius: 10,
          }}
        >
          {notification ? (
            <Button
              sx={Style.darkButton}
              variant="contained"
              onClick={() => {
                setNotification(false);
              }}
            >
              <NotificationsActiveRoundedIcon />
            </Button>
          ) : (
            <Button
              sx={Style.darkButton}
              variant="contained"
              onClick={() => {
                setNotification(true);
              }}
            >
              <NotificationsOffRoundedIcon style={{ color: Colors.textPlaceholder }} />
            </Button>
          )}
        </Grid>

        <Grid
          item
          style={{
            marginLeft: 5,
            zIndex: 10,
            padding: 6,
            backgroundColor: Colors.pageBackground,
            border: `1px solid ${Colors.pageBackground}`,
            borderRadius: 10,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Button sx={Style.darkButton} variant="contained" onClick={goBack} disabled={disableBack}>
            <ArrowBackRoundedIcon />
          </Button>
          <Button
            variant="contained"
            sx={Style.darkButton}
            style={{ marginLeft: 20 }}
            onClick={drillDownHome}
          >
            <HomeRoundedIcon />
          </Button>
          <Button
            sx={Style.darkButton}
            style={{ marginLeft: 20 }}
            variant="contained"
            onClick={goForward}
            disabled={false}
          >
            <ArrowForwardRoundedIcon />
          </Button>
        </Grid>

        <Grid
          item
          style={{
            marginLeft: 5,
            zIndex: 10,
            padding: 6,
            backgroundColor: Colors.pageBackground,
            border: `1px solid ${Colors.pageBackground}`,
            borderRadius: 10,
          }}
          justifyContent="center"
          alignItems="center"
        >
          <Button sx={Style.darkButton} variant="contained" onClick={() => {}}>
            <SettingsIcon />
          </Button>
        </Grid>
      </Grid>
      <Modal
        open={alarm.open}
        onBackdropClick={() => setAlarm({ open: false, id: "", name: "", position: "" })}
      >
        <Alarmreport alarm={alarm} setAlarm={setAlarm} />
      </Modal>
    </Grid>
  );
}

Caster3DWidget.widgetPropTypes = {
  partId: PropTypes.string.isRequired,
  outlinePosition: PropTypes.string,
  alarm: PropTypes.shape({
    open: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
  }).isRequired,
  setAlarm: PropTypes.func.isRequired,
  setOutlinePosition: PropTypes.func.isRequired,
};

Caster3DWidget.widgetDefaultProps = {
  alarm: { open: false, id: "", name: "", position: "" },
};
