import { pickBy } from "lodash";
import { observable } from "mobx";
import { get as mobxGet, has as mobxHas, remove as mobxRemove, set as mobxSet } from "mobx";
import { checkPropTypes } from "prop-types";
import { ReactPropTypes, useState, useRef } from "react";

import { BaseWidget, setterName } from "./Widget";
import { WidgetConfigs } from "./WidgetLayout";
import { StoreType } from "./WidgetContainer";
import shallowEqual from "shallowequal";
import { ValueOf } from "ts-essentials";

// utilities do build a mobx observable for all widgets in the configs
// TODO: typing is generally poor in this module

// TODO: type version of derivePropTypes below
// type PropTypes<WC> = WC extends WidgetConfigs<infer WT> ? ...

/** create propTypes and defaultProps for a Dashboard type component
 *
 *  propTypes and defaultProps are the 'union' of all contained widget's props/defaults
 *  that are not overridden by constants.
 */
export function derivePropTypes<WT extends { [id: string]: BaseWidget }>(configs: WidgetConfigs<WT>) {
  // TODO: we need a mapped type for propTypes. As is we are erasing any type information
  //  from configs.
  const propTypes: { [propName: string]: ValueOf<ReactPropTypes> } = {};
  // TODO: should be possible to b stricter about the value type here
  const defaultProps: { [propName: string]: any } = {};

  for (let widgetConfig of Object.values(configs)) {
    const { widget, config } = widgetConfig as typeof configs[keyof typeof configs];
    const localToStoreName = {
      ...Object.fromEntries(Object.keys(widget.widgetPropTypes).map((p) => [p, p])),
      ...Object.fromEntries(Object.entries(config.mapProps).map(([sn, ln]) => [ln, sn])),
    };

    // pn: propName, pt: propType
    for (let [pn, pt] of Object.entries(widget.widgetPropTypes)) {
      // leave anything declared constant alone, we'd even allow conflicting types
      if (!(pn in config.constants)) {
        const storeName = localToStoreName[pn];

        if (storeName in propTypes && propTypes[storeName] !== pt) {
          // we might be have  arrived here b/c one widget wants type
          // x while the other wants x.isRequired. This should
          // intersect to x.isRequired (cannot  do this elegantly b/c
          // x.isRequired.isRequired === undefined and TS gets in the
          // way, as always ...)
          if (propTypes[storeName] === pt.isRequired) {
            // do nothing, we are good
          } else if ((propTypes[storeName] as any).isRequired === pt) {
            propTypes[storeName] = pt;
          } else {
            throw new Error(
              `Prop '${storeName}' is defined with different types: '${pt}' vs '${propTypes[storeName]}'`
            );
          }
        } else {
          propTypes[storeName] = pt;
        }
      }
    }
    for (let [pn, defaultValue] of Object.entries(widget.widgetDefaultProps || {})) {
      // leave anything declared constant alone, we'd even allow conflicting types
      if (!(pn in config.constants)) {
        const storeName = localToStoreName[pn];
        defaultProps[storeName] = defaultValue;
        // TODO: make sure propType is optional
      }
    }
  }

  return [propTypes, defaultProps];
}

/** returns a mp of setter names and the property to set */
function _getSetters<WT extends { [id: string]: BaseWidget }>(configs: WidgetConfigs<WT>) {
  // going to be liberal about what we consider a setter, ignoring if the prop is set
  // constant. WidgetCotainer will make sure to override setters for constants.
  const propNames = new Set<string>();
  for (let widgetConfig of Object.values(configs)) {
    const { widget, config } = widgetConfig as typeof configs[keyof typeof configs];
    const localToStoreName = {
      ...Object.fromEntries(Object.keys(widget.widgetPropTypes).map((p) => [p, p])),
      ...Object.fromEntries(Object.entries(config.mapProps).map(([sn, ln]) => [ln, sn])),
    };

    for (let propName of Object.keys(widget.widgetPropTypes)) {
      propNames.add(localToStoreName[propName]);
    }
  }
  return Object.fromEntries(
    [...propNames].filter((n) => propNames.has(setterName(n))).map((n) => [setterName(n), n])
  );
}

/** keeps a local mobx store in sync with the widget configs
 */
export function useWidgetStore<WT extends { [id: string]: BaseWidget }>(
  props: { [propName: string]: any },
  configs: WidgetConfigs<WT>,
  checkTypes = true
) {
  const [propTypes, defaultProps] = derivePropTypes(configs); // TODO: can we memo this? trick is not reactint on layout changes ...
  const prevDefaultProps = useRef(defaultProps);
  const prevProps = useRef(props);

  const [store, _] = useState<StoreType<ValueOf<WidgetConfigs<WT>>>>(() => {
    // this is creating the initial store
    const setters = pickBy(_getSetters(configs), (p) => p in propTypes);

    const store = observable({ ...defaultProps, ...props }) as StoreType<ValueOf<WidgetConfigs<WT>>>;
    for (let [setterName, propName] of Object.entries(setters)) {
      if (!mobxHas(store, setterName)) {
        // setters from props take precedence
        mobxSet(store, setterName, (v: any) => mobxSet(store, propName, v));
      }
    }

    if (checkTypes) {
      // we can only check for the properties after the setters have been
      // created.
      checkPropTypes(propTypes, store, "useWidgetStore", "widgetStore");

      // TODO: should we check that if a property is provided via props
      //  and a setter is used that setter is provided via props as well?
      //  w/o we can have behaviour similar (but not identical) to
      //  const [myProp, setMyProp] = useState(props.myProp)
    }

    return store;
  });

  // update store with new props
  if (!shallowEqual(props, prevProps.current)) {
    const prev = prevProps.current;
    prevProps.current = props;

    // delete any properties no longer in the current props
    for (let propName of Object.keys(prev).filter((propName) => !(propName in props))) {
      mobxRemove(store, propName);
    }

    for (const [propName, propValue] of Object.entries(props)) {
      // TODO: not sure if we have to watch-out for subtleties around propValue === undefined
      if (propValue !== mobxGet(store, propName)) {
        mobxSet(store, propName, propValue);
      }
    }
  }

  // changing configs might have changed the defaults
  if (!shallowEqual(defaultProps, prevDefaultProps.current)) {
    // TODO: this condition is a little to sensitive as we are not interested in
    // value changes and these might happen accidently as we do not know which
    // widget's default value gets used
    prevDefaultProps.current = defaultProps;
    const propsToSetters = Object.fromEntries(
      Object.entries(_getSetters(configs))
        .filter(([setterName, propName]) => propName in propTypes)
        .map(([setterName, propName]) => [propName, setterName])
    );

    for (const [propName, defaultValue] of Object.entries(defaultProps)) {
      // TODO: not sure if we have to watch-out for subtleties around propValue === undefined
      if (!mobxHas(store, propName)) {
        mobxSet(store, propName, defaultValue);

        const setterName = propsToSetters[propName];
        if (setterName && !mobxHas(store, setterName)) {
          // TODO: do we really want a setter modifying the defaultValue in the
          //  local store? We might provide a dummy setter (v: any) => {} instead
          mobxSet(store, setterName, (v: any) => mobxSet(store, propName, v));
        }
      }
    }
  }

  return store;
}
