import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import GeneralDetails from "./components/GeneralDetails";
import PartDetails from "./components/PartDetails";

//Material-UI
import { Box, Grid, Tab, Typography } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

interface Props {
  partId: string | null;
  selectedPart: string | null;
  setPosition: (position: string | null) => void;
}

export default function Details({ partId, selectedPart, setPosition }: Props) {
  const { t } = useTranslation();
  const [tab, setTab] = useState("1");

  const handleTabChange = useCallback((event: React.SyntheticEvent, value: string) => setTab(value), []);

  return (
    <Box sx={{ width: "100%", height: "100%" }} component="div">
      <TabContext value={tab}>
        <TabList
          TabIndicatorProps={{ style: { display: "none" } }}
          onChange={handleTabChange}
          variant="fullWidth"
          aria-label="lab API tabs example"
        >
          <Tab label={t("castersetup.details.tablist.general")} value="1" sx={Style.Tablist2} />
          <Tab label={t("castersetup.details.tablist.part")} value="2" sx={Style.Tablist2} />
        </TabList>
        <div
          style={{
            marginTop: 5,
            overflow: "auto",
          }}
        >
          <TabPanel sx={Style.Tabpanel2} value="1">
            <Grid item>
              {partId ? (
                <GeneralDetails partId={partId} setPosition={setPosition} />
              ) : (
                <Typography>{t("castersetup.details.noPartSelected")}</Typography>
              )}
            </Grid>
          </TabPanel>
          <TabPanel sx={Style.Tabpanel2} value="2">
            <Grid item>
              {selectedPart ? (
                <PartDetails selectedPart={selectedPart} setPosition={setPosition} />
              ) : (
                <Typography>{t("castersetup.details.noPartSelected")}</Typography>
              )}
            </Grid>
          </TabPanel>
        </div>
      </TabContext>
    </Box>
  );
}

Details.widgetPropTypes = {
  partId: PropTypes.string,
  selectedPart: PropTypes.string,
  setPosition: PropTypes.func.isRequired,
};
