//permissions
import PermissionsService from "../../services/permissionsService";
import { enPermission } from "../../app/models/permissions";

const getMenu = () => [
  {
    id: "01",
    path: "/app",
    name: "app.menu.assetdictionary",
    visible: PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_READ),
  },
  {
    id: "02",
    path: "/app/caster",
    name: "app.menu.castersetup",
    visible: PermissionsService.hasPermission(enPermission.CASTER_READ),
  },
  {
    id: "03",
    path: "/app/cockpits",
    name: "app.menu.cockpits",
    visible: PermissionsService.hasPermission(enPermission.COCKPITS_READ),
  },
  {
    id: "04",
    path: "/app/dashboards",
    name: "app.menu.dashboards",
    visible: PermissionsService.hasPermission(enPermission.DASHBOARDS_READ),
  },
  {
    id: "05",
    path: "/app/ontology",
    name: "app.menu.ontology",
    visible: PermissionsService.hasPermission(enPermission.ONTOLOGY_READ),
  },
];

export default getMenu;
