import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddIcon from "@mui/icons-material/Add";
import AssetContext from "../../../../context/asset-context";
import { Agent } from "../../../../app/api/agent";
import { ListItem } from "../../../../app/models/listItem";
import SelectSideLabel from "../../../../components/SelectSideLabel";
import { RelationshipType } from "../../../../app/models/relationship";
import RelationshipSection from "./RelationshipSection";
import { PartItem } from "../../../../app/models/partitem";
import { AddPartPropertyRequest, AddRelationshipRequest } from "../../../../app/models/property";
import { AddPartSystemRequest } from "../../../../app/models/system";
import Attribute from "./Attribute";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";
import { Part } from "../../../../app/models/graphql-types";

interface Props {
  iconLaunch: boolean;
  selectedPart: Pick<Part, "id" | "name">;
}

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function AddProperties({ iconLaunch, selectedPart }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const assetContext = useContext(AssetContext);
  const { refreshPartDetails } = assetContext;
  const [selectedProp, setSelectedProp] = useState(-1);
  const [propertyTypes, setPropertyTypes] = useState<ListItem[]>([]);
  const [relationships, setRelationships] = useState<RelationshipType[]>([]);

  const [relatedPart, setRelatedPart] = useState<PartItem>();
  const [relationshipId, setRelationshipId] = useState(-1);
  //Attributes
  const [attributeName, setAttributeName] = useState("");
  const [attributeValue, setAttributeValue] = useState("");
  //System
  const [systemId, setSystemId] = useState("");
  const [systemDesc, setSystemDesc] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");

  useEffect(() => {
    (async () => {
      const properties = await Agent.PropertyTypes.list();
      if (properties.length > 0) {
        setPropertyTypes(properties);
      }

      const relationships = await Agent.Relationships.list();
      if (relationships.length > 0) {
        setRelationships(relationships);
      }
    })();
  }, []);

  const handleSave = async () => {
    //TODO: Validations
    const type = relationships.find((x) => x.id === relationshipId)?.rule ?? "";

    if (type === "system") {
      const addSystemPayload = {
        partName: `${selectedPart.name}`,
        propertyTypeId: selectedProp,
        relationshipId: relationshipId,
        externalSystemId: `${selectedSystem}`,
        systemIdentifier: `${systemId}`,
        description: `${systemDesc}`,
      };

      setOpenAlert(true);
      await Agent.Parts.addSystem(addSystemPayload).then(() => {
        refreshPartDetails();
      });
    } else if (type === "install" || type === "install_reverse") {
      const addRelationshipPayload = {
        partId: `${selectedPart.id}`,
        propertyTypeId: selectedProp,
        relationshipId: relationshipId,
        value: `${relatedPart?.name}`,
        relatedPartId: `${relatedPart?.id}`,
      };

      setOpenAlert(true);
      await Agent.Parts.addRelationship(addRelationshipPayload).then(() => {
        refreshPartDetails();
      });
    } else if (type === "attribute") {
      const addPropertyPayload = {
        partId: `${selectedPart.id}`,
        propertyTypeId: selectedProp,
        relationshipId: relationshipId,
        value: `${attributeValue}`,
        name: `${attributeName}`,
      };

      setOpenAlert(true);
      await Agent.Parts.addProperty(addPropertyPayload).then(() => {
        refreshPartDetails();
      });
    } else {
      throw new Error(`Cannot make sense of property/relationship type '${type}'.`);
      /*payload = {
          partId: `${selectedPart.id}`,
          propertyTypeId: selectedProp,
          value: `${attributeValue}`,
          name: `${attributeName}`,
        };
        break;*/
    }

    setOpen(false);
  };

  const handleClickOpen = () => {
    setSelectedProp(-1);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handlePropertyChange = useCallback((id: number) => {
    if (id) {
      setSelectedProp(id);
      setRelationshipId(-1);
    }
  }, []);

  const handleRelatedPart = (part: PartItem) => {
    setRelatedPart(part);
  };

  const handleRelationship = (id: number) => {
    setRelationshipId(id);
  };

  //TODO: Need rework if we do translations on data
  function renderBody() {
    const selected = propertyTypes.find((x) => x.id === selectedProp);
    switch (selected?.name) {
      case "RELATIONSHIP":
        return (
          <RelationshipSection
            items={relationships}
            parent={selectedPart.name}
            onRelatedPart={handleRelatedPart}
            onRelationSelect={handleRelationship}
            onNameChange={(e) => {
              setAttributeName(e);
            }}
            onValueChange={(e) => {
              setAttributeValue(e);
            }}
            onSystemId={(e) => {
              setSystemId(e);
            }}
            onSystemDesc={(e) => {
              setSystemDesc(e);
            }}
            onSystemSelect={(e) => {
              setSelectedSystem(e);
            }}
          />
        );
      case "ATTRIBUTE":
        return (
          <Attribute
            onNameChange={(e) => {
              setAttributeName(e);
            }}
            onValueChange={(e) => {
              setAttributeValue(e);
            }}
          />
        );
      default:
        return null;
    }
  }

  function launchButton() {
    if (iconLaunch) {
      return (
        <IconButton
          sx={Style.iconTextButton}
          aria-label="addprop"
          onClick={handleClickOpen}
          disabled={selectedPart.id === ""}
        >
          <PostAddIcon />
        </IconButton>
      );
    } else {
      return (
        <Button
          endIcon={<AddIcon />}
          sx={Style.darkButton}
          variant="contained"
          onClick={handleClickOpen}
          disabled={selectedPart.id === ""}
        >
          {t("page.addproperty.label")}
        </Button>
      );
    }
  }

  return (
    <div>
      {launchButton()}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="customized-dialog-title">{t("page.addproperty.label")}</DialogTitle>
        <DialogContent dividers>
          <SelectSideLabel
            onSelectedItem={handlePropertyChange}
            selectedValue={selectedProp}
            items={propertyTypes}
            label={t("page.addproperty.type.label")}
          />
          {selectedProp !== -1 && renderBody()}
        </DialogContent>
        <DialogActions>
          <Button sx={Style.textButton} autoFocus onClick={handleSave}>
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          {t("page.addproperty.alert")}
        </Alert>
      </Snackbar>
    </div>
  );
}
