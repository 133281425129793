import axios, { AxiosError, AxiosResponse } from "axios";
import { DocumentNode, OperationDefinitionNode, print } from "graphql";

import { getToken } from "../../services/auth";
import { environment } from "../../environments/environments";

axios.defaults.baseURL = environment.API_URL;
const ASSET_DICTIONARY_URL = "https://assetdictionary2.dev.srm.sms-digital.cloud/typed";
//const ASSET_DICTIONARY_URL = "http://127.0.0.1:5000/typed";

axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async (response) => {
    return response;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

async function execute<Q, V extends object>(query_: DocumentNode, variables?: V) {
  const firstOperation = query_.definitions.find(
    (d) => d.kind === "OperationDefinition"
  ) as OperationDefinitionNode;
  const operationName = firstOperation.name?.value;
  const query = print(query_);

  const response = await axios
    .post<{ data?: Q; errors?: string[]; extensions?: any }>(ASSET_DICTIONARY_URL, {
      query,
      variables,
      operationName,
    })
    .then(responseBody);

  if (response && response.errors && response.errors.length) {
    throw new Error(`Backend call failed: ${response.errors.join("\n\n")}`);
  }

  return response?.data;
}

export const request = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axios.delete<T>(url).then(responseBody),
  execute,
};
