import React, { ChangeEvent, SyntheticEvent } from "react";
import { Part } from "../app/models/part";
import { PartDetail } from "../app/models/partdetails";
import { Plantview, PlantviewTypes } from "../app/models/plantview";
import { RelationshipType } from "../app/models/relationship";
import { System } from "../app/models/system";

type AssetContextType = {
  selectRoot: (view: Plantview) => void;
  selectViewType: (vt: PlantviewTypes) => void;
  selectPart: (part: Pick<Part, "id" | "name">) => void;
  clearSelectedPart: () => void;
  onSelectPart: (event: ChangeEvent<{}>, id: string) => void;
  editSettings: (event: SyntheticEvent) => void;
  refreshPartDetails: () => void;
  refreshTree: () => void;
  rootNode: Plantview;
  viewType: PlantviewTypes;
  displaySection: string;
  treeData: Plantview;
  relationships: RelationshipType[];
  externalSystems: { id: string; name: string; description?: string }[];
  selectedPart: Pick<Part, "id" | "name">;
  partDetails: PartDetail;
};

const AssetContext = React.createContext<AssetContextType>({
  selectRoot: (view: Plantview) => {},
  selectViewType: () => {},
  selectPart: (part: Pick<Part, "id" | "name">) => {},
  clearSelectedPart: () => {},
  onSelectPart: (event: ChangeEvent<{}>, id: string) => {},
  editSettings: (event: SyntheticEvent) => {},
  refreshPartDetails: () => {},
  refreshTree: () => {},
  rootNode: { id: "", name: "", children: [] },
  viewType: "MECHANICAL",
  displaySection: "hide",
  treeData: {
    id: "root",
    name: "",
    children: [],
  },
  relationships: [],
  externalSystems: [],
  selectedPart: { id: "", name: "" },
  partDetails: { properties: [], relationships: [], systems: [] },
});

export default AssetContext;
