import React, { useState, useEffect, useCallback } from "react";

//material ui
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import DatePickers from "./Datepicker";
import { subMinutes } from "date-fns";
import RefreshTime from "./RefreshTime";
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from "./defaultConfigs";
import { DateFields } from "./dates.interface";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "15ch",
    },
  },
}));

const updatesAvailable = [
  {
    value: 0,
    label: "Custom",
  },
  {
    value: 60,
    label: "Last Hour",
  },
  {
    value: 180,
    label: "Last 3 Hours",
  },
  {
    value: 360,
    label: "Last 6 Hours",
  },
  {
    value: 720,
    label: "Last 12 Hours",
  },
  {
    value: 1440,
    label: "Last 24 Hours",
  },
];

interface Props {
  onDatesChanged: (dates: DateFields) => {};
  updateTime: string;
  updateTimeChanged: (time: number) => {};
}

const DatesFilter = ({ onDatesChanged, updateTime, updateTimeChanged }: Props) => {
  const classes = useStyles();

  //default is enabled and custom
  const [updateTimeSelected, setUpdateTimeSelected] = useState(updateTime ?? "60");
  const [datePickerEnabled, setDatePickerEnabled] = useState(false);

  const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
  const [endData, setEndData] = useState(DEFAULT_END_DATE);

  // on change the dates will be selected and reset on parent
  const handleDatesChanged = (dates: DateFields) => {
    onDatesChanged(dates);
    setTime(time);
  };

  const updateDates = useCallback(
    (minutes: string) => {
      const endDateTime = new Date();
      setEndData(endDateTime);
      const startDateTime = subMinutes(endDateTime, Number(minutes));

      setStartDate(startDateTime);
      onDatesChanged({ startDate: startDateTime, endDate: endDateTime });
    },
    [onDatesChanged]
  );

  const handleChangePeriod = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpdateTimeSelected(event.target.value);
    setDatePickerEnabled(Number(event.target.value) === 0);
    if (event.target.value !== "0") {
      updateDates(event.target.value);
    }
    setTime(time);
  };

  const handleUpdatTimeChanged = (newTime: number) => {
    //set new updatetime,
    // set time according whats is selected for the user
    // inform parent that update is changed
    updateTimeChanged(newTime);
    setTime(Number(newTime));
    if (updateTimeSelected !== "0") {
      // update dates
      updateDates(updateTimeSelected);
    }
  };

  const [time, setTime] = useState(60000);

  useEffect(() => {
    const interval = setTimeout(() => {
      setTime(time);
      // only executes if is not custom
      if (updateTimeSelected !== "0") {
        // update dates
        updateDates(updateTimeSelected);
      }
    }, time);
    return () => {
      clearInterval(interval);
    };
  }, [time, updateDates, updateTimeSelected]);

  return (
    <>
      <Grid container>
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={1} className={classes.root}>
            <TextField
              id="updateAt"
              select
              label="Period"
              value={updateTimeSelected}
              onChange={handleChangePeriod}
              SelectProps={{
                native: true,
              }}
            >
              {updatesAvailable.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={5}>
            <DatePickers
              onDatesChanged={handleDatesChanged}
              enabled={datePickerEnabled}
              start={startDate}
              end={endData}
            />
          </Grid>
          <Grid item xs={1}>
            <RefreshTime updatTimeChanged={handleUpdatTimeChanged} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

DatesFilter.defaultProps = {
  updateTimeChanged: () => {},
};

export default DatesFilter;
