import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ChartProps } from "./chartProps";

import { Pie } from "react-chartjs-2";

const useStyles = makeStyles(() => ({
  container: {
    height: "80%",
  },
}));

const PieChart = (chartProps: ChartProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Pie
        data={chartProps.data as any}
        options={
          {
            ...chartProps.options,
            responsive: true,
            maintainAspectRatio: false,
          } as any
        }
      />
    </div>
  );
};

export default PieChart;
