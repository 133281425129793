import React, { useState, useEffect } from "react";

import { Agent } from "../../../../app/api/agent";
import { CockPitTreeNode } from "../../../../app/models/cockPitTreeNode";

import { PannelLocation } from "../../../../app/models/presentation";
import TreeViewPannel from "./treeview";

interface Props {
  onSelectecItem: (node: CockPitTreeNode) => void;
  panel?: PannelLocation;
}

export default function GeniusCMTreeview({ onSelectecItem, panel }: Props) {
  const [nodes, setNode] = useState([]);

  useEffect(() => {
    // Some synchronous code.
    (async () => {
      let nodes = await Agent.GeniusCMService.getNodes();
      setNode(nodes);
    })();
  }, []);
  return <TreeViewPannel nodes={nodes} panel={panel} onSelectecItem={onSelectecItem} />;
}
