import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default function WaterFlow() {
  const { t } = useTranslation();
  const [currentTemp, setTemp] = useState(980);
  const [min, setMin] = useState(970);
  const [max, setMax] = useState(985);

  useEffect(() => {
    const interval = setInterval(() => {
      let newvalue = currentTemp + getRandomArbitrary(-2, 2);

      if (newvalue > max) {
        setMax(Math.round(newvalue * 100) / 100);
      } else if (newvalue < min) {
        setMin(Math.round(newvalue * 100) / 100);
      }
      setTemp(newvalue);
    }, 2000);
    return () => clearInterval(interval);
  }, [max, min, currentTemp]);

  return (
    <div
      style={{
        height: 120,
        minWidth: 220,
        backgroundColor: "#F2F2F2",
        border: "1px solid #E0E0E0",
        borderRadius: 5,
        cursor: "pointer",
      }}
    >
      <Grid
        container
        item
        style={{ marginLeft: 10, marginTop: 10, width: "auto" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <HiStatusOnline style={{ height: 20, width: 20, color: "green" }} />
        <Typography variant="h5" style={{ marginLeft: 5 }}>
          {t("castersetup.maintenance.waterFlow")}
        </Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }}>
        {(currentTemp * 100) / 100} l/min
      </Typography>
      <Grid
        container
        item
        style={{ marginLeft: 10, width: "auto" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="caption">
          {t("castersetup.maintenance.min")}: {min} l/min
        </Typography>
        <Typography variant="caption" style={{ marginLeft: 5 }}>
          {t("castersetup.maintenance.max")}: {max} l/min
        </Typography>
      </Grid>
    </div>
  );
}
