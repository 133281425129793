import React from "react";
import { InferProps } from "prop-types";

import { BaseWidget, LayoutConstraints } from "./Widget";
import { WidgetLayout, WidgetConfigs } from "./WidgetLayout";
import { useWidgetStore, derivePropTypes } from "./widgetStore";
import { useElementSize } from "usehooks-ts";

interface Props<WT extends { [id: string]: BaseWidget }> {
  displayName: string;
  widgetConfigs: WidgetConfigs<WT>;
  layoutConstraints?: LayoutConstraints;
  rows: number;
  cols: number;
  compactType?: "vertical" | "horizontal";
  margin?: [number, number];
  containerPadding?: [number, number];
  // TODO: what kind of save function could/should be supported?
}

/** HOC to create a widget from a WidgetLayout
 *
 *  Given fixed WidgetConfigs the widget meta data for the WidgetLayout and
 *  the store is derived and written on the created component. The created
 *  widget's properties and defaults will be the union of the individual
 *  widget's properties and defaults.
 *
 *  TODO: Currently, we are very strict about types, for example bool and
 *   number will not unify to number.
 *
 *  ATTN: This should only be called once to create a new widget. (Probably
 *  best called on module level.) Even if we tried the property types are
 *  not memoization friendly.
 *
 *  TODO: Typing in widgetStore.ts is poor. Widgets created using this will
 *   not have meaningful TS types.
 */
export function FixedWidgetLayout<WT extends { [id: string]: BaseWidget }>({
  displayName,
  widgetConfigs,
  layoutConstraints,
  rows,
  cols,
  compactType,
  margin,
  containerPadding,
}: Props<WT>) {
  // TODO: typing of derivePropTypes is lacking, resulting widget will not
  //  have meaningful TS type ...
  const [propTypes, defaultProps] = derivePropTypes(widgetConfigs);

  const FixedGridWidget = (props: InferProps<typeof propTypes>) => {
    const store = useWidgetStore(props, widgetConfigs);

    const [outerDivRef, { width, height }] = useElementSize();

    return (
      <div ref={outerDivRef} style={{ width: "100%", height: "100%" }}>
        <WidgetLayout
          store={store}
          widgetConfigs={widgetConfigs}
          width={width}
          height={height}
          rows={rows}
          cols={cols}
          compactType={compactType}
          margin={margin}
          containerPadding={containerPadding}
        />
      </div>
    );
  };
  FixedGridWidget.displayName = displayName;
  FixedGridWidget.widgetPropTypes = propTypes;
  FixedGridWidget.widgetDefaultProps = defaultProps;
  FixedGridWidget.layoutConstraints = layoutConstraints;

  return FixedGridWidget;
}
