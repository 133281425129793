import React, { useEffect, useState } from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Scatter } from "react-chartjs-2";
import { Grid, Typography } from "@mui/material";
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

// TODO: trim this down to the actual requirements ...
ChartJS.register(...registerables);

function getRandomArbitrary(min: any, max: any) {
  return Math.random() * (max - min) + min;
}

export default function TempChart() {
  const { t } = useTranslation();
  const [tempData, setTempData] = useState([1000, 1005, 999, 1010, 1020, 1050, 1040]);
  const [currentTemp, setTemp] = useState(1050);
  const [min, setMin] = useState(999);
  const [max, setMax] = useState(1050);
  const [data, setData] = useState({
    datasets: [
      {
        label: "A dataset",
        showLine: true,
        borderColor: "rgba(0, 50, 200, 0.5)",
        fill: false,
        data: [
          { x: -1, y: tempData[0] },
          { x: 0, y: tempData[1] },
          { x: 1, y: tempData[2] },
          { x: 2, y: tempData[3] },
          { x: 3, y: tempData[4] },
          { x: 4, y: tempData[5] },
          { x: 5, y: tempData[6] },
        ],
        pointRadius: 0,
      },
    ],
  });

  useEffect(() => {
    const interval = setInterval(() => {
      let temps = tempData;
      let newvalue = getRandomArbitrary(-1, 1);

      temps.shift();
      temps.push(temps[5] + newvalue);

      if (temps[5] + newvalue > max) {
        setMax(Math.round((temps[5] + newvalue) * 100) / 100);
      } else if (temps[5] + newvalue < min) {
        setMin(Math.round((temps[5] + newvalue) * 100) / 100);
      }
      setTemp(temps[5] + newvalue);
      setTempData(temps);
      setData({
        datasets: [
          {
            label: "A dataset",
            showLine: true,
            borderColor: "rgba(0, 50, 200, 0.5)",
            fill: false,
            data: [
              { x: -1, y: temps[0] },
              { x: 0, y: temps[1] },
              { x: 1, y: temps[2] },
              { x: 2, y: temps[3] },
              { x: 3, y: temps[4] },
              { x: 4, y: temps[5] },
              { x: 5, y: temps[6] },
            ],
            pointRadius: 0,
          },
        ],
      });
    }, 10000);
    return () => clearInterval(interval);
  }, [max, min, tempData]);

  const options = {
    animation: {
      duration: 1000,
    },
    responsive: true,
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          minTicksLimit: 3,
          maxTicksLimit: 3,
        },
      },
      x: {
        display: false,
        ticks: {
          beginAtZero: true,
          min: 0,
          max: 5,
        },
        gridLines: {
          display: false,
        },
      },
    },
  };

  return (
    <div
      style={{
        height: 275,
        width: 449,
        backgroundColor: "#F2F2F2",
        border: "1px solid #E0E0E0",
        borderRadius: 5,
        cursor: "pointer",
      }}
    >
      <Grid
        container
        item
        style={{ marginLeft: 10, marginTop: 10, width: "auto" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <HiStatusOnline style={{ height: 20, width: 20, color: "green" }} />
        <Typography variant="h5" style={{ marginLeft: 5 }}>
          {t("castersetup.maintenance.surfaceTemperature")}
        </Typography>
      </Grid>
      <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }}>
        {Math.floor(currentTemp * 100) / 100}°C
      </Typography>
      <Grid
        container
        item
        style={{ marginLeft: 10, width: "auto" }}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Typography variant="caption">
          {t("castersetup.maintenance.min")}: {min}°C
        </Typography>
        <Typography variant="caption" style={{ marginLeft: 5 }}>
          {t("castersetup.maintenance.max")}: {max}°C
        </Typography>
      </Grid>
      <div
        style={{
          marginLeft: 10,
          marginRight: 10,
          marginTop: 10,
          height: 150,
          backgroundColor: "white",
          borderRadius: 5,
        }}
      >
        <Scatter data={data} options={options as any /* TODO: this needs review */} />
      </div>
    </div>
  );
}
