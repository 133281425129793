import React from "react";

//Material UI
import { PlantviewTypeEnum } from "../../../app/models/plantview";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { MenuItem, Typography } from "@mui/material";
import { Style } from "../../../themes/Style";

interface Props {
  onSelectedItem: (id: "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL") => void;
  selectedValue: "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL";
  hideLabel: boolean;
  width?: string;
}

export default function PlantviewsList({ selectedValue, onSelectedItem, hideLabel, width }: Props) {
  const { t } = useTranslation();

  const selectedItemHandler = (e: SelectChangeEvent) => {
    if (["HYDRAULICAL", "ELECTRICAL", "MECHANICAL"].includes(e.target.value)) {
      onSelectedItem(e.target.value as "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL");
    }
  };

  return (
    <div style={{ width: width }}>
      {!hideLabel && (
        <Typography variant="body2" gutterBottom>
          {t("page.plantview.viewType")}
        </Typography>
      )}
      <Select
        style={{ width: "100%" }}
        IconComponent={KeyboardArrowDownIcon}
        id="plantview"
        sx={Style.selectInput}
        onChange={selectedItemHandler}
        value={`${selectedValue}`}
      >
        {Object.keys(PlantviewTypeEnum).map((pw) => (
          <MenuItem key={pw} value={pw}>
            {pw.toLowerCase()}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}
