import React, { useCallback, useEffect, useState } from "react";

import useProgressBar from "../../common/hooks/useProgressBar";
import { PresentationList } from "../../components/Presentation";
import Agent from "../../services/presentationService";
import { Presentation, PresentationContentType } from "../../app/models/presentation";

import PermissionsService from "../../services/permissionsService";
import { enPermission, enRoles } from "../../app/models/permissions";

const DashboardSetup = () => {
  const [dashboards, setDashboards] = useState<Presentation[]>([]);
  const { showProgressBar, hideProgressBar } = useProgressBar();
  const loadData = useCallback(async () => {
    showProgressBar();
    let result: Presentation[] = [];
    result = await Agent.Presentations.list(PresentationContentType.Dashboard);
    setDashboards(result);
    hideProgressBar();
  }, [hideProgressBar, showProgressBar]);

  useEffect(() => {
    (async () => {
      loadData();
    })();
  }, [hideProgressBar, loadData, showProgressBar]);

  const onDeleteItem = async (id: string) => {
    await Agent.Presentations.delete(id);
    loadData();
  };

  return (
    <PresentationList
      presentations={dashboards}
      canEdit={
        PermissionsService.isUserInRole(enRoles.DASHBOARDS_ADMIN) ||
        PermissionsService.hasPermission(enPermission.DASHBOARDS_WRITE)
      }
      canRun={
        PermissionsService.isUserInRole(enRoles.DASHBOARDS_ADMIN) ||
        PermissionsService.hasPermission(enPermission.DASHBOARDS_READ)
      }
      canDelete={PermissionsService.isAdmin()}
      onDeleteItem={onDeleteItem}
    />
  );
};

export default DashboardSetup;
