import React from "react";
import AssetTree from "./AssetTree";
import EquipmentList from "./components/EquipmentView/EquipmentList";
import { useTranslation } from "react-i18next";
import RequireAuthorization, {
  OmitIfUnAuthorized,
} from "../../components/RequireAuthorization/RequireAuthorization";
//permissions
import { enPermission, enRoles } from "../../app/models/permissions";
import { TabPanel, TabContext } from "@mui/lab";
import useWindowSize from "../../app/customHooks";
import { Style } from "../../themes/Style";
import { Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";

function a11yProps(index: any) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function AssetDictionary() {
  const windowHeight = useWindowSize()[0];
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <RequireAuthorization
      roles={[enRoles.ASSET_MANAGEMENT_ADMIN, enRoles.ASSET_MANAGEMENT_STANDARD]}
      permissions={enPermission.ASSET_MANAGEMENT_READ}
    >
      <div style={{ minHeight: windowHeight - 64 - 10 }}>
        <TabContext value={`${value}`}>
          <Tabs
            style={{ marginLeft: 24 }}
            value={value}
            onChange={handleChange}
            TabIndicatorProps={{ style: { top: "0px" } }}
          >
            <Tab label={t("tab.plantview")} sx={Style.Tablist} {...a11yProps(0)} />
            <Tab label={t("tab.equipmentlist")} sx={Style.Tablist} {...a11yProps(1)} />
          </Tabs>
          <TabPanel style={{ height: windowHeight - 122 }} sx={Style.Tabpanel} value={"0"}>
            <AssetTree />
          </TabPanel>
          <TabPanel style={{ height: windowHeight - 122 }} sx={Style.Tabpanel} value={"1"}>
            <EquipmentList />
          </TabPanel>
          <OmitIfUnAuthorized roles={[enRoles.CASTER_ADMIN]}></OmitIfUnAuthorized>
        </TabContext>
      </div>
    </RequireAuthorization>
  );
}
