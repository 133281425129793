import "date-fns";
import React, { useState } from "react";
import { TextField } from "@mui/material";
import { isAfter, isBefore, isEqual } from "date-fns";
import { DEFAULT_START_DATE, DEFAULT_END_DATE } from "./defaultConfigs";
import { DateFields } from "./dates.interface";
import { useTranslation } from "react-i18next";
import { Style } from "../../themes/Style";
interface Props {
  onDatesChanged: (dates: DateFields) => void;
  enabled: boolean;
  start?: Date;
  end?: Date;
}

export default function DatePickers({ onDatesChanged, enabled, start, end }: Props) {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(DEFAULT_START_DATE);
  const [endDate, setEndDate] = useState(DEFAULT_END_DATE);

  const [isValid, setIsValid] = useState(true);

  const handleStartDateChanged = (date: any) => {
    const newDate = new Date(date ?? 0);
    if (!isEqual(newDate, startDate)) {
      setStartDate(newDate);
      if (isAfter(newDate, endDate)) {
        setIsValid(false);
        return;
      }
      onDatesChanged({ startDate: newDate, endDate });
      setIsValid(true);
    }
  };

  const handleEndDateChanged = (date: any) => {
    const newDate = new Date(date ?? 0);
    if (!isEqual(newDate, endDate)) {
      setEndDate(newDate);
      if (isBefore(newDate, startDate)) {
        setIsValid(false);
        return;
      }
      onDatesChanged({ startDate, endDate: newDate });
      setIsValid(true);
    }
  };

  return (
    <div>
      <TextField
        style={{ width: 200 }}
        id="date"
        label={t("datePicker.startDate")}
        type="datetime-local"
        variant="standard"
        value={format(startDate)}
        onChange={(e) => {
          handleStartDateChanged(e.target.value);
        }}
        sx={Style.Textfield}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ width: 200, marginLeft: 20 }}
        id="date"
        label={t("datePicker.endDate")}
        type="datetime-local"
        variant="standard"
        value={format(endDate)}
        onChange={(e) => {
          handleEndDateChanged(e.target.value);
        }}
        sx={Style.Textfield}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <div style={{ color: "red" }}>{!isValid && <span>Invalid dates range</span>}</div>
    </div>
  );
}
function format(date: string | number | Date) {
  date = new Date(date);
  let day = ("0" + date.getDate()).slice(-2);
  let month = ("0" + (date.getMonth() + 1)).slice(-2);
  let year = date.getFullYear();
  let hour = ("0" + date.getHours()).slice(-2);
  let minutes = ("0" + date.getMinutes()).slice(-2);
  return year + "-" + month + "-" + day + "T" + hour + ":" + minutes;
}
