import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { useTheme } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import DashboardSetup from "./DashboardsSetup";
import AddDashboard from "./add-dashboard";
import RunDashboard from "./run-dashboard";
import EditDashboard from "./edit-dashboard";
import PermissionsService from "../../services/permissionsService";
import { enPermission, enRoles } from "../../app/models/permissions";

import { PresentationsContextProvider } from "../../context/presentation-context";
import UnAuthorized from "../UnAuthorized";
import useWindowSize from "../../app/customHooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  tab: theme.tab,
  innerTab: theme.innerTab,

  fixedHeight: {
    backgroundColor: "transparent",
  },

  fixedHeight2: {
    backgroundColor: theme.pageBackgroundSecondary,
  },
  title: {
    width: "150px",
    height: "36px",
    fontSize: "18px",
    marginBottom: "18px",
    fontFamily: "Roboto-Regular, Roboto, sans-serif;",
    lineHeight: "36px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    color: "#333333",
  },
  bread: {
    minHeight: 35,
    marginLeft: 24,
    marginRight: 24,
    paddingTop: 20,
    paddingLeft: 15,
    paddingRight: 15,
    color: "#2b7cd8",
  },
}));

export default function Dashboards() {
  let match = useRouteMatch();
  const { t } = useTranslation();
  const windowHeight = useWindowSize()[0];
  const classes = useStyles();
  const [value, setValue] = useState(`${match.url}`);
  const theme = useTheme();

  const handleChange = (_event: React.ChangeEvent<any>, newValue: any) => {
    setValue(newValue);
  };

  const tabPropsSelected = (index: number) => {
    return {
      id: `menu-${index}`,
      "aria-controls": `menu-${index}`,
    };
  };

  const hasAuthorization =
    PermissionsService.isUserInRole(enRoles.DASHBOARDS_ADMIN) ||
    PermissionsService.isUserInRole(enRoles.DASHBOARDS_STANDARD) ||
    PermissionsService.hasPermission(enPermission.DASHBOARDS_READ);

  return hasAuthorization ? (
    <>
      <PresentationsContextProvider>
        <Router>
          <div className={classes.fixedHeight} style={{ minHeight: windowHeight - 64 - 10 }}>
            <Tabs
              TabIndicatorProps={{ style: { top: "0px" } }}
              style={{ marginLeft: 24 }}
              indicatorColor="primary"
              value={value}
              textColor="primary"
              onChange={handleChange}
              aria-label="menu"
            >
              <Tab
                label={t("app.menu.dashboards")}
                className={classes.tab}
                component={Link}
                to={`${match.url}`}
                value={`${match.url}`}
                dir={theme.direction}
                {...tabPropsSelected(0)}
              />
              {PermissionsService.hasPermission(enPermission.DASHBOARDS_WRITE) && (
                <Tab
                  label={t("button.new")}
                  className={classes.tab}
                  data-testid="new-dashboard"
                  component={Link}
                  to={`${match.url}/add`}
                  dir={theme.direction}
                  value={`${match.url}/add`}
                  {...tabPropsSelected(1)}
                />
              )}
            </Tabs>

            <div
              className={classes.fixedHeight2}
              style={{
                minHeight: windowHeight - 64 - 10 - 48,
                marginLeft: 24,
                marginRight: 24,
                paddingTop: 25,
                paddingLeft: 15,
                paddingRight: 15,
              }}
            >
              <Switch>
                <Route path={`${match.path}/run/:id`} component={RunDashboard} />
                <Route exact path={`${match.path}`} component={DashboardSetup} />
                {PermissionsService.hasPermission(enPermission.DASHBOARDS_WRITE) && (
                  <>
                    <Route path={`${match.path}/edit/:id`} component={EditDashboard} />
                    <Route path={`${match.path}/add`} component={AddDashboard} />
                  </>
                )}
              </Switch>
            </div>
          </div>
        </Router>
      </PresentationsContextProvider>
    </>
  ) : (
    <UnAuthorized />
  );
}
