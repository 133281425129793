import type { ReactPropTypes } from "react";
import { InferProps } from "prop-types";
import type { ValueOf } from "ts-essentials";
import React from "react";

export type WidgetPropTypes = {
  preview?: ReactPropTypes["bool"];
  save?: ReactPropTypes["func"];
  [propName: string]: ValueOf<ReactPropTypes> | undefined;
};

export type LayoutConstraints = {
  preferredWidth: number;
  preferredHeight: number;
  // TODO: whatever else we need here
};

export type BaseWidget = React.FunctionComponent<any> & {
  widgetPropTypes: InferProps<any>;
  widgetDefaultProps?: {};
  layoutConstraints?: LayoutConstraints;
};

// TODO: it should be possible to replace InferProps<> with a generic
//  that enforces property/setter pairs, ie x: T -> setX: (v: T) => void
//  (or maybe React.Dispatch<React.SetStateAction<T>>?)
//  As is we are not getting proper types on the setters b/c the best PropTypes
//  has to offer is PropTypes.func

// TODO: Widgets cannot take refs as they are React.FunctionComponent. We'll
//  see if this can be generalized w/o upsetting Typescript too much.

/** The widget API
 *
 *  A widget is a standard functional component with the following extra
 *  constraints (constraints not in the Typescript below are marked with
 *  NON-TS, we will leave it as an exercise to the reader to add those ;)
 *
 *  - property types must be declared using
 *    [prop-types](https://reactjs.org/docs/typechecking-with-proptypes.html)
 *  - layout constraints may be declared via the `layoutConstraints` property.
 *    Detailed shape tbd.
 *  - NON-TS if a widget has a property `preview` it must be of type `boolean`.
 *    The widget must not take a setter for `preview` (ie no `setPreview`). The
 *    widget may display dummy data etc when rendered with `preview = true`.
 *  - NON-TS if a widget has a property `save` it must be of type `() => void`.
 *    The widget may call `save` to indicate it's current properties should be
 *    persisted. It is at the disgression of the `Dashboard` to honour that
 *    request and/or persist the current layout and/or properties of other
 *    widgets simultaneously.
 *  - NON-TS widget properties and their setters must follow the convention:
 *    `propName: T` has a setter
 *    `\`set${Capitalize<propName>}\`: (value: T) => void`. The setter will
 *    allow the widget to change the property value in the `Dashboard`'s store.
 *    Remark: a lone property `setA: T` (ie without property `a`) would be
 *    considered a property (not a setter) in the `Dashboard`'s store.
 */
export type Widget<PT extends WidgetPropTypes> = React.FunctionComponent<InferProps<PT>> & {
  widgetPropTypes: PT;
  widgetDefaultProps?: {
    [propName in keyof InferProps<PT>]?: InferProps<PT>[propName];
  };
  layoutConstraints?: LayoutConstraints;
};

export type WidgetProps<W extends BaseWidget> = W extends Widget<infer PT> ? InferProps<PT> : {};

export type PropTypes<C> = C extends React.FunctionComponent<infer PT> ? PT : never;

export type IsWidget<T> = T extends BaseWidget ? T : never;
export type AsWidget<T> = T extends Widget<infer PT> ? Widget<PT> : T;

export function setterName(propName: string) {
  return `set${propName[0].toUpperCase()}${propName.slice(1)}`;
}
