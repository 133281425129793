import React, { ChangeEvent, SyntheticEvent, useContext, useState } from "react";
import AssetContext from "../../../context/asset-context";
//Material-UI
import { TreeView, TreeItem } from "@mui/lab";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Agent } from "../../../app/api/agent";
import { Plantview } from "../../../app/models/plantview";
import { Style } from "../../../themes/Style";

export type AssetTreeViewProps = {
  id: string;
  name: string;
};

export default function AssetTreeView(props: AssetTreeViewProps) {
  const assetContext = useContext(AssetContext);
  const { onSelectPart, viewType } = assetContext;
  const [expanded, setExpanded] = useState<string[]>([]);
  const [childNodes, setChildNodes] = useState<JSX.Element[] | null>(null);

  const handleToggle = (event: ChangeEvent<{}>, nodeIds: string[]) => {
    event.persist();
    const target = event.target as HTMLElement;
    let iconClicked = target.closest(".MuiTreeItem-iconContainer");
    if (iconClicked) {
      setExpanded(nodeIds);
    }
  };

  async function fetchChildNodes(id: string) {
    let plantview = await Agent.Plantviews.children(id, viewType);
    return new Promise<Plantview[]>((resolve) => {
      setTimeout(() => {
        resolve(plantview?.children || []);
      }, 1000);
    });
  }

  const handleSelect = async (event: SyntheticEvent<{}>, ids: string[]) => {
    const [id, ...rest] = ids; /* TODO: investigate if this works */
    event.persist();
    const target = event.target as HTMLElement;
    let iconClicked = target.closest(".MuiTreeItem-iconContainer");
    if (!iconClicked) {
      onSelectPart(event, id);
    } else {
      fetchChildNodes(id).then((result) =>
        setChildNodes(result.map((node) => <AssetTreeView key={node.id} id={node.id} name={node.name} />))
      );
    }
  };

  return (
    <TreeView
      aria-label="controlled"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={expanded}
      onNodeToggle={handleToggle}
      onNodeSelect={handleSelect}
      multiSelect
    >
      <TreeItem nodeId={props.id} label={props.name} sx={{} /* TODO: needs investigation Style.treeItem*/}>
        {childNodes || [<div key="stub" />]}
      </TreeItem>
    </TreeView>
  );
}
