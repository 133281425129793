import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function DtModal(props) {
  const {
    open,
    loading,
    title,
    children,
    showContentText,
    contentText,
    width,
    showCancelButton,
    showConfirmButton,
    closeButtonName,
    confirmButtonName,
    confirmButtonDisabledStatus,
    disableBackdropClick,
    handleConfirm,
    handleClose,
    onEnter,
  } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={width}
        disableBackdropClick={disableBackdropClick}
        onEnter={onEnter}
      >
        <DialogTitle id="alert-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent>
          {showContentText && (
            <DialogContent id="alert-dialog-description" dividers>
              {contentText}
            </DialogContent>
          )}
          {children}
        </DialogContent>
        <DialogActions>
          {showCancelButton && (
            <Button onClick={handleClose} color="secondary">
              {closeButtonName}
            </Button>
          )}
          {showConfirmButton && (
            <Button
              data-testid="submit-button"
              onClick={handleConfirm}
              disabled={confirmButtonDisabledStatus || loading}
              color="primary"
              autoFocus
            >
              {confirmButtonName}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

//todo: include interfrace when gos to typescript
DtModal.defaultProps = {
  open: false,
  loading: false,
  children: null,
  showContentText: true,
  contentText: "",
  width: "md",
  showCancelButton: true,
  showConfirmButton: true,
  closeButtonName: "Cancel",
  confirmButtonName: "Confirm",
  handleClose: () => false,
};
