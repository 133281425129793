import React, { useEffect } from "react";
import useProgressBar from "../../../../common/hooks/useProgressBar";
import { PartItem } from "../../../../app/models/partitem";
//Material-UI
import { IconButton } from "@mui/material";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { Agent } from "../../../../app/api/agent";
import AddProperties from "../AddProperty/AddProperty";
import { enPermission } from "../../../../app/models/permissions";
import PermissionsService from "../../../../services/permissionsService";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

export default function EquipmentList() {
  const { t } = useTranslation();
  const { showProgressBar, hideProgressBar } = useProgressBar();
  const [parts, setParts] = React.useState(new Map<string, PartItem>());

  useEffect(() => {
    //TODO: Do not always load
    (async () => {
      showProgressBar();
      const result = await Agent.Parts.list("");
      result.forEach((part) => {
        parts.set(part.id, part);
      });
      hideProgressBar();
    })();
  }, [hideProgressBar, parts, showProgressBar]);

  const handleDeleteProperty = async (id: string) => {
    await Agent.Parts.delete(id).then(() => {
      setParts((prev) => {
        const newState = new Map(prev);
        newState.delete(id);
        return newState;
      });
    });
  };

  const renderDetailsButton = (params: GridCellParams) => {
    return PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_WRITE) ? (
      <>
        <AddProperties iconLaunch={true} selectedPart={params.row as PartItem} />
        <IconButton
          sx={Style.iconTextButton}
          aria-label="delete"
          onClick={() => {
            handleDeleteProperty(params.id.toString());
          }}
        >
          <DeleteIcon />
        </IconButton>
      </>
    ) : (
      <></>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("grid.label.id")}`,
      sortable: false,
      flex: 0.5,
    },
    { field: "name", headerName: `${t("grid.label.name")}`, flex: 0.5 },
    { field: "remarks", headerName: `${t("grid.label.remarks")}`, flex: 1 },
    {
      field: "action",
      headerName: `${t("grid.label.action")}`,
      renderCell: (params: GridCellParams) => renderDetailsButton(params),
    },
  ];

  return (
    <div style={{ height: "95%" }}>
      <DataGrid
        autoPageSize
        sx={Style.Datagrid}
        rows={Array.from(parts.values())}
        columns={columns}
        density="compact"
      />
    </div>
  );
}
