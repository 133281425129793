import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { QrReader, OnResultFunction } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import WarningAmberRoundedIcon from "@mui/icons-material/WarningAmberRounded";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Style } from "../../../themes/Style";

export default function QrScanner({ parts, setOpenQr }: any) {
  const history = useHistory();
  const { t } = useTranslation();
  const [borderColor, setBorderColor] = useState("#d3d3d380");
  const [warningVisible, setWarningV] = useState(false);
  const [okVisible, setOkV] = useState(false);
  const [scannedID, setScannedId] = useState("");

  function checkCode(code: Parameters<OnResultFunction>[0]) {
    if (code) {
      let findPart = parts.filter((part: any) => part.id === code);
      if (findPart.length > 0) {
        setBorderColor("green");
        setScannedId(findPart[0].label);
        setOkV(true);
        setTimeout(() => {
          history.push(`./${code}`);
          setWarningV(false);
          setOpenQr(false);
          setScannedId("");
          setOkV(false);
        }, 1000);
      } else {
        setBorderColor("red");
        setWarningV(true);
      }
    } else if (borderColor !== "#d3d3d380") {
      setBorderColor("#d3d3d380");
      setWarningV(false);
    }
  }

  return (
    <Grid
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "white",
        maxWidth: 700,
      }}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Grid item container style={{ border: `4px solid transparent`, minWidth: "100%" }}>
        <Grid
          container
          style={{
            zIndex: 50,
            top: 54,
            left: 54,
            position: "absolute",
            height: 592,
            width: 592,
            backgroundColor: "transparent",
            border: `3px solid ${borderColor}`,
            boxShadow: "rgba(15, 15, 15, 0.6) 0px 0px 0px 50px",
          }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          {warningVisible ? (
            <Grid
              container
              item
              style={{ width: 150, backgroundColor: "#FFFFFF70", padding: 5, borderRadius: 5 }}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <WarningAmberRoundedIcon sx={{ fontSize: 60, color: "red", alignSelf: "center" }} />
              <Typography variant="h6" style={{ color: "red" }}>
                Unknown ID
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
          {okVisible ? (
            <Grid
              container
              item
              style={{
                minWidth: 150,
                maxWidth: 300,
                backgroundColor: "#FFFFFF70",
                padding: 5,
                borderRadius: 5,
              }}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress sx={{ color: "green" }} />
              <Typography variant="h6" style={{ color: "green" }}>
                Go to {scannedID}
              </Typography>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <QrReader
          scanDelay={300}
          containerStyle={{ width: "100%", borderRadius: "16px" }}
          onResult={(result) => {
            checkCode(result);
          }}
          constraints={{ facingMode: "user" }}
        />
      </Grid>
      <Grid
        style={{ padding: 5, width: "100%" }}
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Button variant="contained" sx={Style.darkButton} onClick={() => setOpenQr(false)}>
          {t("button.close")}
        </Button>
      </Grid>
    </Grid>
  );
}
