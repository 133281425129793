import React, { Suspense, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Tab, CircularProgress, Typography, Box } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import EDoc from "./components/eDoc";
import PartHistoryTable from "./components/History";
import { useTranslation } from "react-i18next";
import JobChart from "./components/JobChart";
import TempChart from "./components/Widgets/tempChart";
import SegmentThickness from "./components/Widgets/SegmentThickness";
import SlabThickness from "./components/Widgets/SlabThickness";
import SlabWidth from "./components/Widgets/SlabWidth";
import CastSpeed from "./components/Widgets/CastSpeed";
import WaterPressure from "./components/Widgets/WaterPressure";
import WaterFlow from "./components/Widgets/WaterFlow";
import UpTime from "./components/Widgets/UpTime";
import LaddleQuantity from "./components/Widgets/LaddleQuantity";
import SteelQuantity from "./components/Widgets/SteelQuantity";
import Mileage from "./components/Widgets/Mileage";
import { Style } from "../../../../themes/Style";

interface Props {
  partId: string;
  maintenanceView: string;
  setMaintenanceView: (view: string) => void;
}

export default function Maintenance({ partId }: Props) {
  const { t } = useTranslation();
  const [data, setData] = useState<any>(null);
  const [maintenanceView, setMaintenanceView] = useState("1");

  const handleMaintenanceViewChange = (event: React.SyntheticEvent, newValue: string) => {
    setMaintenanceView(newValue);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }} component="div">
      <TabContext value={maintenanceView}>
        <Grid container>
          <Grid item xs={12}>
            <TabList
              TabIndicatorProps={{ style: { display: "none" } }}
              onChange={handleMaintenanceViewChange}
              variant="fullWidth"
              aria-label="lab API tabs example"
            >
              <Tab label={t("castersetup.maintenance.tablist.history")} value="1" sx={Style.Tablist2} />
              <Tab label={t("castersetup.maintenance.tablist.documents")} value="2" sx={Style.Tablist2} />
              <Tab label={t("castersetup.maintenance.tablist.widgets")} value="3" sx={Style.Tablist2} />
            </TabList>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                marginTop: 5,
                overflow: "auto",
              }}
            >
              <TabPanel sx={Style.Tabpanel2} value="1">
                <Suspense fallback={<CircularProgress />}>
                  <div>
                    <PartHistoryTable jobData={setData} partId={partId} />
                  </div>
                  <div
                    style={{
                      marginTop: 80,
                      backgroundColor: "#F2F2F2",
                      border: "1px solid #E0E0E0",
                      borderRadius: 5,
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      style={{ fontWeight: "bold", marginLeft: 10, marginTop: 10 }}
                    >
                      {t("casterSetup.maintenance.jobsInLast6Months")}
                    </Typography>
                    <div
                      style={{
                        backgroundColor: "white",
                        borderRadius: 5,
                        marginLeft: 10,
                        marginRight: 10,
                        marginTop: 10,
                        minHeight: 150,
                      }}
                    >
                      <JobChart data={data} />
                    </div>
                  </div>
                </Suspense>
              </TabPanel>
              <TabPanel sx={Style.Tabpanel2} value="2">
                <EDoc partId={partId} />
              </TabPanel>
              <TabPanel sx={Style.Tabpanel2} value="3">
                {partId !== "c90afb55-7edd-4003-87ab-00ec73b9d65a" ? (
                  <>
                    <Grid
                      spacing={1}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <TempChart />
                      </Grid>
                      <Grid item>
                        <SteelQuantity />
                      </Grid>
                      <Grid item>
                        <Mileage />
                      </Grid>
                      <Grid item>
                        <SegmentThickness />
                      </Grid>
                      <Grid item>
                        <SlabThickness />
                      </Grid>
                      <Grid item>
                        <SlabWidth />
                      </Grid>
                      <Grid item>
                        <CastSpeed />
                      </Grid>
                      <Grid item>
                        <WaterPressure />
                      </Grid>
                      <Grid item>
                        <WaterFlow />
                      </Grid>
                      <Grid item>
                        <UpTime />
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      spacing={1}
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                    >
                      <Grid item>
                        <TempChart />
                      </Grid>
                      <Grid item>
                        <SlabThickness />
                      </Grid>
                      <Grid item>
                        <SlabWidth />
                      </Grid>
                      <Grid item>
                        <CastSpeed />
                      </Grid>
                      <Grid item>
                        <LaddleQuantity />
                      </Grid>
                      <Grid item>
                        <SteelQuantity />
                      </Grid>
                    </Grid>
                  </>
                )}
              </TabPanel>
            </div>
          </Grid>
        </Grid>
      </TabContext>
    </Box>
  );
}

Maintenance.widgetPropTypes = {
  partId: PropTypes.string.isRequired,
};
