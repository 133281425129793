import { Box, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

//Material UI

import { ListItem } from "../../app/models/listItem";
import { RelationshipType } from "../../app/models/relationship";
import { Style } from "../../themes/Style";

interface Props {
  onSelectedItem: (id: number) => void;
  selectedValue: number;
  items: ListItem[] | RelationshipType[];
  label: string;
}

export default function SelectSideLabel({ onSelectedItem, selectedValue, items, label }: Props) {
  const [value, setValue] = useState(selectedValue ?? -1);

  const handleChange = (e: {
    preventDefault: () => void;
    stopPropagation: () => void;
    target: { value: any };
  }) => {
    e.preventDefault();
    e.stopPropagation();
    const id = e.target.value;
    setValue(id);
    if (id) {
      onSelectedItem(id);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Box p={1} component={"div"} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {label}
        </Box>
        <Select
          style={{ width: "60%" }}
          sx={Style.selectInput}
          size="small"
          id="properties"
          onChange={handleChange}
          value={value}
        >
          {items.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.description}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </div>
  );
}
