import React, {
  useState,
  useEffect,
  useMemo,
  useReducer,
  useCallback,
  SyntheticEvent,
  MouseEvent,
  PointerEvent,
  ChangeEvent,
} from "react";
//Internal
import AssetTreeView from "./components/AssetTreeView";
import AssetContext from "../../context/asset-context";
//Material-UI
import { Grid, Divider, Typography } from "@mui/material";
import NodeHeader from "./components/NodeHeader";
//TODO:combine
// import { Agent as PlantAgent } from "../../services/plantviewsService";
import PlantviewsList from "./components/PlantviewsList";
import { Agent } from "../../app/api/agent";
import PartSearch from "./components/PartSearch";
import useWindowSize from "../../app/customHooks";
import { useTranslation } from "react-i18next";

import type { PartDetail } from "../../app/models/partdetails";
import { RelationshipType } from "../../app/models/relationship";
import { System } from "../../app/models/system";
import { Plantview } from "../../app/models/plantview";
import { Part } from "../../app/models/part";

export default function AssetTree() {
  const windowHeight = useWindowSize()[0];
  const ROOTNODE_KEY = "rootNode";
  const SELECTED_PART_KEY = "selectedPart";
  const VIEW_TYPE_KEY = "viewType";
  const DEFAULT_TREE = useMemo<Plantview>(() => ({ id: "", name: "", children: [] }), []);
  const DEFAULT_PART = useMemo<Pick<Part, "id" | "name">>(() => ({ id: "", name: "" }), []);
  const [rootNode, setRootNode] = useReducer((_previous: typeof DEFAULT_TREE, node: typeof DEFAULT_TREE) => {
    localStorage.setItem(ROOTNODE_KEY, JSON.stringify(node));
    return node;
  }, JSON.parse(localStorage.getItem(ROOTNODE_KEY) || "null") || DEFAULT_TREE);
  const [selectedPart, setSelectedPart] = useReducer(
    (_previous: typeof DEFAULT_PART, node: typeof DEFAULT_PART) => {
      localStorage.setItem(SELECTED_PART_KEY, JSON.stringify(node));
      return node;
    },
    JSON.parse(localStorage.getItem(SELECTED_PART_KEY) || "null") || DEFAULT_PART
  );
  const [viewType, setViewType] = useReducer(
    (
      _previous: "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL",
      node: "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL"
    ) => {
      localStorage.setItem(VIEW_TYPE_KEY, JSON.stringify(node));
      return node;
    },
    JSON.parse(localStorage.getItem(VIEW_TYPE_KEY) || '"MECHANICAL"')
  );

  const { t } = useTranslation();
  const [treeData, setTreeData] = React.useState(DEFAULT_TREE);
  const [displaySection, setDisplaySection] = useState("hide");
  const [relationships, setRelationships] = useState<RelationshipType[]>([]);
  const [externalSystems, setExternalSystems] = useState<
    {
      id: string;
      name: string;
      description?: string;
    }[]
  >([]);
  const [partDetails, setPartDetails] = useState<PartDetail>({
    properties: [],
    relationships: [],
    systems: [],
  });

  const onSelectPartHandler = (event: ChangeEvent<{}>, id: string) => {
    const target = event.nativeEvent?.target as HTMLElement;
    if (selectedPart.id === id || !target?.innerText) return;

    const name = target.innerText;
    setSelectedPart({ id: id, name: name });
  };

  const clearSelectedPartHandler = () => {
    setSelectedPart({ id: "", name: "" });
    setDisplaySection("hide");
  };

  const selectPartHandler = (part: typeof DEFAULT_PART) => {
    if (selectedPart.id === part.id) return;
    setSelectedPart(part);
  };

  const loadPartDetails = useCallback(async () => {
    if (!selectedPart.id) {
      setPartDetails({ properties: [], relationships: [], systems: [] });
      return;
    }

    const details = await Agent.Parts.details(selectedPart.id);
    if (details) {
      setPartDetails(details);
    } else {
      setPartDetails({ properties: [], relationships: [], systems: [] });
    }
  }, [selectedPart.id]);

  const refreshPartDetailsHandler = async () => {
    loadPartDetails();
  };

  useEffect(() => {
    (async () => await loadPartDetails())();
  }, [loadPartDetails, selectedPart]);

  useEffect(() => {
    (async () => {
      const relList = await Agent.Relationships.list();
      if (relList) {
        setRelationships(relList);
      }
    })();

    (async () => {
      const systems = await Agent.ReferenceData.getSystems();
      if (systems) {
        setExternalSystems(systems);
      }
    })();
  }, []);

  const selectRootHandler = (part: typeof DEFAULT_PART) => {
    if (part) {
      setRootNode({ ...part, children: [] });
    }
  };

  const loadTree = useCallback(async () => {
    let plantview;
    const start = rootNode.name === "" ? "StrandCaster6" : rootNode.name; //TODO:Default??

    plantview = await Agent.Plantviews.filtered(start, viewType);

    if (plantview) {
      setTreeData(plantview);
      if (selectedPart.id === "") {
        setSelectedPart({ id: plantview.id, name: plantview.name });
      }
    } else {
      setSelectedPart(DEFAULT_PART);
      setTreeData(DEFAULT_TREE);
    }
  }, [DEFAULT_PART, DEFAULT_TREE, rootNode, selectedPart.id, viewType]);

  const refreshTreeHandler = async () => {
    loadTree();
  };

  useEffect(() => {
    (async () => await loadTree())();
  }, [loadTree, rootNode, viewType]);

  const editHandler = (event: SyntheticEvent) => {
    // TODO: no clue if this was the intention!
    setDisplaySection(`${event}`);
  };

  const handleRelatedPartSelect = (value: typeof DEFAULT_PART) => {
    if (value && value?.id) {
      setRootNode({ ...value, children: [] });
      selectPartHandler(value);
    }
  };

  // TODO: we should not have local components
  const renderTree = () => {
    if (treeData.children.length > 0) {
      return <AssetTreeView key={treeData.id} {...treeData}></AssetTreeView>;
    } else {
      return [<div key="stub" />];
    }
  };

  return (
    <AssetContext.Provider
      value={{
        selectedPart: selectedPart,
        clearSelectedPart: clearSelectedPartHandler,
        onSelectPart: onSelectPartHandler,
        selectPart: selectPartHandler,
        selectViewType: setViewType,
        rootNode: rootNode,
        treeData: treeData,
        viewType: viewType,
        selectRoot: selectRootHandler,
        editSettings: editHandler,
        refreshPartDetails: refreshPartDetailsHandler,
        refreshTree: refreshTreeHandler,
        displaySection: displaySection,
        partDetails: partDetails,
        relationships: relationships,
        externalSystems: externalSystems,
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4} lg={3}>
          <div style={{ paddingRight: 8 }}>
            <PlantviewsList
              selectedValue={viewType}
              hideLabel={false}
              onSelectedItem={(id) => {
                setViewType(id);
              }}
            />
            <Divider
              textAlign="left"
              style={{
                marginTop: 10,
                marginBottom: 10,
                width: "100%",
                height: 1,
              }}
            />
            <Typography variant="body2" gutterBottom>
              {t("page.plantview.startingNode")}
            </Typography>
            <PartSearch width="100%" onItemSelect={handleRelatedPartSelect} />
            <Divider
              textAlign="left"
              style={{
                marginTop: 10,
                marginBottom: 10,
                width: "100%",
                height: 1,
              }}
            />
            <div
              style={{
                height: windowHeight - 64 - 10 - 48 - 235,
                overflowY: "auto",
              }}
            >
              {renderTree()}
            </div>
          </div>
        </Grid>

        <Divider style={{ height: windowHeight - 64 - 10 - 48 - 20 }} orientation="vertical" />

        <Grid item xs={12} md={8} lg={8.99}>
          <NodeHeader></NodeHeader>
        </Grid>
      </Grid>
    </AssetContext.Provider>
  );
}
