import React, { useState } from "react";
import PropTypes, { InferProps } from "prop-types";
import { useHistory } from "react-router-dom";
//Material-UI
import { Autocomplete, Button, Grid, Modal, TextField } from "@mui/material";

//Components
import { installationTreeResource } from "../../services/suspenders";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import QrScanner from "./components/QrScanner";

import { Style } from "../../themes/Style";

export function PartPickerWidget({
  partId,
  setOutlinePosition,
}: InferProps<typeof PartPickerWidget.widgetPropTypes>) {
  const [openQr, setOpenQr] = useState(false);
  const history = useHistory();

  const { installableParts } = installationTreeResource.read();
  const installPlacesAutocompleteArray = Object.values(installableParts).map((x) => ({
    label: x.name,
    id: x.id,
  }));

  return (
    <Grid container style={{ paddingTop: 5, paddingLeft: 5, minWidth: 250, maxWidth: "100%" }}>
      <Autocomplete
        sx={Style.Autocomplete}
        style={{ zIndex: 5, minWidth: "60%" }}
        popupIcon={<KeyboardArrowDown />}
        onChange={(event: any, newValue: any) => {
          if (newValue != null) {
            history.push(`./${newValue.id}`);
            setOutlinePosition(null);
          }
        }}
        value={installPlacesAutocompleteArray.find((x) => x.id === partId)}
        disablePortal
        id="combo-box-demo"
        options={installPlacesAutocompleteArray}
        renderInput={(params) => <TextField {...params} InputLabelProps={{ shrink: false }} />}
      />

      <Button
        style={{
          backgroundColor: "#F2F2F2",
          zIndex: 5,
          borderBottom: "2px solid #BFBFBF",
        }}
        onClick={() => {
          setOpenQr(true);
        }}
      >
        <QrCodeScannerIcon />
      </Button>
      <Modal open={openQr} onBackdropClick={() => setOpenQr(false)}>
        <div>
          <QrScanner setOpenQr={setOpenQr} parts={installPlacesAutocompleteArray} />
        </div>
      </Modal>
    </Grid>
  );
}

PartPickerWidget.widgetPropTypes = {
  partId: PropTypes.string.isRequired,
  setOutlinePosition: PropTypes.func.isRequired,
};
