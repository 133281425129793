export interface Plantview {
  id: string;
  name: string;
  children: Plantview[];
}

export type PlantviewTypes = "HYDRAULICAL" | "ELECTRICAL" | "MECHANICAL";

export enum PlantviewTypeEnum {
  HYDRAULICAL = 1,
  ELECTRICAL = 2,
  MECHANICAL = 3,
}

export interface ViewRelationship {
  id: number;
  name: string;
  filter?: string;
}

export interface AddViewRelationshipRequest {
  viewId: string;
  relationshipId: number;
}
