import { environment } from "../environments/environments";
import Keycloak from "keycloak-js";

const KEYCLOAK_URL =
  (environment.CUSTOMER_PORTAL &&
  environment.CUSTOMER_PORTAL.startsWith("https://qas.portal.my.sms-group.com/")
    ? "https://keycloak.dev.sms-digital.cloud"
    : environment.CUSTOMER_PORTAL) || "https://keycloak.dev.sms-digital.cloud";

export const keycloak = new Keycloak({
  url: KEYCLOAK_URL,
  realm: "smsdigital",
  clientId: "digital-twin",
});

export const isAuthenticated = () => {
  return keycloak.authenticated;
};

export const getToken = () => keycloak.token;

export const login = (token: string) => {
  // TODO: not sure we want/need to do more here. Keycloak seems to do do everything on it's own
  return keycloak.authenticated;
};

export const loggedUser = () => {
  return keycloak.tokenParsed || {};
};

export const logout = () => {
  keycloak.logout();
};

export const updateToken = async (minValidity: number) => {
  return await keycloak.updateToken(minValidity);
};
