import React from "react";
import TextField from "@mui/material/TextField";
import { SxProps, Theme } from "@mui/material";

interface Props {
  children: JSX.Element;
  label: string;
  sx: SxProps<Theme>;
  style: React.CSSProperties;
}

const InputComponent = ({ inputRef, ...other }: any) => <div {...other} />;
const TextfieldDiv = ({ children, label, sx, style }: Props) => {
  return (
    <TextField
      style={style}
      variant="standard"
      label={label}
      sx={sx}
      multiline
      InputLabelProps={{ shrink: true }}
      InputProps={{
        inputComponent: InputComponent,
        readOnly: true,
        disableUnderline: true,
      }}
      inputProps={{ children: children }}
    />
  );
};
export default TextfieldDiv;
