import { FixedTabWidgetLayout } from "../../components/Dashboard/FixedTabWidgetLayout";
import Maintenance from "./components/Maintenance/Maintenance";
import Details from "./components/Details/Details";
import InstallationPlaces from "./components/InstallationPlaces/InstallationPlaces";

export const MaintenanceNavigationTabsWidget = FixedTabWidgetLayout({
  displayName: "MaintenanceNavigationTabs",
  tabConfigs: {
    Details: {
      cols: 1,
      rows: 1,
      widgetConfigs: {
        MaintenanceNavigationTabs_Details_w1: {
          widget: Details,
          layout: { x: 0, y: 0, w: 1, h: 1 },
          config: { mapProps: {}, constants: {} },
        },
      },
    },
    Maintenance: {
      cols: 1,
      rows: 1,
      widgetConfigs: {
        MaintenanceNavigationTabs_Maintenance_w1: {
          widget: Maintenance,
          layout: { x: 0, y: 0, w: 1, h: 1 },
          config: { mapProps: {}, constants: {} },
        },
      },
    },
    InstallationPlaces: {
      cols: 1,
      rows: 1,
      widgetConfigs: {
        MaintenanceNavigationTabs_InstallationPlaces_w1: {
          widget: InstallationPlaces,
          layout: { x: 0, y: 0, w: 1, h: 1 },
          config: { mapProps: {}, constants: {} },
        },
      },
    },
  },
});
