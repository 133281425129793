import React, { useCallback, useMemo } from "react";
import { isEmpty } from "lodash";
import "/node_modules/react-grid-layout/css/styles.css";
import "/node_modules/react-resizable/css/styles.css";
import GridLayout, { Layout } from "react-grid-layout";
import { observer } from "mobx-react-lite";
import shallowequal from "shallowequal";

import { StoreType, WidgetConfig, WidgetContainer } from "./WidgetContainer";

import type { BaseWidget } from "./Widget";
import { ValueOf } from "ts-essentials";

export type WidgetConfigs<WT extends { [id: string]: BaseWidget }> = {
  [K in keyof WT]: WidgetConfig<WT[K]>;
};

function _updateWidgetConfigs<WT extends { [id: string]: BaseWidget }>(
  widgetConfigs: WidgetConfigs<WT>,
  layouts: Layout[]
) {
  // update the widgetConfigs from the (new) layouts.
  const newConfigs: Partial<typeof widgetConfigs> = {};

  for (let item of layouts) {
    const { i: id, ...layout } = item;
    const prevConfig = widgetConfigs[id];

    // layouts from onLayoutChange might include a dragged widget
    // current thinking is not add this here
    if (prevConfig) {
      if (!shallowequal(prevConfig.layout, layout)) {
        newConfigs[id as keyof typeof widgetConfigs] = {
          ...prevConfig,
          layout,
        };
      }
    }
  }

  if (!isEmpty(newConfigs)) {
    return { ...widgetConfigs, ...newConfigs };
  } else {
    return widgetConfigs;
  }
}

interface Props<WT extends { [id: string]: BaseWidget }> {
  store: StoreType<ValueOf<WidgetConfigs<WT>>>; // TODO: this should be the intersection not the union ...
  widgetConfigs: WidgetConfigs<WT>;
  droppingWidget?: { width: number; height: number };
  width: number;
  height: number;
  rows: number;
  cols: number;
  compactType?: "vertical" | "horizontal";
  edit?: boolean;
  margin?: [number, number];
  containerPadding?: [number, number];
  preventCollision?: boolean;
  onDroppedWidget?: (
    currentWidgetConfigs: WidgetConfigs<WT>, // does NOT contain the dropped widget
    layout: Omit<Layout, "i">
  ) => void;
  onLayoutChange?: (newWidgetConfigs: WidgetConfigs<WT>) => void;
  onDeleteWidget?: (deletedWidgetId: string) => void;
  save?: (widgetId: string) => void;
}

export const WidgetLayout = observer(
  <WT extends { [id: string]: BaseWidget }>({
    store,
    widgetConfigs,
    droppingWidget,
    width,
    height,
    rows,
    cols,
    compactType,
    edit,
    margin,
    containerPadding,
    preventCollision,
    onDroppedWidget,
    onLayoutChange,
    onDeleteWidget,
    save,
  }: Props<WT>) => {
    /**/

    // TODO: we could be a little more clever how to treat rounding errors in
    // column/row witdth and maybe tweak containerPadding
    const _compactType = useMemo(() => compactType || "vertical", [compactType]);
    const _margin = useMemo<[number, number]>(() => margin || [10, 10], [margin]);

    const _containerPadding = useMemo<[number, number]>(
      () => containerPadding || _margin,
      [containerPadding, _margin]
    );

    const rowHeight = useMemo(
      () => Math.floor((height - 2 * _containerPadding[1] + (1 - rows) * _margin[1]) / rows),
      [height, rows, _containerPadding, _margin]
    );

    const onDrop = useCallback(
      (currentLayouts: Layout[], newLayoutItem: Layout) => {
        if (onDroppedWidget && droppingWidget) {
          const currentWidgetConfigs = _updateWidgetConfigs(widgetConfigs, currentLayouts);

          const { i: _, ...layout } = newLayoutItem;

          onDroppedWidget(currentWidgetConfigs, layout);
        }
      },
      [widgetConfigs, droppingWidget, onDroppedWidget]
    );

    const _onLayoutChange = useCallback(
      (newLayouts: Layout[]) => {
        if (onLayoutChange) {
          const newConfigs = _updateWidgetConfigs(widgetConfigs, newLayouts);
          if (newConfigs !== widgetConfigs) {
            onLayoutChange(newConfigs);
          }
        }
      },
      [widgetConfigs, onLayoutChange]
    );

    const layouts: Layout[] = useMemo(
      () =>
        Object.entries(widgetConfigs).map(([id, wc]) => ({
          i: id,
          ...wc.layout,
        })),
      [widgetConfigs]
    );

    const droppingItem = useMemo(
      () =>
        droppingWidget && {
          i: "__dropping_item__",
          w: droppingWidget.width,
          h: droppingWidget.height,
        },
      [droppingWidget]
    );

    const gridStyle = useMemo(
      () => ({
        backgroundColor: edit ? "lightblue" : "transparent",
        display: "inline-block",
        width,
        height,
      }),
      [edit, width, height]
    );

    const gridItemDivStyle = useMemo(
      () => ({
        ...(gridStyle || {
          border: "1px solid grey",
          borderRadius: 0,
          padding: 0,
          backgroundColor: "#F2F2F2",
        }),
        cursor: !edit ? "pointer" : "move",
      }),
      [edit, gridStyle]
    );

    return (
      <GridLayout
        layout={layouts}
        isDraggable={edit}
        isResizable={edit}
        resizeHandles={edit ? ["se"] : []}
        isDroppable={droppingWidget !== undefined}
        cols={cols}
        maxRows={rows}
        rowHeight={rowHeight}
        autoSize={false}
        compactType={_compactType}
        width={width}
        margin={_margin}
        containerPadding={_containerPadding}
        preventCollision={preventCollision}
        droppingItem={droppingItem}
        className="layout"
        useCSSTransforms={false}
        style={gridStyle}
        onLayoutChange={_onLayoutChange}
        onDrop={onDrop}
      >
        {Object.keys(widgetConfigs).map((id) => {
          // TODO: any chance to unpack the individual widget types
          //  widget is WT[id1] | WT[id2] | ... | WT[idN].
          //  Using a mapped type we could get at least
          //  WidgetContainer<W[id1], typeof store> | ...
          //  | WidgetContainer<W[idN], typeof store>
          const { widget, config } = widgetConfigs[id as keyof WT];

          return (
            // TODO: would look cleaner if the <div> was part of the
            // WidgetContainer but that seems to break GridLayout
            // (obviously explicitly looking for <div>, MUI does the same
            //  all over the place, severely misguided ...)
            <div key={id} style={gridItemDivStyle}>
              <WidgetContainer
                key={id}
                id={id}
                widget={widget}
                store={store}
                mapProps={config.mapProps}
                constants={config.constants}
                save={save}
                preview={false}
                edit={edit}
                onDeleteWidget={onDeleteWidget}
              />
            </div>
          );
        })}
      </GridLayout>
    );
  }
);
