import React, { useState } from "react";

//material ui
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

//internals
import DtModal from "../../../components/Modal";
import DataSourceConfiguration from "./dataSourceConfiguration";
import DashboardPanels from "./dashboardPanels";
import { Pannel } from "../../../app/models/presentation";

const useStyles = makeStyles(() => ({
  chartContent: {
    position: "relative",
    height: "100%",
    minHeight: "100%",
  },
  container: {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    gap: "50px",
    paddingTop: 20,
  },
}));

interface Props {
  chartType: any;
  onAddQuery: (panelContent: any) => void;
  pannelId: string;
  content: any;
  handleEditable: (editable: boolean) => void;
}

const PanelContent = ({ chartType, onAddQuery, pannelId, content, handleEditable }: Props) => {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState(false);
  const [panelContent, setPanelContent] = useState<Pannel>({
    ...content,
    pannelId,
  });

  const openModal = () => {
    setModalOpen(true);
    //parente cannot edit panels
    handleEditable(false);
  };

  const handlModalClose = () => {
    handleEditable(true);
    setModalOpen(false);
  };

  const confirmDatasource = () => {
    onAddQuery(panelContent);
    setModalOpen(false);
    handleEditable(true);
  };

  const clearDatasource = () => {
    setPanelContent({ ...content, panelid: null });
  };

  const onChange = (content: any) => {
    setPanelContent(content);
  };

  const renderModalContent = (type: string) => {
    return (
      <DataSourceConfiguration
        pannelId={pannelId}
        type={type}
        onChange={onChange}
        showSelectedDefaults={false}
        panelContent={panelContent}
      />
    );
  };

  const isPanelEmpty = () => {
    // todo: check wich infor is required
    return !modalOpen && !panelContent.partId && !panelContent.title;
  };

  return (
    <>
      <DtModal
        open={modalOpen}
        title={"Configure Datasource"}
        showCancelButton={true}
        showConfirmButton={true}
        closeButtonName="Cancel"
        handleConfirm={confirmDatasource}
        showContentText={false}
        width={"lg"}
        handleClose={handlModalClose}
      >
        {renderModalContent(chartType)}
      </DtModal>

      {isPanelEmpty() && (
        <Button variant="contained" color={"primary"} onClick={openModal}>
          Add query
        </Button>
      )}
      {!isPanelEmpty() && (
        <>
          <Grid item xs className={`${classes.chartContent}`}>
            <DashboardPanels
              dashboardItem={{
                ...content,
                pannelContent: panelContent,
                visualization_type: chartType,
              }}
              daily={0}
            />

            <div className={classes.container}>
              <Button variant="contained" color="secondary" onClick={clearDatasource}>
                Clear
              </Button>
              <Button variant="contained" color="secondary" onClick={openModal}>
                Edit
              </Button>
            </div>
          </Grid>
        </>
      )}
    </>
  );
};

PanelContent.defaultProps = {
  handleEditable: () => {},
};

export default PanelContent;
