import React from "react";
import { installationTreeResource } from "../../../../../services/suspenders";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
//Material-UI
import { Button, Grid, TextField } from "@mui/material";
import { Style } from "../../../../../themes/Style";
import TextfieldDiv from "../../../../../components/textfieldDiv";

interface Props {
  partId: string;
  setPosition: (position: string | null) => void;
}

export default function GeneralDetails({ partId, setPosition }: Props) {
  const { t } = useTranslation();
  const history = useHistory();
  const { installableParts } = installationTreeResource.read();
  const part = installableParts[partId];
  const currentPosition = part.currentlyInstalledAt;
  const positionName = currentPosition?.name;
  if (partId === "c90afb55-7edd-4003-87ab-00ec73b9d65a") {
    return (
      <div>
        <Grid container>
          <TextField
            variant="standard"
            label={t("grid.label.name")}
            value={part.name}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            sx={Style.Textfield2}
          />
        </Grid>
        <Grid container>
          <TextField
            style={{ width: "100%" }}
            variant="standard"
            label={t("grid.label.id")}
            value={part.id}
            InputProps={{ readOnly: true, disableUnderline: true }}
            InputLabelProps={{ shrink: true }}
            sx={Style.Textfield2}
          />
        </Grid>
        <Grid
          container
          style={{ width: "100%" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid xs={2.5} item>
            <TextField
              variant="standard"
              label={t("castersetup.details.grade")}
              value={"1100"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid xs={3} item>
            <TextField
              variant="standard"
              label={t("castersetup.details.heatID")}
              value={"00000157"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ width: "100%", marginTop: 10 }}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid xs={2.5} item>
            <TextField
              variant="standard"
              label={t("castersetup.details.startTime")}
              value={"2021-06-01 08:30"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid xs={2} item>
            <TextField
              variant="standard"
              label={t("castersetup.details.castTons")}
              value={"200"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
        </Grid>
      </div>
    );
  } else {
    return (
      <div>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
          <Grid item style={{ width: "100%" }}>
            <TextField
              style={{ width: "100%" }}
              variant="standard"
              label={t("grid.label.name")}
              value={part.name}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField
              style={{ width: "100%" }}
              variant="standard"
              label={t("grid.label.id")}
              value={part.id}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: "100%" }}
              variant="standard"
              label={t("castersetup.details.consumes")}
              value={"Energy"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid item>
            <TextField
              style={{ width: "100%" }}
              variant="standard"
              label={t("castersetup.details.isPartOf")}
              value={"drive chain"}
              InputProps={{ readOnly: true, disableUnderline: true }}
              InputLabelProps={{ shrink: true }}
              sx={Style.Textfield2}
            />
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextfieldDiv
              style={{ width: "100%" }}
              label={t("castersetup.details.isInstalledAt")}
              sx={Style.Textfield2}
            >
              {currentPosition ? (
                <Button
                  sx={Style.linkButton}
                  size="large"
                  onClick={() => {
                    if (currentPosition) {
                      history.push(`../caster/${currentPosition.belongsTo.id}`);
                      setPosition(currentPosition.id);
                    }
                  }}
                >
                  {`${positionName}`}
                </Button>
              ) : (
                <p>{t("castersetup.details.notInstalled")}</p>
              )}
            </TextfieldDiv>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <Grid xs={2} item>
              <TextField
                style={{ width: "100%" }}
                variant="standard"
                label={t("castersetup.details.installationDate")}
                value={"2021-06-01"}
                InputProps={{ readOnly: true, disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                sx={Style.Textfield2}
              />
            </Grid>
            <Grid xs={2} item>
              <TextField
                style={{ width: "100%" }}
                variant="standard"
                label={t("castersetup.details.castTons")}
                value={"200 t"}
                InputProps={{ readOnly: true, disableUnderline: true }}
                InputLabelProps={{ shrink: true }}
                sx={Style.Textfield2}
              />
            </Grid>
          </Grid>
          <TextfieldDiv
            style={{ width: "100%" }}
            label={t("castersetup.details.canBeInstalledAt")}
            sx={Style.Textfield2}
          >
            {part.installableAt.length !== 0 ? (
              <Grid container spacing={2} direction="row" justifyContent="flex-start" alignItems="flex-start">
                {part.installableAt.map((position) => (
                  <Grid key={position.id} item>
                    <Button
                      sx={Style.linkButton}
                      size="large"
                      onClick={() => {
                        if (currentPosition) {
                          history.push(`../caster/${position.belongsTo.id}`);
                          setPosition(position.id);
                        }
                      }}
                    >
                      {position.name}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            ) : (
              <p>{t("castersetup.details.noPlaceAvailable")}</p>
            )}
          </TextfieldDiv>
        </Grid>
      </div>
    );
  }
}
