/* eslint-disable react/prop-types */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import CardHeader from "@material-ui/core/CardHeader";
import Chip from "@material-ui/core/Chip";
import { Pannel } from "../../../app/models/presentation";

const useStyles = makeStyles({
  container: {
    height: "100%",
  },
  cardHeader: {
    cursor: "move",
    maxHeight: 30,
    textAlign: "center",
  },
});

interface Props {
  selectable: boolean;
  cockpitItem: Pannel;
  handlerChecked: (panel: Pannel, checked: boolean) => {};
  defaultCheck: boolean;
  onRemoveItem: (id: string) => void;
  removable: boolean;
}

const CardItem = ({
  selectable,
  cockpitItem,
  handlerChecked,
  defaultCheck = false,
  onRemoveItem,
  removable = false,
}: Props) => {
  const classes = useStyles();
  const [checked, setChecked] = React.useState(defaultCheck || false);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    handlerChecked(cockpitItem, event.target.checked);
  };

  const handleDelete = () => {
    onRemoveItem(cockpitItem.id);
  };

  return (
    <Card className={classes.container}>
      <CardHeader
        className={classes.cardHeader}
        action={
          <>
            {selectable && (
              <Checkbox data-testid="panel-item-checkeble" checked={checked} onChange={handleChange} />
            )}

            {removable && <Chip label="" onDelete={handleDelete} color="primary" />}
          </>
        }
        title={cockpitItem.name}
      />

      <CardContent className={classes.container}>
        {/* render content if has */}
        {cockpitItem.content && <>{cockpitItem.content()}</>}
        {/* else render visualization type for while */}
        {!cockpitItem.content && (
          <>
            <Typography paragraph>Type:</Typography>
            <Typography paragraph>{cockpitItem.visualization_type}</Typography>
          </>
        )}
      </CardContent>
    </Card>
  );
};

CardItem.defaultProps = {
  handlerChecked: () => {},
  selectable: false,
  defaultCheck: false,
  removable: false,
  onRemoveItem: () => {},
};
export default CardItem;
