import React, { useState, ComponentProps, ComponentType, useMemo, useCallback } from "react";
import _ from "lodash";
import { Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { InferProps } from "prop-types";
import { ValueOf } from "ts-essentials";
import { useElementSize } from "usehooks-ts";

import { Style } from "../../themes/Style";
import { BaseWidget, LayoutConstraints } from "./Widget";
import { WidgetLayout, WidgetConfigs } from "./WidgetLayout";
import { useWidgetStore, derivePropTypes } from "./widgetStore";

interface TabConfig<WT extends { [id: string]: BaseWidget }> {
  rows: number;
  cols: number;
  compactType?: "vertical" | "horizontal";
  margin?: [number, number];
  containerPadding?: [number, number];
  widgetConfigs: WidgetConfigs<WT>;
}

interface Props<TC extends { [id: string]: { [id: string]: BaseWidget } }> {
  displayName: string;
  tabConfigs: { [title in keyof TC]: TabConfig<TC[title]> };
  layoutConstraints?: LayoutConstraints;
  // TODO: what kind of save function could/should be supported?
}

type SizeWrapperProps<C extends ComponentType> = Omit<ComponentProps<C>, "width" | "height"> & {
  childComponent: C;
};

// TODO: this should be a standard component from somewhere NOT roll-your-own ...
const SizeWrapper = React.forwardRef(function SizeWrapper<C extends ComponentType<any>>(
  props: SizeWrapperProps<C>,
  ref: React.ForwardedRef<C>
) {
  const [outerDivRef, { width, height }] = useElementSize();

  const Child = props.childComponent;
  const innerProps = useMemo(
    () => ({ ..._.omit(props, ["childComponent"]), width, height }),
    [props, width, height]
  ) as unknown as JSX.LibraryManagedAttributes<C, {}>; // TODO: any chance w/o cast?

  return (
    <div ref={outerDivRef} style={{ width: "100%", height: "100%" }}>
      <Child ref={ref} {...innerProps} />
    </div>
  );
});

/** HOC to create a tab widget
 *
 *  Creates tabs each containing a WidgetLayout with a shared store.
 *
 *  TODO: I am not 100% the mapping will guarantee order of the tabs, AFAIK
 *   JS spec is not explicit on whether key order is preserved.
 *
 *  TODO: There is currently no way to use this as a controlled tab component.
 *   Would be desirable to have this opt-in or failing that make it controlled
 *   in the first place
 *
 *  TODO: This does not offer a way to localize tab titles, yet. Need to have
 *   separate title and id (value) on the tabs in the long run ...
 *
 *  TODO: Currently, we are very strict about types, for example string? and
 *   string will not unify to string.
 *
 *  ATTN: This should only be called once to create a new widget. (Probably
 *  best called on module level.) Even if we tried the property types are
 *  not memoization friendly.
 *
 *  TODO: Typing in widgetStore.ts is poor. Widgets created using this will
 *   not have meaningful TS types.
 */
export function FixedTabWidgetLayout<TC extends { [id: string]: { [id: string]: BaseWidget } }>({
  displayName,
  tabConfigs,
  layoutConstraints,
}: Props<TC>) {
  // TODO: this should be the intersection type not the union but this I am not sure
  //  how to tell typescript that the widget ids are disjoint (and if that would be
  //  enough to get things to work ...)
  const widgetConfigs: WidgetConfigs<ValueOf<TC>> = _.assign(
    // configs can be safely merged b/c ids of the widget instances are unique
    {} as WidgetConfigs<ValueOf<TC>>,
    ...Object.values(tabConfigs).map((tc) => tc.widgetConfigs)
  );

  // TODO: typing of derivePropTypes is lacking, resulting widget will not
  //  have meaningful TS type ...
  const [propTypes, defaultProps] = derivePropTypes(widgetConfigs);
  const tabs = Object.keys(tabConfigs).map((t) => <Tab key={t} value={t} label={t} sx={Style.Tablist} />);
  const initialTab = Object.keys(tabConfigs)[0];

  const FixedTabWidget = (props: InferProps<typeof propTypes>) => {
    const store = useWidgetStore(props, widgetConfigs);
    const [selectedTab, setSelectedTab] = useState<string>(initialTab);

    const panels = useMemo(
      () =>
        Object.entries(tabConfigs).map(([t, c]) => (
          <TabPanel key={t} value={t}>
            <SizeWrapper
              childComponent={WidgetLayout}
              store={store}
              widgetConfigs={c.widgetConfigs}
              rows={c.rows}
              cols={c.cols}
              compactType={c.compactType}
              margin={c.margin}
              containerPadding={c.containerPadding}
            />
          </TabPanel>
        )),
      [store]
    );

    const handleSelect = useCallback(
      (event: React.SyntheticEvent, value: string) => setSelectedTab(value),
      []
    );

    return (
      <TabContext value={selectedTab}>
        <TabList
          style={{ backgroundColor: "#F2F2F2" }}
          TabIndicatorProps={{ style: { top: "0px" } }}
          onChange={handleSelect}
          aria-label="lab API tabs example"
        >
          {tabs}
        </TabList>
        {panels}
      </TabContext>
    );
  };
  FixedTabWidget.displayName = displayName;
  FixedTabWidget.widgetPropTypes = propTypes;
  FixedTabWidget.widgetDefaultProps = defaultProps;
  FixedTabWidget.layoutConstraints = layoutConstraints;

  return FixedTabWidget;
}
