import React, { Suspense, useState, useEffect } from "react";
import Logo from "./assets/icons/sdig_white.svg";
import LogoBlack from "./assets/icons/sdig_black.svg";
import { BrowserRouter as Router, Switch, Route, Link, useLocation } from "react-router-dom";
import AssetDictionary from "./pages/AssetDictionary";
import Ontology from "./pages/Ontology";
import Cockpits from "./pages/Cockpits";
import Dashboards from "./pages/Dashboards";
import UnAuthorized from "./pages/UnAuthorized";
import Caster from "./pages/CasterSetup"; //TODO: Implement index file
//Material-UI

import {
  Button,
  MenuItem,
  Divider,
  Drawer,
  Toolbar,
  AppBar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  CssBaseline,
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { getToken } from "./services/auth";
import { logout } from "./services/auth";
import APIProgressBarNotification from "./components/APIProgressBar";
import axios from "axios";
import getMenu from "./components/Menus";
import { useTranslation } from "react-i18next";
import { environment } from "./environments/environments";
import { Style } from "./themes/Style";
import { Colors } from "./themes/DefaultTheme";

axios.defaults.baseURL = environment.API_URL;

axios.interceptors.request.use(async (config) => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

const LANG_KEY = "@sms/dt:language";
const languageFromLocalStorage = localStorage.getItem(LANG_KEY) || "en";

function DigitalTwin() {
  const { i18n, t } = useTranslation();
  const location = useLocation();
  const [lang, setLang] = useState(languageFromLocalStorage);
  useEffect(() => {
    localStorage.setItem(LANG_KEY, lang);
  }, [lang]);

  const [open, setOpen] = useState(false);
  const [menus, setMenus] = useState(getMenu);
  const [title, setTitle] = useState("");

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListClick = (sender: string) => {
    setTitle(sender);
    setOpen(false);
  };

  const handleLogout = () => {
    logout();
    window.location.reload();
  };

  const handleLanguageChange = (e: SelectChangeEvent) => {
    setLang(e.target.value);
    i18n.changeLanguage(e.target.value as string);
  };

  useEffect(() => {
    switch (location.pathname.split("/app")[1].split("/")[1]) {
      case undefined:
        setTitle("app.menu.assetdictionary");
        break;
      case "caster":
        setTitle("app.menu.castersetup");
        break;
      case "cockpits":
        setTitle("app.menu.cockpits");
        break;
      case "dashboards":
        setTitle("app.menu.dashboards");
        break;
      case "ontology":
        setTitle("app.menu.ontology");
        break;
    }
  }, [location]);

  //TODO: Split in components (refactor)
  return (
    <div style={{ height: "100vh", backgroundColor: Colors.pageBackground }}>
      <Router>
        <CssBaseline />
        <AppBar position="static" sx={Style.appBar}>
          <Toolbar>
            <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start">
              <MenuIcon />
            </IconButton>
            <img src={Logo} width="20" alt={"logo"} style={{ marginLeft: 28 }} />
            <Typography variant="h6" style={{ marginLeft: 28, flexGrow: 1 }}>
              {t("title")} - {t(title)}
            </Typography>
            <Select
              IconComponent={KeyboardArrowDownIcon}
              labelId="language"
              id="language"
              value={lang}
              onChange={handleLanguageChange}
              inputProps={{ "aria-label": "Without label" }}
              sx={Style.languageSelector}
            >
              <MenuItem value={"en"}>EN</MenuItem>
              <MenuItem value={"ru"}>RU</MenuItem>
            </Select>
            <p></p>
            <Button onClick={handleLogout} color="inherit">
              <LogoutIcon />
            </Button>
          </Toolbar>
        </AppBar>
        <div />
        <Drawer
          onBackdropClick={() => {
            handleDrawerClose();
          }}
          anchor="left"
          open={open}
        >
          <div>
            <Toolbar style={{ paddingLeft: 15 }}>
              <>
                <IconButton
                  onClick={() => {
                    handleDrawerClose();
                  }}
                >
                  <MenuIcon style={{ color: "#666666" }} />
                </IconButton>
                <img src={LogoBlack} width="20" alt={"logo"} style={{ marginLeft: 28 }} />
                <Typography
                  variant="h6"
                  style={{ color: "#666666", marginLeft: 28, marginRight: 25, fontWeight: "bold" }}
                >
                  {t("title")}
                </Typography>
              </>
            </Toolbar>
          </div>
          <Divider />
          <List style={{ marginLeft: 8 }}>
            {menus.map((menu) => {
              return (
                <div key={menu.id}>
                  {menu.visible && (
                    <ListItem
                      onClick={() => handleListClick(menu.name)}
                      button
                      key={menu.path}
                      component={Link}
                      to={menu.path}
                    >
                      <ListItemIcon>
                        <InboxIcon />
                      </ListItemIcon>
                      <ListItemText primary={t(menu.name)} key={menu.name} />
                    </ListItem>
                  )}
                </div>
              );
            })}
          </List>
        </Drawer>
        <div key={"apiprogressbar"} style={{ height: 10 }}>
          <APIProgressBarNotification />
        </div>
        <Grid
          component="div"
          sx={{
            /** TODO: hard-coded app bar height and progress bar */ height: "calc(100% - 64px - 10px)",
            width: "100%",
          }}
        >
          <Switch>
            <Route path="/app/cockpits">
              <Cockpits />
            </Route>
            <Route path="/app/dashboards">
              <Dashboards />
            </Route>
            <Route path="/app/caster">
              <Suspense fallback="Loading <CasterSetup /> from <DigitalTwin /> ...">
                <Caster />
              </Suspense>
            </Route>
            <Route path="/app/ontology">
              <Ontology />
            </Route>
            <Route path="/app/unauthorized">
              <UnAuthorized />
            </Route>
            <Route path="/app">
              <AssetDictionary />
            </Route>
          </Switch>
        </Grid>
      </Router>
    </div>
  );
}
export default DigitalTwin;
