import React from "react";
import PartSearch from "../PartSearch";
import { PartItem } from "../../../../app/models/partitem";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

interface Props {
  onPartSelect: (part: PartItem) => void;
  parent: string;
  relation: string;
  reverse: boolean;
}

export default function ChildToParent({ parent, relation, onPartSelect, reverse }: Props) {
  const { t } = useTranslation();
  const handleRelatedPartSelect = (value: PartItem) => {
    if (value && value?.id) {
      onPartSelect(value);
    }
  };

  if (reverse) {
    return (
      <>
        <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
          <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
            {t("page.addproperty.part.label")}
          </Box>
          <PartSearch width="60%" onItemSelect={handleRelatedPartSelect} />
        </Box>
        <Box sx={{ fontWeight: "bold", marginLeft: "220px" }} component={"div"} p={1}>
          {relation}
        </Box>
        <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
          <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
            {t("page.addproperty.location.label")}
          </Box>
          <Box component={"div"} p={1}>
            {parent}
          </Box>
        </Box>
      </>
    );
  } else {
    return (
      <>
        <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
          <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
            {t("page.addproperty.location.label")}
          </Box>
          <Box component={"div"} p={1}>
            {parent}
          </Box>
        </Box>
        <Box sx={{ fontWeight: "bold", marginLeft: "220px" }} component={"div"} p={1}>
          {relation}
        </Box>
        <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
          <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
            {t("page.addproperty.part.label")}
          </Box>
          <PartSearch width="60%" onItemSelect={handleRelatedPartSelect} />
        </Box>
      </>
    );
  }
}
