import React from "react";

//permissions
import UnAuthorized from "../../pages/UnAuthorized";
import PermissionsService from "../../services/permissionsService";
import { enRoles } from "../../app/models/permissions";

type RequireAuthorizationProps = {
  roles: enRoles[];
  children?: React.ReactNode;
  permissions?: string;
};

export default function RequireAuthorization(props: RequireAuthorizationProps) {
  const { roles, children, permissions } = props;
  const hasAuthorization =
    roles.some((r) => PermissionsService.isUserInRole(r)) ||
    PermissionsService.hasPermission(permissions ?? "");
  return hasAuthorization ? <>{children}</> : <UnAuthorized />;
}

export function OmitIfUnAuthorized(props: RequireAuthorizationProps) {
  const { roles, children } = props;
  const hasAuthorization = roles.some((r) => PermissionsService.isUserInRole(r));
  return hasAuthorization ? <>{children}</> : null;
}
