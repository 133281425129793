import React, { useEffect, useCallback } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import CasterSetup from "./CasterSetup";
import { gltfResource } from "./components/Part3d";
import RequireAuthorization from "../../components/RequireAuthorization/RequireAuthorization";
import { enPermission, enRoles } from "../../app/models/permissions";
import { installationTreeResource } from "../../services/suspenders";

export default function Caster() {
  let match = useRouteMatch();
  gltfResource.preload("STRANDCASTER6"); // TODO: this is cheating but I do not see another way of overlapping with installationTreeResource.read()
  const { roots, installableParts } = installationTreeResource.read();
  const root = roots[0];
  const defaultRootId = root?.id || "<no default 3d model available>";

  useEffect(() => {
    // start preloading the remaining models
    Object.values(installableParts).forEach((p) => {
      if (p?.systems["DIGITALTWIN3D"]) gltfResource.preload(p.systems["DIGITALTWIN3D"]);
    });
  }, [root, installableParts]);

  const redirectToDefault = useCallback(() => {
    return <Redirect to={`${match.path}/${defaultRootId}`} />;
  }, [defaultRootId, match.path]);

  const renderCasterSetup = useCallback(
    ({ match }) => {
      const partId = match.params.partId;
      if (!installableParts[partId])
        return (
          <div>
            <h3>
              Unknown part id <code>{partId}</code>
            </h3>
          </div>
        );
      return <CasterSetup partId={partId} />;
    },
    [installableParts]
  );

  return (
    <RequireAuthorization
      roles={[enRoles.CASTER_ADMIN, enRoles.CASTER_STANDARD]}
      permissions={enPermission.CASTER_READ}
    >
      <Router>
        <Switch>
          <Route exact path={`${match.path}`} render={redirectToDefault} />
          <Route exact path={`${match.path}/:partId`} render={renderCasterSetup} />
        </Switch>
      </Router>
    </RequireAuthorization>
  );
}
