import React, { useCallback, useMemo } from "react";
import { get as mobxGet } from "mobx";
import { observer } from "mobx-react-lite";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { IconButton } from "@mui/material";
import { Layout } from "react-grid-layout";

import { BaseWidget, setterName, WidgetProps } from "./Widget";

/** Configure a widget to be rendered in a Dashboard/FixedWidgetLayout
 *
 *  `widget` is the actual widget component. `layout` is a layout as in
 *  `react-grid-layout` but with the id omitted, ie `{x: column, y: row,
 *   w: width in columns, h: height in rows }`. `config` allows to map
 *   properties in the store to widget properties and to set widget properties
 *   constant.
 */
export interface WidgetConfig<W extends BaseWidget> {
  widget: W;
  layout: Omit<Layout, "i">;
  config: {
    mapProps: {
      // TODO: any chance blocking inconsistent prop/setter pairs post mapping?
      [storeName: string]: keyof WidgetProps<W>;
    };
    constants: {
      // TODO: any chance preventing setting setters constant?
      [propName in keyof WidgetProps<W>]?: WidgetProps<W>[propName];
    };
  };
}

export type StoreType<WC> = WC extends WidgetConfig<infer W>
  ? Omit<WidgetProps<W>, "preview" | "save" | keyof WC["config"]["constants"]>
  : {};

type WidgetContainerProps<W extends BaseWidget, WC extends WidgetConfig<W>, ST extends StoreType<WC>> = {
  store: ST;
  id: string;
  widget: W;
  mapProps: WC["config"]["mapProps"];
  constants: WC["config"]["constants"];
  save?: (id: string) => void;
  preview?: boolean;
  edit?: boolean;
  onDeleteWidget?: (widgetId: string) => void;
};

export const WidgetContainer = observer(
  <W extends BaseWidget, WC extends WidgetConfig<W>, ST extends StoreType<WC>>({
    id,
    widget,
    store,
    mapProps,
    constants,
    save,
    preview,
    edit,
    onDeleteWidget,
  }: WidgetContainerProps<W, WC, ST>) => {
    const mappedProps = Object.fromEntries(
      Object.entries(mapProps).map(([storeName, propName]) => [propName, mobxGet(store, storeName)])
    );

    const props = Object.fromEntries(
      Object.keys(widget.widgetPropTypes)
        .filter((propName) => !Object.values(mapProps).includes(propName) && !(propName in constants))
        .map((propName) => [propName, mobxGet(store, propName)])
    );

    // TODO: preview/save should be treated like constants
    const previewProp = "preview" in widget.widgetPropTypes ? { preview } : {};

    const saveProp = "save" in widget.widgetPropTypes ? { save: save ? () => save(id) : () => {} } : {};

    const constSetters = Object.fromEntries(
      // replace setters for values set constant by dummies
      Object.keys(constants)
        .filter((propName) => setterName(propName) in widget.widgetPropTypes)
        .map((propName) => [setterName(propName), (v: any) => {}])
    );

    const widgetProps = {
      ...mappedProps,
      ...props,
      ...constants,
      ...constSetters,
      ...previewProp,
      ...saveProp,
    };

    const Wrapped = useMemo(() => observer(widget), [widget]);

    const deleteOverlayStyle = useMemo(
      () =>
        ({
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "rgba(105, 105, 105, 0.5)",
        } as React.CSSProperties),
      []
    );

    const deleteButtonStyle = useMemo(
      () =>
        ({
          color: "white",
          position: "absolute",
          top: 0,
          width: 25,
          height: 25,
          left: 0,
          cursor: "default",
        } as React.CSSProperties),
      []
    );

    const onDelete = useCallback(() => {
      if (onDeleteWidget) {
        onDeleteWidget(id);
      }
    }, [id, onDeleteWidget]);

    return (
      <>
        <Wrapped {...widgetProps} />
        {edit && onDeleteWidget ? (
          <>
            <div style={deleteOverlayStyle}></div>
            <IconButton id={id} style={deleteButtonStyle} onClick={onDelete}>
              <DeleteForeverOutlinedIcon />
            </IconButton>
          </>
        ) : null}
      </>
    );
  }
);
