/* eslint-disable react/display-name */
/**
 * Render list of Dashboards and Cockpits with Run and Edit redirects
 */

import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import { DataGrid, GridCellParams, GridColDef } from "@mui/x-data-grid";
import { IconButton } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import PlayCircleIcon from "@mui/icons-material/PlayCircleFilledOutlined";

import { Presentation, PresentationStatus } from "../../app/models/presentation";
import UserPreferenceService from "../../services/userPreferences";
import ConfirmDialog from "../ConfirmDialog/dialog";
import { useTranslation } from "react-i18next";
import DeleteIcon from "@mui/icons-material/Delete";
import PostAddIcon from "@mui/icons-material/PostAdd";
import useWindowSize from "../../app/customHooks";

const useStyles = makeStyles((theme) => ({
  dataGrid: theme.dataGrid,

  fixedHeight: {
    minHeight: window.innerHeight - 64 - 10 - 50,
    maxHeight: window.innerHeight - 64 - 10 - 50,
    backgroundColor: "red",
  },
  tableButton: { color: theme.link },
  dotActive: {
    width: "16px",
    height: "16px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    boxSizing: "border-box",
    color: "#48d82b",
    textAlign: "center",
    lineHeight: "normal",
  },
  inActive: {
    width: "16px",
    height: "16px",
    backgroundColor: "rgba(255, 255, 255, 0)",
    boxSizing: "border-box",
    color: "#d80003",
    textAlign: "center",
    lineHeight: "normal",
  },
  rectangle: {
    width: "322px",
    height: "48px",
    padding: "5px 16px 16px 16px",
    borderTop: "1px solid #e5e5e5",
    boxSizing: "border-box",
  },
}));

interface Props {
  presentations: Presentation[];
  onDeleteItem: (id: string) => void;
  canEdit: boolean;
  canRun: boolean;
  canDelete: boolean;
}

const PresentationList = ({ presentations, canEdit, canDelete, canRun, onDeleteItem }: Props) => {
  let match = useRouteMatch();
  const history = useHistory();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [page, setPage] = useState(Number(UserPreferenceService.getRowsPerPage()));
  const windowHeight = useWindowSize()[0];
  const { t } = useTranslation();

  const renderStatus = (params: GridCellParams) => {
    let presentation: Presentation = params.row;
    return presentation.status === PresentationStatus.ACTIVATED ? (
      <div className={classes.rectangle}>
        <span className={classes.dotActive}>{t("grid.label.published")}</span>
      </div>
    ) : (
      <div className={classes.rectangle}>
        <span className={classes.inActive}>{t("grid.label.revoked")}</span>
      </div>
    );
  };

  const confirmDeletion = () => {
    onDeleteItem(deleteId);
    setIsOpen(false);
  };

  const renderActions = (params: GridCellParams) => {
    return (
      <>
        {canEdit && (
          <IconButton
            aria-label="run"
            className={classes.tableButton}
            onClick={() => {
              history.push(`${match.path}/edit/${params.id.toString()}`);
            }}
          >
            <PostAddIcon />
          </IconButton>
        )}
        {canRun && (
          <IconButton
            aria-label="run"
            className={classes.tableButton}
            onClick={() => {
              history.push(`${match.path}/run/${params.id.toString()}`);
            }}
          >
            <PlayCircleIcon />
          </IconButton>
        )}
        {canDelete && (
          <>
            <IconButton
              aria-label="delete"
              className={classes.tableButton}
              onClick={() => {
                setDeleteId(params.id.toString());
                setIsOpen(true);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </>
        )}
      </>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: `${t("grid.label.id")}`,
      sortable: false,
      hide: true,
    },
    { field: "name", headerName: `${t("grid.label.name")}`, flex: 2.5 },
    {
      field: "status",
      headerName: `${t("grid.label.status")}`,
      flex: 1,
      renderCell: (params: GridCellParams) => renderStatus(params),
    },
    {
      field: "action",
      flex: 1.5,
      headerName: `${t("grid.label.action")}`,
      renderCell: (params: GridCellParams) => renderActions(params),
    },
  ];

  const handleChangePageSize = (page: number) => {
    UserPreferenceService.setRowsPerPage(page.toString());
    setPage(page);
  };

  return (
    <>
      <ConfirmDialog title="Delete Item?" open={isOpen} onConfirm={confirmDeletion} setOpen={setIsOpen}>
        Are you sure you want to delete this item?
      </ConfirmDialog>
      <Grid
        container
        style={{
          overflowY: "auto",
          maxHeight: windowHeight - 64 - 10 - 48 - 75,
        }}
      >
        <DataGrid
          getRowId={(row) => row._id}
          className={classes.dataGrid}
          rows={presentations}
          pageSize={page}
          columns={columns}
          rowsPerPageOptions={[5, 10, 20]}
          onPageSizeChange={(page: number) => handleChangePageSize(page)}
          density="compact"
          autoHeight={true}
        />
      </Grid>
    </>
  );
};

PresentationList.defaultProps = {
  canEdit: false,
  canRun: false,
};

export default PresentationList;
