import React, { useState } from "react";
import { Box, makeStyles, Theme } from "@material-ui/core";
import { Agent } from "../../app/api/agent";
import Button from "@material-ui/core/Button";
import { Autocomplete, TextField } from "@mui/material";
import useWindowSize from "../../app/customHooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    backgroundColor: theme.pageBackgroundSecondary,
  },
  container: {
    display: "flex",
    paddingBottom: theme.spacing(0),
    alignItems: "center",
  },
  label: {
    flexBasis: "100%",
    maxWidth: "1000%",
  },
  item: {
    flexBasis: "60%",
    maxWidth: "60%",
  },
  answer: {
    flexBasis: "60%",
    maxWidth: "60%",
    backgroundColor: theme.pageBackground,
  },
  textfield: {
    ...theme.autocompleteTextField,
    flexBasis: "60%",
    maxWidth: "60%",
  },
  button: { ...theme.darkButton, marginTop: 5 },
}));

const questions = ["What", "Where", "Does"];
const filtered_assets = ["LOOSESIDE", "FIXEDSIDE", "SEGMENT", "ROLLSET", "STRANDCASTER6", "MOLD"];
var querrylevel = 0;
var opt_array = questions;

var relations: any = [];
Agent.Ontology.query("relation").then(async (response: any) => {
  relations = response;
});

var asset_type: any;
var assets: any = [];
var datasources: any = [];

Agent.Parts.list("").then(async (response: any) => {
  response.forEach((element: any) => {
    if (element.nodeType !== "SUPPLY" && element.nodeType !== "DATASOURCE") {
      assets.push(element.name);
    }
    if (element.nodeType === "DATASOURCE") {
      datasources.push(element.name);
    }
  });
});

export default function QueryOntology() {
  const { t } = useTranslation();
  const windowHeight = useWindowSize()[0];
  const classes = useStyles();
  const [answer, setAnswer] = useState(" ");
  const [autoInput, setautoIn] = useState("");
  const [firstRender, setFirst] = useState(false);

  if (!firstRender) {
    opt_array = questions;
    querrylevel = 0;
    setFirst(true);
  }

  const filterOptions = () =>
    opt_array.filter((x) => x.split(" ")[querrylevel]?.toLowerCase().includes(autoInput.toLowerCase()));

  const submitQuery = async (event: any) => {
    var InputElement = document.getElementById("query") as HTMLInputElement;
    if (
      (event.key === "Enter" && InputElement.getAttribute("aria-activedescendant") === null) ||
      event.target.className === "MuiButton-label"
    ) {
      var querystring = InputElement.value;
      if (querystring !== "") {
        await Agent.Ontology.query(encodeURIComponent(querystring))
          .then(async (response: any) => {
            if (JSON.stringify(response, null, 1)[0] === "[") {
              setAnswer(
                await JSON.stringify(response, null, 1)
                  .replace(/\[/g, "")
                  .replace(/]/g, "")
                  .replace(/"/g, "")
                  .substring(1)
              );
            } else {
              setAnswer(await JSON.stringify(response, null, 1).replace(/"/g, ""));
            }
          })
          .catch((error) => setAnswer("No answer found"));
      }
    }
  };

  //function to handle the Autocomplete options
  function handleAuto(newInputValue: any) {
    var splitInput = newInputValue.split(" ");
    var wordCount = splitInput.length; //Amount of words in the string
    if (wordCount === 1) {
      querrylevel = 0;
      setautoIn(newInputValue);
      opt_array = questions;
    }
    //first word is What
    else if (newInputValue.toLowerCase().includes("what")) {
      querrylevel = 1;
      setautoIn(splitInput[1]);
      var WhatOptions: any = [];
      //Autocomplete option for second word (relation)
      if (wordCount === 2) {
        relations.forEach((element: any) => {
          WhatOptions.push("What " + element);
        });
        opt_array = WhatOptions;
      }
      //Autocomplete option for third word without has_id as second word
      else if (wordCount === 3 && splitInput[1].toLowerCase() !== "has_id") {
        querrylevel = 2;
        setautoIn(splitInput[2]);
        if (splitInput[2].length < 1) {
          asset_type = filtered_assets;
        } else {
          asset_type = assets;
        }
        WhatOptions = [];
        asset_type.forEach((element: any) => {
          WhatOptions.push(newInputValue.split(" ").slice(0, 2).join(" ") + " " + element);
        });
        opt_array = WhatOptions;
      }
      //Autocomplete option for third word with has_id as second word
      else if (wordCount > 2 && wordCount < 6) {
        querrylevel = 2;
        setautoIn(splitInput[2]);
        WhatOptions = [];
        datasources.forEach((element: any) => {
          WhatOptions.push(newInputValue.split(" ").slice(0, 2).join(" ") + " has " + element + " for");
        });
        opt_array = WhatOptions;
      }
      //Autocomplete option for sixth word with has_id as second word
      else if (wordCount === 6 && splitInput[1].toLowerCase() === "has_id") {
        querrylevel = 5;
        setautoIn(splitInput[5]);
        if (splitInput[5].length < 1) {
          asset_type = filtered_assets;
        } else {
          asset_type = assets;
        }
        WhatOptions = [];
        asset_type.forEach((element: any) => {
          WhatOptions.push(newInputValue.split(" ").slice(0, 5).join(" ") + " " + element);
        });
        opt_array = WhatOptions;
      }
    }
    //first word is Where
    else if (newInputValue.toLowerCase().includes("where")) {
      setautoIn(splitInput[1]);
      var WhereOptions: any = [];

      //Autocomplete option for second word
      if (wordCount === 2) {
        querrylevel = 1;
        if (splitInput[1].length < 1) {
          asset_type = filtered_assets;
        } else {
          asset_type = assets;
        }
        asset_type.forEach((element: any) => {
          WhereOptions.push("Where " + element);
        });
      }
      //Autocomplete option for third word
      else if (wordCount === 3) {
        querrylevel = 2;
        setautoIn(splitInput[2]);
        relations.forEach((element: any) => {
          WhereOptions.push(newInputValue.split(" ").slice(0, 2).join(" ") + " " + element);
        });
      }
      opt_array = WhereOptions;
    }
    //first word is Does
    else if (newInputValue.toLowerCase().includes("does")) {
      querrylevel = 1;
      setautoIn(splitInput[1]);
      var DoesOptions: any = [];

      //Autocomplete option for second word
      if (wordCount === 2) {
        if (splitInput[1].length < 1) {
          asset_type = filtered_assets;
        } else {
          asset_type = assets;
        }
        asset_type.forEach((element: any) => {
          DoesOptions.push("Does " + element);
        });
      }
      //Autocomplete option for third word
      if (wordCount === 3) {
        querrylevel = 2;
        setautoIn(splitInput[2]);
        relations.forEach((element: any) => {
          DoesOptions.push(newInputValue.split(" ").slice(0, 2).join(" ") + " " + element);
        });
      }
      //Autocomplete option for fourth word
      else if (wordCount === 4) {
        querrylevel = 3;
        setautoIn(splitInput[3]);
        if (splitInput[3].length < 1) {
          asset_type = filtered_assets;
        } else {
          asset_type = assets;
        }
        asset_type.forEach((element: any) => {
          DoesOptions.push(newInputValue.split(" ").slice(0, 3).join(" ") + " " + element);
        });
      }
      opt_array = DoesOptions;
    }
  }

  return (
    <div
      className={classes.root}
      style={{
        height: windowHeight - 64 - 10,
        marginLeft: 24,
        marginRight: 24,
        marginBottom: 10,
      }}
      onLoad={(e) => console.log("load")}
    >
      <Box className={classes.container}></Box>
      <Box className={classes.container}>
        <Autocomplete
          className={classes.textfield}
          id="query"
          freeSolo
          onInputChange={(event, newInputValue) => {
            handleAuto(newInputValue);
          }}
          options={questions}
          getOptionLabel={(option) => option}
          filterOptions={filterOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={t("ontology.inputPlaceholder")}
              variant="outlined"
              InputLabelProps={{ shrink: false }}
              onKeyDown={submitQuery}
            />
          )}
        />
      </Box>
      <Box className={classes.container}>
        <Button className={classes.button} autoFocus variant="contained" onClick={submitQuery}>
          {t("ontology.submitQuery")}
        </Button>
      </Box>
      <Box className={classes.container}>
        <Box p={1} className={classes.label}>
          {t("ontology.answer")}:
        </Box>
      </Box>
      <Box className={classes.container}>
        <TextField
          id="answer"
          className={classes.textfield}
          multiline
          rows={10}
          variant="outlined"
          value={answer}
          InputProps={{
            readOnly: true,
          }}
        />
      </Box>
    </div>
  );
}
