/* eslint-disable react/prop-types */
import GaugeChart from "react-gauge-chart";

import React, { useState, useEffect } from "react";

interface Props {
  alarm?: number;
}

export default function Gauge({ alarm }: Props) {
  const [percent, setPercent] = useState(0);

  const calcAlarm = (alarm?: number) => {
    if (!alarm) return;
    switch (alarm) {
      case 0:
        setPercent(0.1);
        break;
      case 1:
        setPercent(0.5);
        break;
      case 2:
        setPercent(0.9);
        break;
      default:
        return 0;
    }
  };

  useEffect(() => {
    calcAlarm(alarm);
  }, [alarm]);

  return (
    <>
      <GaugeChart id="gauge-chart6" animate={false} nrOfLevels={15} percent={percent} needleColor="#345243" />
    </>
  );
}
