import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box } from "@mui/system";
import { IconButton, MenuItem, Select } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ListItem } from "../../../../app/models/listItem";
import PlantviewsList from "../PlantviewsList";
import { Agent } from "../../../../app/api/agent";
import { RelationshipType } from "../../../../app/models/relationship";
import ItemList from "./ItemList";
import AssetContext from "../../../../context/asset-context";
import {
  AddViewRelationshipRequest,
  PlantviewTypes,
  ViewRelationship,
} from "../../../../app/models/plantview";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

export default function PlantviewBody() {
  const { t } = useTranslation();
  const assetContext = useContext(AssetContext);
  const { viewType } = assetContext;
  const [plantview, setPlantView] = useState(viewType ?? "MECHANICAL");
  const [relationships, setRelationships] = useState<RelationshipType[]>([]);
  const [filtered, setFiltered] = useState<RelationshipType[]>([]);
  const [selectedRel, setSelectedRel] = useState(-1);
  const [plantTypes, setPlantTypes] = useState<ViewRelationship[]>([]);

  const loadPlantRelationships = useCallback(
    async (id: PlantviewTypes) => {
      const list = await Agent.Plantviews.relationships(id);
      if (list) {
        setPlantTypes(list);

        if (relationships?.length > 0) {
          const temp = list.map((x) => x.name);
          const filter = relationships?.filter((a) => !temp.includes(a.name));
          setFiltered(filter);
        }
      }
    },
    [relationships]
  );

  useEffect(() => {
    (async () => {
      const list = await Agent.Relationships.list();
      if (list) {
        setRelationships(list);
      }
      loadPlantRelationships(plantview);
    })();
  }, [loadPlantRelationships, plantview]);

  useEffect(() => {
    if (relationships?.length > 0) {
      const temp = plantTypes.map((x) => x.name);
      const filter = relationships?.filter((a) => !temp.includes(a.name));
      setFiltered(filter);
    }
  }, [plantTypes, relationships]);

  const handleChange = (event: {
    preventDefault: () => void;
    stopPropagation: () => void;
    target: { value: any };
  }) => {
    event.preventDefault();
    event.stopPropagation();
    const id = event.target.value;
    setSelectedRel(id);
  };

  const handlePlanttypeChange = (id: PlantviewTypes) => {
    setPlantView(id);
    loadPlantRelationships(id);
  };

  const onAddItem = async () => {
    const payload = {
      viewId: `${plantview}`,
      relationshipId: selectedRel,
    };

    if (payload) {
      await Agent.Plantviews.addRelationship(payload as AddViewRelationshipRequest).then(() => {
        loadPlantRelationships(plantview);
      });
    }
  };

  const handleDeleteItem = (id: number) => {
    const list = plantTypes?.filter((x) => x.id !== id);
    setPlantTypes(list);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        <Box component="div" sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.plantview.plantview.label")}:
        </Box>
        <PlantviewsList
          width="60%"
          hideLabel={true}
          selectedValue={plantview}
          onSelectedItem={(id) => {
            handlePlanttypeChange(id);
          }}
        />
      </Box>
      <Box component="div" sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Box component="div" sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.plantview.relationship.label")}:
        </Box>
        <Select
          style={{ width: "50%" }}
          sx={Style.selectInput}
          size="small"
          id="Relationship"
          IconComponent={KeyboardArrowDown}
          onChange={handleChange}
          value={selectedRel}
        >
          {filtered.map((item: ListItem) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
        <IconButton
          sx={Style.iconTextButton}
          aria-label="add"
          onClick={onAddItem}
          disabled={selectedRel === -1}
        >
          <AddCircleOutlineIcon />
        </IconButton>
      </Box>
      <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
        <ItemList items={plantTypes} onDeleteItem={handleDeleteItem} />
      </Box>
    </div>
  );
}
