import { format } from "date-fns";
import gql from "graphql-tag";
import { DateFields } from "../components/DateUtils/dates.interface";
import { request } from "../app/api/serviceBase";

import type {
  DecJobsQuery,
  DecJobsQueryVariables,
  DecReportQuery,
  DecReportQueryVariables,
} from "../app/models/graphql-types";

export const DigitalEquipmentCheck = {
  async jobs(partId: string, dates?: DateFields) {
    const start = dates && dates.startDate ? dates.startDate : new Date(2018, 1, 1);
    let end = dates && dates.endDate ? dates.endDate : new Date();

    const query = gql`
      query DecJobs($partId: ID!, $startDate: Date, $endDate: Date) {
        equipmentModule(id: $partId) {
          dec(startDate: $startDate, endDate: $endDate) {
            id
            created
            updated
            title
            priority
            caseId
            overdue
            status
            due {
              earliest
              latest
            }
            responsibility {
              type
              id
              name
            }
            finished
            workflow {
              id
              title
            }
          }
        }
      }
    `;

    const response = await request.execute<DecJobsQuery, DecJobsQueryVariables>(query, {
      partId,
      startDate: format(start, "yyyy-MM-dd"),
      endDate: format(end, "yyyy-MM-dd"),
    });

    return response?.equipmentModule?.dec ?? [];
  },

  async report(jobId: string) {
    const query = gql`
      query DecReport($id: ID!) {
        dec {
          report(id: $id) {
            id
            status
            jobId
            caseId
            jobTitle
            userId
            userFullName
            started
            updated
            finished
            received
            content {
              uuid
              type
              label
              value
            }
          }
        }
      }
    `;

    const response = await request.execute<DecReportQuery, DecReportQueryVariables>(query, { id: jobId });

    return response?.dec?.report;
  },
};
