import { Colors } from "./DefaultTheme";

export const Style = {
  Autocomplete: {
    backgroundColor: Colors.lightBackground,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        borderBottom: `1px solid black`,
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
      },

      "&:hover fieldset": {
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottom: "2px solid black",
      },
      "&.Mui-focused fieldset": {
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottom: `2px solid ${Colors.interactivePrimary}`,
      },
    },
  },

  inputTextfield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: 0,
        borderBottom: `1px solid black`,
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
      },

      "&:hover fieldset": {
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottom: "2px solid black",
      },
      "&.Mui-focused fieldset": {
        borderTopColor: "transparent",
        borderLeftColor: "transparent",
        borderRightColor: "transparent",
        borderBottom: `2px solid ${Colors.interactivePrimary}`,
      },
    },
    "& .MuiInputBase-root": { backgroundColor: Colors.lightBackground },
  },
  Textfield: {
    "& .MuiInputLabel-root": { fontSize: 20 },
    "& .MuiInputBase-root": { backgroundColor: Colors.lightBackground },
  },
  Textfield2: {
    "& .MuiInputLabel-root": { fontSize: 20 },
    "& .MuiInputBase-root": {},
  },

  Datagrid: {
    boxShadow: 2,
    border: 0,
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: Colors.pageBackgroundTertiary,
      color: Colors.black,
    },
    "& .MuiDataGrid-row": {
      backgroundColor: Colors.pageBackground,
    },
    "& .MuiDataGrid-footerContainer": {
      backgroundColor: Colors.pageBackground,
    },
    "& .MuiDataGrid-main": {
      backgroundColor: Colors.pageBackground,
    },
    "& .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
    "& .MuiDataGrid-sortIcon": {
      // color: 'white',
    },
  },

  Tablist: {
    "&.MuiTab-root": {
      fontWeight: "bold",
      textTransform: "none",
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: Colors.pageBackgroundTertiary,
      "&.MuiTab-textColorPrimary": {
        color: Colors.textTertiary,
      },
      "&.Mui-selected": {
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: Colors.pageBackgroundSecondary,
        "&.MuiTab-textColorPrimary": {
          color: Colors.textPrimary,
        },
      },
    },
  },
  Tablist2: {
    "&.MuiTab-root": {
      borderBottom: `1px solid ${Colors.mediumContrastBorder}`,
      fontWeight: "bold",
      textTransform: "none",
      paddingLeft: 5,
      paddingRight: 5,
      backgroundColor: Colors.pageBackgroundSecondary,
      "&.MuiTab-textColorPrimary": {
        color: Colors.textTertiary,
      },
      "&.Mui-selected": {
        borderBottom: "0px",
        borderTop: `1px solid ${Colors.mediumContrastBorder}`,
        borderLeft: `1px solid ${Colors.mediumContrastBorder}`,
        borderRight: `1px solid ${Colors.mediumContrastBorder}`,
        fontWeight: "bold",
        textTransform: "none",
        backgroundColor: Colors.pageBackgroundSecondary,
        "&.MuiTab-textColorPrimary": {
          color: Colors.textPrimary,
        },
      },
    },
  },
  Tabpanel: {
    padding: 0,
    backgroundColor: Colors.pageBackgroundSecondary,
    marginLeft: "24px",
    marginRight: "24px",
    paddingTop: "25px",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  Tabpanel2: {
    padding: "5px",
    backgroundColor: Colors.pageBackgroundSecondary,
  },
  loading: {
    width: "100%",
    "& > * + *": {},
  },
  appBar: {
    backgroundColor: "#333333",
    height: 64,
  },
  languageSelector: {
    color: Colors.textInteractiveElement,
    "& .MuiSvgIcon-root": {
      color: Colors.textInteractiveElement,
    },
    "& fieldset": {
      border: 0,
    },
  },
  drawerHeader: {
    width: 240,
    backgroundColor: Colors.pageBackgroundSecondary,
    height: 64,
  },
  drawer: {
    width: 240,
    "& .MuiDrawer-paper": {
      width: 240,
    },
  },
  drawerListItem: {
    height: 50,
  },
  selectInput: {
    backgroundColor: Colors.lightBackground,
    "& fieldset": {
      borderRadius: 0,
      borderBottom: `1px solid black`,
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
    "&:hover fieldset": {
      borderTop: 0,
      borderLef: 0,
      borderRigh: 0,
      borderBottom: "2px solid black",
    },
  },
  actionButton: {
    textTransform: "none",
    fontWeight: "normal",
    color: Colors.link,
  },
  darkButton: {
    textTransform: "none",
    borderRadius: 0,
    backgroundColor: Colors.interactiveSecondary,
    color: Colors.textInteractiveElement,
    fontWeight: "normal",
    "&:hover": {
      backgroundColor: Colors.buttonHover,
    },
  },
  textButton: {
    textTransform: "none",
    borderRadius: 0,
    backgroundColor: "transparent",
    color: Colors.link,
    fontWeight: "normal",
  },
  iconTextButton: {
    backgroundColor: "transparent",
    color: Colors.link,
    fontWeight: "normal",
  },
  linkButton: {
    display: "inline-block",
    padding: "2px",
    minHeight: 0,
    minWidth: 0,
    fontSize: 17,
    border: "1px solid grey",
    borderRadius: 2,
  },
  buttonProgress: {
    color: Colors.interactivePrimary,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  eDoc: {
    cardImage: {
      alignSelf: "center",
      height: "100%",
      width: "100%",
      backgroundColor: "white",
    },
    card: {
      maxWidth: "60%",
      marginBottom: "20px",
      "&.MuiCard-root": {
        backgroundColor: Colors.pageBackground,
      },
    },
    modal: {
      position: "absolute" as any,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "80%",
      height: "80%",
      backgroundColor: Colors.textPrimary,
    },
    modalImage: {
      alignSelf: "center",
      height: "100%",
      width: "100%",
      backgroundColor: Colors.pageBackgroundSecondary,
    },
  },
  report: {
    container: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      border: "2px solid grey",
      backgroundColor: "white",
      maxWidth: "700px",
    },
    item: {
      padding: "5px",
      width: "100%",
    },
    headLine: {
      fontSize: "19px",
    },
    headLineValue: {
      fontSize: "19px",
      wordWrap: "break-word",
    },
    infoHeadLine: {
      fontSize: "17px",
    },
    infoHeadLineValue: {
      fontSize: "17px",
      wordWrap: "break-word",
    },
    textarea: {
      fontFamily: "Roboto",
      backgroundColor: "transparent",
      width: "100%",
      height: "350px",
      resize: "none",
      fontSize: "17px",
      marginTop: "-10px",
    },
  },
};
