import { makeAutoObservable } from "mobx";
import { eDocLoader } from "../components/eDocLoader";

export default class CasterSetupStore {
  eDocuments = [] as any;

  constructor() {
    makeAutoObservable(this);
  }

  load_eDocs = async (partId: string) => {
    await eDocLoader(partId).then((res) => {
      this.eDocuments = res;
    });
  };
}
