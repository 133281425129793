import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { HiStatusOnline } from "react-icons/hi";
import { useTranslation } from "react-i18next";

function getRandomArbitrary(min: any, max: any) {
  return Math.floor(Math.random() * (max - min) + min);
}

export default function SlabThickness() {
  const { t } = useTranslation();
  const [currentTemp, setTemp] = useState(250);
  const [min, setMin] = useState(250);
  const [max, setMax] = useState(250);
  const active = false;
  useEffect(() => {
    const interval = setInterval(() => {
      let newvalue = currentTemp + getRandomArbitrary(-1, 1);

      if (newvalue > max) {
        setMax(Math.round(newvalue * 100) / 100);
      } else if (newvalue < min) {
        setMin(Math.round(newvalue * 100) / 100);
      }
      setTemp(newvalue);
    }, 2000);
    return () => clearInterval(interval);
  }, [max, min, currentTemp]);
  if (active) {
    return (
      <div
        style={{
          height: 120,
          width: 200,
          backgroundColor: "#F2F2F2",
          border: "1px solid #E0E0E0",
          borderRadius: 5,
          cursor: "pointer",
        }}
      >
        <Grid
          container
          item
          style={{ marginLeft: 10, marginTop: 10, width: "auto" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <HiStatusOnline style={{ height: 20, width: 20, color: "green" }} />
          <Typography variant="h5" style={{ marginLeft: 5 }}>
            {t("castersetup.maintenance.slabThickness")}
          </Typography>
        </Grid>
        <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }}>
          {(currentTemp * 100) / 100} mm
        </Typography>
        <Grid
          container
          item
          style={{ marginLeft: 10, width: "auto" }}
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
        >
          <Typography variant="caption">
            {t("castersetup.maintenance.min")}: {min} mm
          </Typography>
          <Typography variant="caption" style={{ marginLeft: 5 }}>
            {t("castersetup.maintenance.max")}: {max} mm
          </Typography>
        </Grid>
      </div>
    );
  } else {
    return (
      <div
        style={{
          height: 120,
          width: 220,
          backgroundColor: "#F2F2F2",
          border: "1px solid #E0E0E0",
          borderRadius: 5,
          cursor: "pointer",
        }}
      >
        <div style={{ opacity: 0.4 }}>
          <Grid
            container
            item
            style={{ marginLeft: 10, marginTop: 10, width: "auto" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <HiStatusOnline style={{ height: 20, width: 20, color: "grey" }} />
            <Typography variant="h5" style={{ marginLeft: 5 }}>
              {t("castersetup.maintenance.slabThickness")}
            </Typography>
          </Grid>
          <Typography variant="h6" style={{ marginLeft: 10, marginTop: 5 }}>
            NaN
          </Typography>
          <Grid
            container
            item
            style={{ marginLeft: 10, width: "auto" }}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Typography variant="caption">{t("castersetup.maintenance.min")}: NaN</Typography>
            <Typography variant="caption" style={{ marginLeft: 5 }}>
              {t("castersetup.maintenance.max")}: NaN
            </Typography>
          </Grid>
        </div>
        <Typography variant="caption" style={{ marginLeft: 10, color: "red" }}>
          {t("castersetup.maintenance.connectionError")}
        </Typography>
      </div>
    );
  }
}
