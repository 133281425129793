import React, { useContext, useState } from "react";
//Material-UI
import AssetContext from "../../../../context/asset-context";
import { ListItem } from "../../../../app/models/listItem";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, TextField, Select } from "@mui/material";
import { Style } from "../../../../themes/Style";
import { System } from "../../../../app/models/system";

interface Props {
  onDescChanged: (description: string) => void;
  onIdChanged: (id: string) => void;
  onSystemChanged: (id: string) => void;
}

export default function SystemSection({ onDescChanged, onIdChanged, onSystemChanged }: Props) {
  const { t } = useTranslation();
  const assetContext = useContext(AssetContext);
  const { externalSystems } = assetContext;
  const [id, setId] = useState("");
  const [description, setDescription] = useState("");
  const [selectedSystem, setSelectedSystem] = useState("");

  const handleDescription = (text: string) => {
    setDescription(text);
    onDescChanged(text);
  };

  const handleId = (id: string) => {
    setId(id);
    onIdChanged(id);
  };

  const handleSystemSelect = (id: string) => {
    setSelectedSystem(id);
    onSystemChanged(id);
  };

  return (
    <>
      <Box component={"div"}>
        <Box component={"div"} p={1}>
          {t("page.addproperty.system.label")}
        </Box>
        <Select
          style={{ width: 350 }}
          sx={Style.selectInput}
          size="small"
          id="systems"
          onChange={(e) => handleSystemSelect(e.target.value as string)}
          value={selectedSystem}
        >
          {externalSystems.map((item: { id: string; name: string; description?: string }) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box component={"div"}>
        <Box component={"div"} p={1}>
          {t("page.addproperty.identifier.label")}
        </Box>
        <TextField
          id="systemId"
          size="small"
          style={{ width: 350 }}
          sx={Style.inputTextfield}
          onChange={(e) => {
            handleId(e.target.value as string);
          }}
          value={id}
        />
      </Box>
      <Box component={"div"}>
        <Box component={"div"} p={1}>
          {t("page.addproperty.description.label")}
        </Box>
        <TextField
          id="systemDesc"
          size="small"
          style={{ width: 350 }}
          sx={Style.inputTextfield}
          onChange={(e) => {
            handleDescription(e.target.value as string);
          }}
          value={description}
        />
      </Box>
    </>
  );
}
