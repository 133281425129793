import { loggedUser } from "./auth";

class PermissionsService {
  getDigitalTwinRoles(): String[] {
    const token = loggedUser();
    return token?.resource_access ? token?.resource_access["digital-twin"]?.roles : [];
  }

  checkApplicationPermission(): boolean {
    const roles = this.getDigitalTwinRoles();
    return roles.includes("dt_user") || roles.includes("dt_admin");
  }

  hasPermission(permission: string): boolean {
    if (this.isAdmin()) return true;
    const roles = this.getDigitalTwinRoles();
    return roles.includes(permission);
  }

  isUserInRole(role: string) {
    if (this.isAdmin()) return true;
    const roles = this.getDigitalTwinRoles();
    return roles.includes(role);
  }

  isAdmin() {
    const dt_roles = this.getDigitalTwinRoles();
    return dt_roles?.includes("dt_admin");
  }
}

const permission = new PermissionsService();

export default permission;
