import React from "react";
import { Chart as ChartJS, registerables } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

// TODO: trim this down to the actual requirements ...
ChartJS.register(...registerables);

function monthDiff(d1: any, d2: any) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months;
}
interface Props {
  data: any;
}
export default function JobChart({ data }: Props) {
  const today = new Date();
  const { t } = useTranslation();
  const months = [
    t("months.jan"),
    t("months.feb"),
    t("months.mar"),
    t("months.apr"),
    t("months.mai"),
    t("months.jun"),
    t("months.jul"),
    t("months.aug"),
    t("months.sep"),
    t("months.oct"),
    t("months.nov"),
    t("months.dec"),
  ];
  //const today = new Date('February 17, 2022 03:24:00');
  const labels = ["", "", "", "", "", ""];
  let negative = 0;
  for (let i = 1; i < 7; i++) {
    if (today.getMonth() + 1 - i < 0) {
      labels[6 - i] = `${months[11 - negative]} ${(today.getFullYear() - 1).toString().substr(2, 2)}`;
      negative++;
    } else {
      labels[6 - i] = months[today.getMonth() + 1 - i];
    }
  }
  let chartData = [0, 0, 0, 0, 0, 0];
  if (data) {
    data.forEach((element: any) => {
      if (
        monthDiff(today, new Date(element.created)) <= 0 &&
        monthDiff(today, new Date(element.created)) >= -5
      ) {
        chartData[5 + monthDiff(today, new Date(element.created))]++;
      }
    });
  }
  const options = {
    responsive: true,
    legend: {
      display: false,
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        gridLines: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          beginAtZero: true,
          max: Math.max(...chartData) + 1,
          maxTicksLimit: Math.max(...chartData) + 2,
        },
      },
      x: {
        gridLines: {
          display: false,
        },
      },
    },
  };
  const data2 = {
    labels,
    datasets: [
      {
        label: "Jobs",
        data: chartData,
        backgroundColor: "rgba(0, 50, 200, 0.5)",
      },
    ],
  };
  return <Bar options={options as any /* TODO: this needs review */} data={data2} />;
}
