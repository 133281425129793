import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as CriptoJs from "crypto-js";
import { EDoc } from "../app/models/eDocs";

const baseUrl = "/eDoc";

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const apiRigths = "read";
const apiKey = "smsgroup-read";

const transformToEdoc = (catalog: any, axiosResult: any) => {
  const resultSet = axiosResult[catalog[0]];

  const edocList: EDoc[] = [];
  Object.keys(resultSet[0]).forEach((element: any) => {
    edocList.push(resultSet[0][element]);
  });

  return edocList.filter((edoc) => edoc.media_vector && edoc.media_vector !== "");
};

const request = {
  post: <T>(url: string, body: {}, config: AxiosRequestConfig) =>
    axios.post<T>(url, body, config).then(responseBody),
};

export const EDocs = {
  getCatalogAttributeValues: async (body: any) => {
    const axiosResult = await request.post<any>(
      `${baseUrl}/api.php/c/data/getCatalogAttributeValues`,
      body,
      getConfigs("api.php/c/data/getCatalogAttributeValues", JSON.stringify(body))
    );
    const result = await transformToEdoc(body.catalogs, axiosResult);
    return result;
  },
  checkFilesExist: (body: { file: string }) =>
    request.post<any>(
      `${baseUrl}/api.php/c/data/checkFilesExist`,
      body,
      getConfigs("api.php/c/data/checkFilesExist", JSON.stringify(body))
    ),
  getFile: (body: { file: string }) =>
    request.post<any>(
      `${baseUrl}/api.php/c/media/getFile`,
      body,
      getConfigs("api.php/c/media/getFile", JSON.stringify(body))
    ),
};

const getConfigs = (requestedUrl: string, body: string): AxiosRequestConfig => {
  const aPath = requestedUrl.split("/");
  let oDate = new Date(),
    sTs = oDate.getTime().toString(),
    sKey = apiKey,
    sApi = apiRigths;

  sTs = sTs.substr(0, sTs.length - 3); //Milliseconds

  let sSig = [aPath[3], sTs, body, aPath[2], sApi].join(sKey);
  sSig = CriptoJs.SHA512(sSig).toString();

  const config: AxiosRequestConfig = {
    headers: {
      "Content-Type": "application/json",
      "ExportDynamic-TS": sTs,
      "ExportDynamic-SIG": sSig,
      "ExportDynamic-API": apiRigths,
    },
  };

  return config;
};
