/* eslint-disable react/display-name */
import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { maintenanceHistoryResource } from "../../../../../services/suspenders";
import { DECJobs } from "../../../../../app/models/decJobs";
import { Agent } from "../../../../../app/api/agent";
import { DatePicker } from "../../../../../components/DateUtils";
import { DateFields } from "../../../../../components/DateUtils/dates.interface";
import { Modal, Alert, AlertTitle, Button } from "@mui/material";
import JobReport from "./JobReport";
import { useTranslation } from "react-i18next";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import { Style } from "../../../../../themes/Style";

interface Props {
  partId: string;
  jobData: any;
}

export default function PartHistoryTable({ partId, jobData }: Props) {
  const { t } = useTranslation();
  const [reportData, set_reportData] = useState<any>(undefined);
  const [open, setOpen] = useState<any>(false);
  const [openAlert, setOpenAlert] = useState(false);
  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };

  const startDates: DateFields = {
    startDate: new Date(2018, 1, 1),
    endDate: new Date(),
  };

  const [dates, setDates] = useState(startDates);
  const handleDatesChanged = (dates: DateFields) => {
    setDates(dates);
  };

  let decObject = maintenanceHistoryResource.read(partId);
  const [data, setData] = useState<DECJobs[]>(decObject);

  const loadData = useCallback(async () => {
    let decObject = await Agent.DigitalEquipmentCheck.jobs(partId, dates);
    setData(decObject);
    // eslint-disable-next-line
  }, [dates]);

  const loadData2 = useCallback(async () => {
    await Agent.DigitalEquipmentCheck.jobs(partId, startDates).then((response) => {
      setData(response);
      jobData(response);
    });
    // eslint-disable-next-line
  }, [partId]);

  //Todo: This error alert is not for a failing query but for an empty answer of a correct query (can be deleted if API is fixed)
  useEffect(() => {
    if (openAlert) {
      setTimeout(() => {
        setOpenAlert(false);
      }, 3000);
    }
  }, [openAlert]);

  useEffect(() => {
    loadData();
  }, [dates, loadData]);

  useEffect(() => {
    loadData2();
    // eslint-disable-next-line
  }, [partId]);

  const handleClick = (event: any, cellValues: any) => {
    Agent.DigitalEquipmentCheck.report(cellValues["id"])
      .then((report) => {
        if (typeof report === "object") {
          set_reportData(report);
          return true;
        } else {
          setOpenAlert(true);
          return false;
        }
      })
      .then((open: any) => {
        setOpen(open);
      });
  };

  const columns = [
    { field: "id", hide: true },
    { field: "status", headerName: t("grid.label.status"), width: 150 },
    { field: "created", headerName: t("grid.label.created"), width: 150 },
    {
      field: "title",
      headerName: t("grid.label.title"),
      minWidth: 280,
      flex: 1,
    },
    {
      field: "action",
      headerName: t("grid.label.action"),
      sortable: false,

      width: 90,
      disableClickEventBubbling: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Button
              startIcon={<SummarizeOutlinedIcon />}
              color="primary"
              sx={Style.textButton}
              onClick={(event) => {
                handleClick(event, cellValues);
              }}
            >
              {t("grid.action.report")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div style={{ minHeight: 350 }}>
        <DatePicker
          enabled={true}
          start={dates.startDate}
          end={dates.endDate}
          onDatesChanged={handleDatesChanged}
        />
        <DataGrid
          style={{ marginTop: 10 }}
          sx={Style.Datagrid}
          rows={data}
          columns={columns}
          disableColumnMenu={true}
          disableVirtualization={true}
        />
      </div>
      <Modal open={open} onClose={handleClose}>
        <JobReport data={reportData} onClick={handleClose} />
      </Modal>
      <Modal open={openAlert} onClose={handleClose}>
        <Alert
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          severity="error"
        >
          <AlertTitle>Error</AlertTitle>
          Error opening the report!
        </Alert>
      </Modal>
    </>
  );
}
