import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import * as THREE from "three";
import { Canvas, useFrame, extend, ThreeEvent } from "@react-three/fiber";
import {
  // AdaptiveDpr,
  AdaptiveEvents,
  Loader,
  OrbitControls,
  Stage,
  Html,
} from "@react-three/drei";
import { DRACOLoader, GLTFLoader, MeshoptDecoder, OrbitControls as ThreeOrbitControls } from "three-stdlib";
import {
  create3dText,
  getAggregateTransform,
  searchMeshes,
  shrinkwrap,
} from "../../../components/ThreeJSX/threeUtils";
import { Agent } from "../../../app/api/agent";
import { installationTreeResource } from "../../../services/suspenders";
import { Suspender, wrapPromise } from "../../../utils/suspender";
import { EffectComposer, Outline } from "@react-three/postprocessing";
import { UnrealBloomPass } from "three-stdlib";
extend({ UnrealBloomPass });

const alarmicon = process.env.PUBLIC_URL + "/alarmicon.png";
const rotateicon = "url(" + process.env.PUBLIC_URL + "'/rotateIcon.png'), auto";
const panIcon = "url(" + process.env.PUBLIC_URL + "'/panIcon.png'), auto";

var clickTimer: NodeJS.Timeout;
var clickTimerPrev = false;
var ctrlTimer: NodeJS.Timeout;
var ctrlTimerPrev = false;

declare module "three-stdlib" {
  // MeshoptDecoder is not typed in three-stdlib@2.0.3
  function MeshoptDecoder(): any;
}

const MODEL_3D_ROOT = process.env.PUBLIC_URL + "/models";
THREE.Cache.enabled = true;

class GLTFResource {
  // will be superseeded in part by the new scene loader
  _cache: { [url: string]: Suspender<THREE.Object3D> };
  progress: number;
  loader: GLTFLoader;

  constructor(createManager = false) {
    this._cache = {};
    this.progress = 0;

    const manager = createManager ? new THREE.LoadingManager() : THREE.DefaultLoadingManager;
    this.loader = new GLTFLoader(manager);

    // setup compression support for DRACO and MeshOpt
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("https://www.gstatic.com/draco/versioned/decoders/1.4.1/"); // TODO: this pulls decoders from web, should be local
    dracoLoader.setDecoderConfig({ type: "js" });
    dracoLoader.preload();
    this.loader.setDRACOLoader(dracoLoader);
    this.loader.setMeshoptDecoder(MeshoptDecoder);
  }

  preload(modelName: string) {
    let suspender = this._cache[modelName];

    if (!suspender) {
      this.progress = 0;

      const _progressCb = this._progressCb;

      const worker = new Promise((resolve, reject) => {
        this.loader.load(`${MODEL_3D_ROOT}/${modelName}.glb`, resolve, _progressCb, reject);
      })
        .then((gltf: any) => {
          const root = gltf.scene as THREE.Object3D;
          if (root.name === "AuxScene" && root.children.length === 1) return root.children[0];
          else return root;
        })
        .catch((e) => {
          if (e instanceof SyntaxError) {
            // this seems to be the reaction for missing file
            return create3dText(
              `File ${MODEL_3D_ROOT}/${modelName}.glb\n seems unavailable.`
            ) as THREE.Object3D;
          }
          throw e;
        });

      this._cache[modelName] = suspender = wrapPromise(worker);
    }

    return suspender;
  }

  read(modelName: string) {
    const suspender = this.preload(modelName);
    return suspender.read();
  }

  _progressCb(xhr: ProgressEvent) {
    this.progress = (xhr.loaded / xhr.total) * 100;
  }
}

export const gltfResource = new GLTFResource();

export type Part3dProps = JSX.IntrinsicAttributes & {
  doNotHighlightPositions?: boolean;
  onPositionClick?: (positionId: string | null) => void;
  onPositionDClick?: (positionId: string) => void;
  selectedPositionId?: string;
  outlinePos?:
    | {
        position?: string | null | undefined;
        belongs?: string | null | undefined;
        part?: string | null | undefined;
      }
    | null
    | undefined;
  view?: string;
  partId: string | null;
  setAlarm?: any;
  notification?: boolean;
};

export default function Part3d(props: Part3dProps) {
  const {
    doNotHighlightPositions,
    onPositionClick,
    onPositionDClick,
    outlinePos,
    partId,
    setAlarm,
    notification,
  } = props;
  const positionHighlighting = !doNotHighlightPositions;
  // a bunch or ref needed to tame react-three, should go after next refactor
  const controlsRef = useRef<ThreeOrbitControls | null>(null);
  const canvasRef = useRef<any | null>(null);
  const prevOutlinedClickable = useRef<THREE.Object3D | null>(null);
  const [outlineColor, setOutlineColor] = useState("grey");
  const [outlinedMeshes, setOulinedMeshes] = useState<any>(undefined);
  const [doRay, setRay] = useState(true);
  const [cursor, setCursor] = useState("");

  // read the installation tree and find the 3d model name
  const { installableParts, installationPositions } = installationTreeResource.read();
  const part = partId ? installableParts[partId] : installationTreeResource.readDefaultRoot();
  const modelName = part?.systems["DIGITALTWIN3D"];

  const partHealth: { [id: string]: "green" | "orangered" | "red" } = useMemo(() => ({}), []);

  const getAlarmStatus = useCallback(async () => {
    const colors: ["green", "orangered", "red"] = ["green", "orangered", "red"];
    const status = await Agent.GeniusCMService.getAlarmStatus();

    if (status) {
      Object.values(installableParts).forEach((p) => {
        const geniusCmId = p.systems["GENIUSCM"];
        if (geniusCmId && status[geniusCmId] !== undefined) {
          partHealth[p.id] = colors[status[geniusCmId]];
        }
      });
      Object.values(installationPositions).forEach((p) => {
        const geniusCmId = p.systems["GENIUSCM"];
        if (geniusCmId && status[geniusCmId] !== undefined) {
          partHealth[p.id] = colors[status[geniusCmId]];
        }
      });
    }
  }, [partHealth, installableParts, installationPositions]);

  useEffect(() => {
    getAlarmStatus(); // update once asap
    const intervalID = setInterval(getAlarmStatus, 300000); //  poll every 5 minutes

    return () => clearInterval(intervalID);
  }, [getAlarmStatus]);

  // load 3d model or create a fallback text
  let _model3d: THREE.Object3D;

  if (modelName) {
    if (gltfResource.read(modelName).getObjectByName("Model")) {
      _model3d = gltfResource.read(modelName);
    } else {
      _model3d = create3dText("No Object called 'Model'\n inside .glb file");
      _model3d.name = "Warning";
    }
  } else if (part) {
    _model3d = create3dText("3d model not defined\n in Asset Dictionary");
  } else if (partId) {
    _model3d = create3dText(`Part '${partId}' is not\n in Asset Dictionary`);
  } else {
    _model3d = create3dText(`No part id provided and no default\n root in Asset Dictionary`);
  }

  // do not get confused by the linter, adding model3d in the dependencies will cause desaster
  const model3d = useMemo(() => _model3d.clone(), [modelName, partId]); // clone model as different Part3d must not share model

  const model3d2 = model3d.name !== "Warning" ? model3d.getObjectByName("Model") : model3d;

  // create react-three components for click handling and highlighting
  const clickables = useMemo(() => {
    const clickables: JSX.Element[] = [];
    if (modelName && (positionHighlighting || onPositionClick)) {
      // no use to create mapping if not going to be used

      const outlineMaterial = new THREE.MeshPhongMaterial({
        color: "#149414",
        opacity: 0.0,
        transparent: true,
      });

      model3d.updateWorldMatrix(true, true);

      model3d.children.forEach((child) => {
        if (child.type === "Mesh") {
          const installPos = part.installationPostions.find((element) =>
            element.systems["DIGITALTWIN3D"].split(".").includes(child.name)
          );
          const positionId = installPos ? installPos.id : child.uuid;
          const positionName = installPos ? installPos.name : child.name;
          const installedPartName = installPos?.currentlyInstalled?.name;
          const installedPartId = installPos?.currentlyInstalled?.id;

          const obj = child;
          if (obj) {
            const meshes = searchMeshes(obj);
            const clickableRef = React.createRef<THREE.Object3D>();

            let geom, transform;

            if (meshes.length === 1) {
              geom = meshes[0].geometry;
              transform = getAggregateTransform(model3d, meshes[0], true);
            } else {
              // TODO: currently alpha-shape seems to choke on larger parts (hence keep it off, alpha: -1)
              //       and SimplifyModifier cannot handle degenerate meshes (which we have plenty after
              //       quantizing and is super slow in the debugger.)
              const boundingMesh = shrinkwrap(obj, {
                material: outlineMaterial,
                alpha: -1,
                simplify: 0,
              });
              geom = boundingMesh.geometry;
              transform = getAggregateTransform(model3d, obj, false);
            }

            const pointerOverHandler = positionHighlighting
              ? (event: ThreeEvent<PointerEvent>) => {
                  event.stopPropagation();
                  const status = partHealth[positionId!] || partHealth[installedPartId!] || "deepskyblue";
                  setOulinedMeshes({ current: event.object });
                  setOutlineColor(status);

                  if (clickableRef.current && prevOutlinedClickable.current !== clickableRef.current) {
                    prevOutlinedClickable.current = clickableRef.current;
                  }
                }
              : undefined;

            const pointerOutHandler = positionHighlighting
              ? (event: ThreeEvent<PointerEvent>) => {
                  event.stopPropagation();
                  setOulinedMeshes(undefined);

                  if (!event.intersections.length) {
                    prevOutlinedClickable.current = null;
                  }
                }
              : undefined;

            // TODO: decomposing `transform` might fail, but react-three does seem
            //       to ignore the matrix if provided directly
            const position = new THREE.Vector3();
            const quaternion = new THREE.Quaternion();
            const scale = new THREE.Vector3();
            var alarmlabel = null;
            if (positionId === "c2df0f41-9378-4465-89e3-bb29b101ea3f") {
              alarmlabel = notification ? (
                <Html
                  as="div"
                  zIndexRange={[1, 0]}
                  key={positionId}
                  transform
                  sprite
                  position={new THREE.Vector3(0, 0, 0)}
                >
                  <div style={{ userSelect: "none", zIndex: 10 }}>
                    <img
                      src={alarmicon}
                      alt={""}
                      width="60"
                      height="60"
                      onClick={() => {
                        setAlarm({
                          open: true,
                          id: installedPartId,
                          name: installedPartName,
                          position: positionName,
                        });
                      }}
                    />
                  </div>
                </Html>
              ) : null;
            }

            transform.decompose(position, quaternion, scale);
            //TODO: The clickable objects for rollers are in every Model but they are disabled till they're needed
            if (!child.name.includes("ROLLER")) {
              clickables.push(
                <mesh
                  key={`${part.id}_${positionId}`}
                  ref={clickableRef}
                  geometry={geom}
                  position={position}
                  quaternion={quaternion}
                  scale={scale}
                  material={
                    outlinePos?.position === positionId
                      ? new THREE.MeshPhongMaterial({
                          color:
                            partHealth[outlinePos.position!] || partHealth[outlinePos.part!] || "deepskyblue",
                          opacity: 0.5,
                          depthTest: false,

                          transparent: true,
                        })
                      : new THREE.MeshPhongMaterial({
                          color: "#149414",
                          opacity: 0,
                          transparent: true,
                        })
                  }
                  visible={true}
                  onClick={(e) => {
                    e.stopPropagation();
                    clickTimerPrev = false;
                    clickTimer = setTimeout(function () {
                      if (!clickTimerPrev && onPositionClick) onPositionClick(positionId);
                    }, 200);
                  }}
                  onDoubleClick={(e) => {
                    e.stopPropagation();
                    if (onPositionDClick) {
                      clickTimerPrev = true;
                      clearTimeout(clickTimer);
                      onPositionDClick(positionId);
                    }
                  }}
                  onPointerOver={pointerOverHandler}
                  onPointerOut={pointerOutHandler}
                >
                  {alarmlabel}
                </mesh>
              );
            }
          }
        }
      });
    }
    return clickables;
  }, [part, modelName, model3d, positionHighlighting, onPositionClick, partHealth, setOulinedMeshes]);

  function CheckControls() {
    if (controlsRef.current) {
      //check if a mouse button was pressed on the canvas
      canvasRef.current.addEventListener("mousedown", (e: any) => {
        e.stopPropagation();
        ctrlTimerPrev = false;
        ctrlTimer = setTimeout(function () {
          if (!ctrlTimerPrev) {
            setRay(false);
            clickTimerPrev = true;
          }
          if (e.button === 0) {
            setCursor(rotateicon);
          } else if (e.button === 2) {
            setCursor(panIcon);
          }
        }, 300);
      });

      //check if a mouse button was released on the canvas
      canvasRef.current.addEventListener("mouseup", (e: any) => {
        e.stopPropagation();
        setCursor("");
        setRay(true);
        clearTimeout(ctrlTimer);
        ctrlTimerPrev = true;
      });

      //check if the cursor enters the canvas
      canvasRef.current.addEventListener("mouseenter", (e: any) => {
        if (e.buttons === 0) {
          setCursor("");
        }
      });
    }
  }

  //Function to disable Raycast
  const ControlRaycast = () => {
    useFrame((state) => {
      if (!doRay) {
        state.raycaster.layers.disableAll(); //disable Raycast
      } else if (doRay) {
        state.raycaster.layers.enableAll(); //enable Raycast
      }
    });
    return null;
  };

  return (
    <>
      <Canvas
        mode="concurrent"
        frameloop="demand"
        shadows={false}
        className="scene"
        onPointerMissed={(e) => {
          if (e.button === 0 && onPositionClick) {
            onPositionClick(null);
          }
        }}
        key={partId}
        ref={canvasRef}
        onCreated={CheckControls}
        style={{ background: "transparent", cursor: cursor }}
        dpr={window.devicePixelRatio}
      >
        <AdaptiveEvents />
        <OrbitControls
          ref={controlsRef}
          enablePan
          dampingFactor={1}
          mouseButtons={{
            LEFT: THREE.MOUSE.ROTATE,
            MIDDLE: null as unknown as any,
            RIGHT: THREE.MOUSE.PAN,
          }}
          addEventListener={undefined}
          hasEventListener={undefined}
          removeEventListener={undefined}
          dispatchEvent={undefined}
        />

        <Stage
          shadows={false}
          intensity={0.4}
          controls={
            controlsRef as React.MutableRefObject<{
              update(): void;
              target: THREE.Vector3;
            }>
          }
          environment={"" as any}
          contactShadow={false}
        >
          <primitive object={model3d2} />
          {clickables}
          <EffectComposer multisampling={8} autoClear={false} renderPriority={1}>
            <Outline
              blur
              selection={outlinedMeshes}
              visibleEdgeColor={outlineColor as unknown as number}
              hiddenEdgeColor={outlineColor as unknown as number}
              edgeStrength={10}
            />
          </EffectComposer>
          <ControlRaycast />
        </Stage>
      </Canvas>
      <Loader
        /*   containerStyles={{
             height: "89vh",
             width: "98%",
             borderRadius: 4,
             position: "absolute",
            // left: "10/3%",

             marginTop: 75,
           }}*/
        dataStyles={{ fontSize: 20 }} // Text styles
        dataInterpolation={(p) => `Loading ${p.toFixed(2)}%`} // Text
        initialState={(active) => active} // Initial black out state
      />
    </>
  );
}
