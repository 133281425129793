import React from "react";
import { ListItem, List, ListItemSecondaryAction, ListItemText, IconButton, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ViewRelationship } from "../../../../app/models/plantview";
import { Agent } from "../../../../app/api/agent";
import { enPermission } from "../../../../app/models/permissions";
import PermissionsService from "../../../../services/permissionsService";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../themes/Style";

interface Props {
  items: ViewRelationship[];
  onDeleteItem: (id: number) => void;
}

export default function ItemList({ items, onDeleteItem }: Props) {
  const { t } = useTranslation();
  const onRemoveItem = async (id: number) => {
    await Agent.Plantviews.deleteRelationship(id).then(() => {
      onDeleteItem(id);
    });
  };

  return (
    <div>
      <Typography variant="h6" sx={{ marginTop: "15px" }}>
        {t("page.plantview.list.label")}
      </Typography>
      <div>
        <List dense={true}>
          {items.map((item) => (
            <ListItem key={item.id}>
              <ListItemText primary={item.name} />
              <ListItemText
                primary={item.filter}
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginRight: "310px",
                }}
              />
              <ListItemSecondaryAction>
                {PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_WRITE) && (
                  <IconButton
                    sx={Style.iconTextButton}
                    key={item.id}
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      onRemoveItem(item.id);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </div>
    </div>
  );
}
