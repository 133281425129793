/* eslint-disable no-unused-vars */
import React, { useState, useContext } from "react";
import Draggable from "react-draggable";
import DataService from "../../../services/dataService";
import AssetContext from "../../../context/asset-context";
import { useTranslation } from "react-i18next";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  Checkbox,
  FormControlLabel,
  CircularProgress,
} from "@mui/material";
import useApiMessage from "../../../common/hooks/useAPIMessage";
import { Style } from "../../../themes/Style";

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export default function FileImportDialog() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [selectedFile, setSelectedFile] = useState("");
  const [overrideData, setOverrideData] = useState(false);
  const assetContext = useContext(AssetContext);
  const { clearSelectedPart, refreshTree } = assetContext;
  const { addError } = useApiMessage();

  const handleClickOpen = () => {
    setOpen(true);
    setLoading(false);
  };

  const handleClose = () => {
    setLoading(false);
    setSelectedFile(false);
    setOpen(false);
  };

  const uploadFile = async () => {
    if (selectedFile && selectedFile !== "") {
      setLoading(true);
      const data = new FormData();
      data.append("overrideData", overrideData);
      data.append("file", selectedFile[0], selectedFile[0].name);
      const response = await DataService.ImportData(data);
      if (response) {
        addError(response);
      }
      setLoading(false);
      setOpen(false);
      clearSelectedPart();
      refreshTree();
    }
  };

  return (
    <div>
      <Button sx={Style.darkButton} variant="contained" onClick={handleClickOpen}>
        {t("page.import.button")}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          {t("page.import.label")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("page.import.context")}</DialogContentText>
          <DialogContentText>
            <input
              accept="application/JSON"
              style={{ display: "none" }}
              id="raised-button-file"
              onChange={(e) => setSelectedFile(e.target.files)}
              type="file"
            />
            <label htmlFor="raised-button-file">
              <Button sx={Style.textButton}>{t("page.import.button.select")}</Button>
            </label>
            {selectedFile && selectedFile.length > 0 ? selectedFile[0].name : null}
          </DialogContentText>
          <DialogContentText>
            <FormControlLabel
              value="end"
              control={
                <Checkbox
                  color="primary"
                  checked={overrideData}
                  onChange={(e) => setOverrideData(e.target.checked)}
                />
              }
              label={t("page.import.override.label")}
              labelPlacement="end"
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div>
            <Button autoFocus onClick={handleClose} color="primary" disabled={loading} sx={Style.textButton}>
              {t("button.cancel")}
            </Button>
            <Button onClick={uploadFile} sx={Style.textButton} color="primary" disabled={loading}>
              {t("page.import.button.upload")}
            </Button>
            {loading && <CircularProgress size={24} />}
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}
