/* eslint-disable react/display-name */
import React from "react";
import { useHistory } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { changeHistoryResource } from "../../../../../services/suspenders";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { Style } from "../../../../../themes/Style";
import { Button } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  dataGrid: theme.dataGrid,

  button: {
    textTransform: "none",
    fontWeight: "normal",
  },
  gridDiv: {
    height: 400,
    width: "100%",
  },
}));
interface Props {
  positionId: string | null;
}

export default function PositionHistoryTable({ positionId }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const columns = [
    { field: "id", hide: true },
    { field: "date", headerName: t("grid.label.date"), width: 150 },
    {
      field: "partName",
      headerName: t("grid.label.part"),
      minwidth: 300,
      flex: 1,
    },
    /*{ field: "description", headerName: "Installation Position", width: 400 },*/
    {
      field: "action",
      headerName: t("grid.label.action"),
      sortable: false,
      width: 90,
      disableClickEventBubbling: true,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Button
              sx={Style.textButton}
              startIcon={<VisibilityOutlinedIcon />}
              className={classes.button}
              onClick={(event) => {
                history.push(`../caster/${cellValues.id}`);
              }}
            >
              {t("grid.action.show")}
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <DataGrid
      style={{ minHeight: 400 }}
      sx={Style.Datagrid}
      rows={positionId ? changeHistoryResource.preload(positionId).read() : []}
      columns={columns}
      disableColumnMenu={true}
      disableSelectionOnClick
    />
  );
}
