import React, { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { Agent } from "../../../../app/api/agent";
import { useTranslation } from "react-i18next";
import EquipmentBody from "./Body";
import { Part } from "../../../../app/models/part";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Snackbar,
} from "@mui/material";
import { Style } from "../../../../themes/Style";

interface Props {
  iconLaunch: boolean;
}

export interface DialogTitleProps {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function AddEquipment({ iconLaunch }: Props) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [remarks, setRemarks] = useState("");
  const handleSave = async () => {
    //TODO: Validate
    if (type && name) {
      const payload = {
        name: name,
        remarks: remarks,
        nodeType: type,
      };
      await Agent.Parts.create(payload as Part).then(() => {
        setOpenAlert(true);
        //TODO: Refresh equipment
      });
    }
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  function launchButton() {
    if (iconLaunch) {
      return (
        <IconButton aria-label="addprop" onClick={handleClickOpen}>
          <AddIcon />
        </IconButton>
      );
    } else {
      return (
        <Button sx={Style.darkButton} endIcon={<AddIcon />} variant="contained" onClick={handleClickOpen}>
          {t("page.addequipment.button")}
        </Button>
      );
    }
  }

  return (
    <div>
      {launchButton()}
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle id="customized-dialog-title">{t("page.addequipment.label")}</DialogTitle>
        <DialogContent dividers>
          <EquipmentBody
            onNameChange={(e) => setName(e)}
            onSelectType={(e) => setType(e)}
            onRemarksChange={(e) => setRemarks(e)}
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus sx={Style.textButton} onClick={handleSave} color="primary">
            {t("button.save")}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={openAlert}
        onClose={handleCloseAlert}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="success" onClose={handleCloseAlert}>
          {t("page.plantview.alert")}
        </Alert>
      </Snackbar>
    </div>
  );
}
