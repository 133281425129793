import React, { useEffect, useState } from "react";
import { ListItem } from "../../../../app/models/listItem";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useTranslation } from "react-i18next";
import { Agent, NodeTypesEnum } from "../../../../app/api/agent";
import { Box, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import { Style } from "../../../../themes/Style";

interface Props {
  onNameChange: (value: string) => void;
  onSelectType: (id: string) => void;
  onRemarksChange: (value: string) => void;
}

export default function EquipmentBody({ onNameChange, onSelectType, onRemarksChange }: Props) {
  const [name, setName] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectedNodeType, setSelectedNodeType] = useState("");
  const { t } = useTranslation();

  const handleNameChange = (value: string) => {
    setName(value);
    onNameChange(value);
  };

  const handleRemarksChange = (value: string) => {
    setRemarks(value);
    onRemarksChange(value);
  };

  const handleTypeChange = (e: SelectChangeEvent<string>) => {
    e.preventDefault();
    e.stopPropagation();
    const typ = e.target.value;
    if (typ) {
      setSelectedNodeType(typ);
      onSelectType(typ);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center" }}>
        <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.addequipment.part.label")}:
        </Box>
        <TextField
          style={{ width: "60%" }}
          sx={Style.inputTextfield}
          size="small"
          id="Name"
          onChange={(e) => {
            handleNameChange(e.target.value as string);
          }}
          value={name}
        />
      </Box>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.addequipment.type.label")}:
        </Box>
        <Select
          style={{ width: "60%" }}
          sx={Style.selectInput}
          size="small"
          id="Type"
          IconComponent={KeyboardArrowDown}
          onChange={handleTypeChange}
          value={selectedNodeType}
        >
          {Object.keys(NodeTypesEnum).map((nt) => (
            <MenuItem key={nt} value={nt}>
              {nt}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box component={"div"} sx={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <Box component={"div"} p={1} sx={{ flexBasis: "40%", maxWidth: "40%" }}>
          {t("page.addequipment.remarks.label")}:
        </Box>
        <TextField
          style={{ width: "60%" }}
          sx={Style.inputTextfield}
          size="small"
          id="Remarks"
          onChange={(e) => {
            handleRemarksChange(e.target.value as string);
          }}
          value={remarks}
        />
      </Box>
    </div>
  );
}
