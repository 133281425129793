import { Agent } from "../../../app/api/agent";

//Todo: If a working and filterable eDoc Api is available this script can get deleted
export async function eDocLoader(partId: string) {
  const selectedPart = "2110590_MMK";
  const body = {
    catalogs: [selectedPart],
    catalogTypes: [0],
    locale: "en",
    search: {
      articlenumber: ["21031%"],
    },
    attrs: ["articlenumber", "partname", "media_vector", "quantity"],
  };

  const documents: any = await Agent.EDocs.getCatalogAttributeValues(body);
  const filteredDocuments = documents.splice(4, 5);
  for (const element of filteredDocuments) {
    if (element.media_vector) {
      const firstPage = element.media_vector?.split("#")[0];
      if (firstPage) {
        const result = await Agent.EDocs.getFile({ file: firstPage });
        element.image = result.replace(
          `onload="if(typeof(parent.fileLoaded) != 'undefined') parent.register2();"`,
          ``
        );
      }
    }
  }
  return filteredDocuments;
}
