import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Style } from "../../../../../themes/Style";
import { Button } from "@mui/material";

function convert_date(date: any) {
  var new_date =
    date.getUTCDate() +
    "-" +
    (date.getMonth() + 1) +
    "-" +
    date.getUTCFullYear() +
    ", " +
    date.getUTCHours() +
    ":" +
    date.getUTCMinutes() +
    ":" +
    date.getUTCSeconds();
  return new_date;
}

// TODO: We could have made this a React.forwardRef as well
const JobReport = React.memo(function JobReport({ data, onClick }: any) {
  const { t } = useTranslation();
  var job_title = data["jobTitle"];
  var equipment = data["content"][0]["value"][0]["objectDisplayValue"];
  var reporter = data["userFullName"];
  var started = convert_date(new Date(Date.parse(data["started"])));
  var updated = convert_date(new Date(Date.parse(data["updated"])));
  var finished = convert_date(new Date(Date.parse(data["finished"])));
  var received = convert_date(new Date(Date.parse(data["received"])));
  var job_description = data["content"][1]["label"] + ": " + data["content"][1]["value"];

  return (
    <>
      <Grid
        sx={Style.report.container}
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        <h1>{t("castersetup.jobReport.title")}</h1>
        <Grid
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.headLine}>{t("castersetup.jobReport.partName")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.headLineValue as React.CSSProperties}>{`${job_title}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -40 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.equipment")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${equipment}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.reporter")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${reporter}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.started")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${started}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.updated")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${updated}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.finished")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${finished}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={{ marginTop: -30 }}
          sx={Style.report.item}
          item
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Grid item xs={3}>
            <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.received")}:</p>
          </Grid>
          <Grid item xs={9}>
            <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${received}`}</p>
          </Grid>
        </Grid>
        <Grid
          style={Style.report.item}
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={0.1}
        >
          <p style={Style.report.infoHeadLine}>{t("castersetup.jobReport.jobDescription")}:</p>
          <textarea
            style={Style.report.textarea as React.CSSProperties}
            readOnly={true}
            spellCheck="false"
            defaultValue={`${job_description}`}
          />
        </Grid>
        <Grid
          style={{ padding: 5, width: "100%" }}
          item
          container
          direction="column"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Button variant="contained" sx={Style.darkButton} onClick={onClick}>
            {t("button.close")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
});

export default JobReport;
