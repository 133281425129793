import React from "react";
import LinearProgress from "@material-ui/core/LinearProgress";

import { makeStyles } from "@material-ui/core/styles";
import useApiProgressBar from "../../common/hooks/useProgressBar";

const useStyles = makeStyles((theme) => ({
  loading: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(4),
    },
  },
}));

export default function APiProgressBar() {
  const { isLoading } = useApiProgressBar();

  const classes = useStyles();

  return <div className={classes.loading}>{isLoading && <LinearProgress />}</div>;
}
