export const environment = {
  production: false,
  plugins: [],
  API_URL:
    (window as { [key: string]: any })["env"] && (window as { [key: string]: any })["env"]["API_URL"]
      ? (window as { [key: string]: any })["env"]["API_URL"]
      : process.env.REACT_APP_API_URL,
  CUSTOMER_PORTAL:
    (window as { [key: string]: any })["env"] && (window as { [key: string]: any })["env"]["CUSTOMER_PORTAL"]
      ? (window as { [key: string]: any })["env"]["CUSTOMER_PORTAL"]
      : process.env.REACT_APP_CUSTOMER_PORTAL,
  CALLBACK_URL:
    (window as { [key: string]: any })["env"] && (window as { [key: string]: any })["env"]["CALLBACK_URL"]
      ? (window as { [key: string]: any })["env"]["CALLBACK_URL"]
      : process.env.REACT_APP_CALLBACK_URL,
  DIGITAL_TWIN_TOKEN:
    (window as { [key: string]: any })["env"] &&
    (window as { [key: string]: any })["env"]["DIGITAL_TWIN_TOKEN"]
      ? (window as { [key: string]: any })["env"]["DIGITAL_TWIN_TOKEN"]
      : process.env.REACT_APP_DIGITAL_TWIN_TOKEN,
  ASSET_DICTIONARY_API:
    (window as { [key: string]: any })["env"] &&
    (window as { [key: string]: any })["env"]["ASSET_DICTIONARY_API"]
      ? (window as { [key: string]: any })["env"]["ASSET_DICTIONARY_API"]
      : process.env.REACT_APP_ASSET_DICTIONARY_API,
  ONTOLOGY_API:
    (window as { [key: string]: any })["env"] && (window as { [key: string]: any })["env"]["ONTOLOGY_API"]
      ? (window as { [key: string]: any })["env"]["ONTOLOGY_API"]
      : process.env.REACT_APP_ONTOLOGY_API,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
