import React, { useContext } from "react";

//Material-UI
import { Box, Button } from "@mui/material";
import AssetContext from "../../../context/asset-context";
import { Agent } from "../../../app/api/agent";
import { enPermission } from "../../../app/models/permissions";
import PermissionsService from "../../../services/permissionsService";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowSize from "../../../app/customHooks";
import { useTranslation } from "react-i18next";
import { Style } from "../../../themes/Style";
export default function ListProporties() {
  const windowHeight = useWindowSize()[0];
  const assetContext = useContext(AssetContext);
  const { partDetails, refreshPartDetails, refreshTree, selectedPart } = assetContext;
  const { properties, systems } = partDetails;
  const { t } = useTranslation();

  const handleDeleteProperty = async (id) => {
    if (id) {
      await Agent.Parts.deleteProperty(id);
      refreshPartDetails();
      refreshTree();
    }
  };

  return (
    <>
      <Box display="flex" p={0}>
        <Box p={1} sx={{ fontWeight: "bold", backgroundColor: "#E5E5E5", width: "100%" }}>
          {selectedPart && selectedPart.name}
        </Box>
      </Box>
      <div
        style={{
          maxHeight: windowHeight - 64 - 10 - 50 - 45 - 50 - 25,
          backgroundColor: "#F2F2F2",
          overflow: "auto",
        }}
      >
        {properties.map((item, i) => {
          return (
            <Box key={item.id} display="flex" p={0} sx={{ borderTop: `1px solid #E5E5E5` }}>
              <Box p={1} width="26%">
                {item.type}
              </Box>
              <Box p={1} width="26%">
                {item.relationship ?? item.name}
              </Box>
              <Box p={1} width="26%">
                {item.value}
              </Box>
              <Box p={1} width="22%" sx={{ display: "flex", justifyContent: "flex-end" }}>
                {PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_WRITE) && (
                  <>
                    <Button
                      sx={Style.textButton}
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteProperty(item.id)}
                    >
                      {t("button.delete")}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
        {systems.map((item) => {
          return (
            <Box key={item.id} display="flex" p={0} sx={{ borderTop: `1px solid #E5E5E5` }}>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.externalSystem}
              </Box>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.systemIdentifier}
              </Box>
              <Box p={1} width="26%" style={{ wordWrap: "break-word" }}>
                {item.description}
              </Box>
              <Box p={1} width="22%" sx={{ display: "flex", justifyContent: "flex-end" }}>
                {PermissionsService.hasPermission(enPermission.ASSET_MANAGEMENT_WRITE) && (
                  <>
                    <Button startIcon={<DeleteIcon />} onClick={() => handleDeleteProperty(item.id)}>
                      {t("button.delete")}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          );
        })}
      </div>
    </>
  );
}
