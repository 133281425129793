import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Bar } from "react-chartjs-2";
import { ChartProps } from "./chartProps";

const useStyles = makeStyles(() => ({
  container: {
    height: "80%",
  },
}));

const BarChart = (chartProps: ChartProps) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Bar
        redraw={true}
        height={chartProps.height}
        data={chartProps.data as any}
        options={
          {
            ...chartProps.options,
            responsive: true,
            maintainAspectRatio: false,
          } as any
        }
      />
    </div>
  );
};

export default BarChart;
