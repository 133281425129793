import React from "react";
import Grid from "@mui/material/Grid";
import { useTranslation } from "react-i18next";
import { Style } from "../../../themes/Style";
import { Button } from "@mui/material";

// TODO: what we really want is React.forwardRef but I cannot figure-out
//  how to type React.ForwardedRef<typeof Grid> (this does not work b/c Grid
/// is generic)
export default React.memo(function Alarmreport({
  alarm,
  setAlarm,
}: {
  alarm: { open: boolean; id: string; name: string; position: string };
  setAlarm: (v: { open: boolean; id: string; name: string; position: string }) => void;
}) {
  const { t } = useTranslation();

  return (
    <Grid
      style={{ borderColor: "red" }}
      sx={Style.report.container}
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="center"
    >
      <h1>{t("castersetup.alarmReport.alarmReport")}</h1>
      <Grid
        sx={Style.report.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p style={Style.report.headLine}>{t("castersetup.alarmReport.partName")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p style={Style.report.headLineValue as React.CSSProperties}>{`${alarm.name}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -40 }}
        sx={Style.report.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p style={Style.report.infoHeadLine}>{t("castersetup.alarmReport.partId")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${alarm.id}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -30 }}
        sx={Style.report.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p style={Style.report.infoHeadLine}>{t("castersetup.alarmReport.installedAt")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p style={Style.report.infoHeadLineValue as React.CSSProperties}>{`${alarm.position}`}</p>
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: -30 }}
        sx={Style.report.item}
        item
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
      >
        <Grid item xs={3}>
          <p style={Style.report.infoHeadLine}>{t("castersetup.alarmReport.alarmCode")}:</p>
        </Grid>
        <Grid item xs={9}>
          <p style={Style.report.infoHeadLineValue as React.CSSProperties}>
            ad605a24-5215-11ec-bf63-0242ac130002
          </p>
        </Grid>
      </Grid>
      <Grid
        sx={Style.report.item}
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={0.1}
      >
        <p style={Style.report.infoHeadLine}>{t("castersetup.alarmReport.alarmText")}:</p>
        <textarea
          style={Style.report.textarea as React.CSSProperties}
          readOnly={true}
          spellCheck="false"
          defaultValue="Reason for alarm"
        />
      </Grid>
      <Grid
        style={{ padding: 5, width: "100%" }}
        item
        container
        direction="column"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Button
          variant="contained"
          sx={Style.darkButton}
          onClick={() => setAlarm({ open: false, id: "", name: "", position: "" })}
        >
          {t("button.close")}
        </Button>
      </Grid>
    </Grid>
  );
});
