import gql from "graphql-tag";
import { Part } from "../models/part";
import { LegacyPartDetail } from "../models/partdetails";
import { PartItem } from "../models/partitem";
import { Plantview, PlantviewTypeEnum, PlantviewTypes } from "../models/plantview";
import { AddViewRelationshipRequest } from "../models/plantview";
import {
  AddPartPropertyRequest,
  AddRelationshipRequest,
  LegacyProperty,
  Property,
  Relationship,
} from "../models/property";
import { System, AddPartSystemRequest } from "../models/system";
import { environment } from "../../environments/environments";
import { DigitalEquipmentCheck } from "../../services/digitalEcService";
import { EDocs } from "../../services/eDocService";
import { GeniusCMService } from "../../services/geniusCmService";
import { request } from "./serviceBase";
import { Ontology } from "./ontology";

import type {
  PartDetailsQueryVariables,
  PartDetailsQuery,
  PartItemsQuery,
  PartItemsQueryVariables,
  CreatePartMutation,
  CreatePartMutationVariables,
  DeletePartMutation,
  DeletePartMutationVariables,
  AddPropertyMutation,
  AddPropertyMutationVariables,
  AddEdgeMutation,
  AddEdgeMutationVariables,
  AddSystemMutation,
  AddSystemMutationVariables,
  DeleteEdgeMutation,
  DeleteEdgeMutationVariables,
  AllPropertiesQuery,
  AllPropertiesQueryVariables,
  PlantviewsFilteredQuery,
  PlantviewsFilteredQueryVariables,
  PlantviewsChildrenQuery,
  PlantviewsChildrenQueryVariables,
  ReferenceDataSystemsQuery,
  ReferenceDataSystemsQueryVariables,
} from "../models/graphql-types";

const baseURL = environment.ASSET_DICTIONARY_API;

type uuid = string; // TODO: make this  more restrictive?

export enum RelationshipTypeEnum {
  INSTALLABLE_PARTS = -1, // this does not exist in Asset Dictionary 1
  HAS_INSTALLATION_PLACE = 4,
  CAN_BE_INSTALLED_AT = 5,
  SUPPLIES_ELECTRICAL = 6,
  SUPPLIES_HYDRAULICAL = 7,
  IS_TYPE_OF = 8,
}

export enum NodeTypesEnum {
  SITE = 1,
  ENTERPRISE = 2,
  AREA = 3,
  PROCESSCELL = 4,
  UNIT = 5,
  EQUIPMENTMODULE = 6,
  CONTROLMODULE = 7,
  INSTALLATIONPLACE = 8,
  DATASOURCE = 9,
  SUPPLY = 10,
}

class DeprecationError extends Error {}

// /api/Parts/{id}/Properties returns the relation
// description as 'relationship'. In assetdictionary2
// relationsip names are camel cased.
const RELATIONSHIP_MAP: { [k: string]: string } = {
  allowedType: "Allowed type",
  isTypeOf: "Is type of",
  isInMaterialFlowBefore: "Is in material flow before",
  supplies: "Supplies",
  hasInstallationPlace: "Has installation place",
  hasId: "Has Id",
  canBeInstalledAt: "Can be installed at",
  electricalSupply: "Electrical supply",
  hydraulicalSupply: "Hydraulical supply",
};

const propertiesRelationshipsSystemsFragment = gql`
  fragment PropertiesRelationshipsSystemsFragment on AssetNode {
    properties: attributes {
      partId: nodeId
      name
      type
      value
    }
    relationships: outEdges {
      id
      relationship: name
      start {
        partId: id
      }
      end {
        name
        # only relevant for relationships
        relatedPartId: id
        # next two only relevant for systems
        systemIdentifier: attributes(name: "externalId") {
          value
        }
        datasource: outEdges(name: "datasource") {
          end {
            description: attributes(name: "remarks") {
              value
            }
            externalSystem: name
          }
        }
      }
    }
  }
`;

function _handlePropertiesRelationshipsSystemsResult(
  node: PartDetailsQuery["node"],
  properties_?: Property[],
  relationships_?: Relationship[],
  systems_?: System[]
) {
  const properties = properties_ ?? ([] as Property[]);
  const relationships = relationships_ ?? ([] as Relationship[]);
  const systems = systems_ ?? ([] as System[]);

  if (!node || !["InstallationPlace", "Unit", "EquipmentModule"].includes(node.__typename)) {
    return { properties, relationships, systems };
  } else {
    const node_ = node;

    node_.properties.forEach((p) => {
      const value = p.type.startsWith("String") ? JSON.parse(p.value) : p.value;

      properties!.push({
        id: `${p.partId}/${p.name}`,
        partId: p.partId,
        name: p.name,
        value,
      });
    });

    node_.relationships.forEach((r) => {
      if (r.relationship !== "externalSystems") {
        let start = r.start.partId;
        let end = r.end.relatedPartId;

        if (r.relationship === "canBeInstalledAt") {
          // yikes, DB schema limitation leaking into the front-end ...
          // rule 'install_reverse' in legacy Asset Dictionary
          [start, end] = [end, start];
        }

        relationships!.push({
          id: r.id,
          partId: start,
          relationship: RELATIONSHIP_MAP[r.relationship] ?? r.relationship,
          relatedPartId: end,
        });
      } else {
        systems!.push({
          id: r.id,
          partId: r.start.partId,
          systemIdentifier: JSON.parse(r.end.systemIdentifier[0].value),
          description: JSON.parse(r.end.datasource[0].end.description[0]?.value || '""'),
          externalSystem: r.end.datasource[0].end.externalSystem,
        });
      }
    });
  }

  return { properties, relationships, systems };
}

const Parts = {
  async details(part: uuid) {
    const query = gql`
      query PartDetails($partId: ID!) {
        node(id: $partId) {
          # TODO: this is PropertyRelationshipsSystemsFragment but
          #  fragment spreads seem to crash graphql-codegen
          __typename
          ... on AssetNode {
            properties: attributes {
              partId: nodeId
              name
              type
              value
            }
            relationships: outEdges {
              id
              relationship: name
              start {
                partId: id
              }
              end {
                name
                # only relevant for relationships
                relatedPartId: id
                # next two only relevant for systems
                systemIdentifier: attributes(name: "externalId") {
                  value
                }
                datasource: outEdges(name: "datasource") {
                  end {
                    description: attributes(name: "remarks") {
                      value
                    }
                    externalSystem: name
                  }
                }
              }
            }
          }
        }
      }
    `;

    const result = (await request.execute<PartDetailsQuery, PartDetailsQueryVariables>(query, {
      partId: part,
    })) ?? {
      node: {
        __typename: "Datasource",
        properties: [],
        relationships: [],
        systems: [],
      },
    };

    const { properties, relationships, systems } = _handlePropertiesRelationshipsSystemsResult(result.node);

    return { properties, relationships, systems };
  },

  async list(part?: string) {
    const listQuery = gql`
      query PartItems {
        nodes {
          ... on AssetNode {
            id
            name
            remarks: attributes(name: "remarks") {
              value
            }
            nodeType: attributes(name: "__type") {
              value
            }
          }
        }
      }
    `;

    const resultList = await request.execute<PartItemsQuery, PartItemsQueryVariables>(listQuery, {});

    const response = await resultList?.nodes?.filter((n) => n.name && n.name.startsWith(part || n.name));
    const result =
      response?.map((r) => ({
        id: r.id,
        name: r.name,
        remarks: JSON.parse(r.remarks[0]?.value || '""'),
        nodeType: JSON.parse(r.nodeType[0]!.value).toUpperCase(),
      })) || [];

    return result;
  },

  async getAllProperties() {
    try {
      const query = gql`
        query AllProperties {
          nodes {
            # TODO: this is PropertyRelationshipsSystemsFragment but
            #  fragment spreads seem to crash graphql-codegen
            __typename
            ... on AssetNode {
              properties: attributes {
                partId: nodeId
                name
                type
                value
              }
              relationships: outEdges {
                id
                relationship: name
                start {
                  partId: id
                }
                end {
                  name
                  # only relevant for relationships
                  relatedPartId: id
                  # next two only relevant for systems
                  systemIdentifier: attributes(name: "externalId") {
                    value
                  }
                  datasource: outEdges(name: "datasource") {
                    end {
                      description: attributes(name: "remarks") {
                        value
                      }
                      externalSystem: name
                    }
                  }
                }
              }
            }
          }
        }
      `;

      const result = (await request.execute<AllPropertiesQuery, AllPropertiesQueryVariables>(query, {})) ?? {
        nodes: [],
      };

      const properties = [] as Property[];
      const relationships = [] as Relationship[];
      const systems = [] as System[];

      result.nodes?.forEach((n) => {
        if (["InstallationPlace", "Unit", "EquipmentModule"].includes(n?.__typename)) {
          _handlePropertiesRelationshipsSystemsResult(n, properties, relationships, systems);
        }
      });

      return { properties, relationships, systems };
    } catch (error) {
      console.error(error);
    }
  },

  async create(part: Part) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const mutation = gql`
      mutation CreatePart($name: String!, $type: String!, $remarks: String = "") {
        storeAssetNode(name: $name, type: $type, attributes: { name: "remarks", stringValue: $remarks }) {
          assetNode {
            id
          }
        }
      }
    `;

    const result = await request.execute<CreatePartMutation, CreatePartMutationVariables>(mutation, {
      // TODO: need to move nodeTypeId -> nodeType
      name: part.name,
      type: `${part.nodeType}`,
      remarks: part.remarks,
    });

    return await request.post<void>(`${baseURL}/Parts`, part);
  },

  async delete(id: string) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const mutation = gql`
      mutation DeletePart($partId: ID!) {
        deleteAssetNode(id: $partId) {
          edges {
            old {
              id
            }
          }
        }
      }
    `;

    const result = await request.execute<DeletePartMutation, DeletePartMutationVariables>(mutation, {
      partId: id,
    });

    return await request.delete<Part>(`${baseURL}/Parts/${id}`);
  },

  async addProperty(property: AddPartPropertyRequest) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const addPropertyMutation = gql`
      mutation AddProperty($partId: ID!, $attrName: String!, $attrValue: String!) {
        storeAssetNode(id: $partId, attributes: [{ name: $attrName, stringValue: $attrValue }]) {
          assetNode {
            id
          }
        }
      }
    `;

    const result = await request.execute<AddPropertyMutation, AddPropertyMutationVariables>(
      addPropertyMutation,
      { partId: property.partId, attrName: property.name!, attrValue: property.value }
    );

    return await request.post<void>(`${baseURL}/Parts/Properties`, property);
  },

  async addRelationship(relationship: AddRelationshipRequest) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const edgeName =
      relationship.relationshipId === RelationshipTypeEnum.HAS_INSTALLATION_PLACE
        ? "hasInstallationPlace"
        : relationship.relationshipId === RelationshipTypeEnum.CAN_BE_INSTALLED_AT
        ? "canBeInstalledAt"
        : relationship.relationshipId === RelationshipTypeEnum.INSTALLABLE_PARTS
        ? "installableParts"
        : relationship.relationshipId === RelationshipTypeEnum.SUPPLIES_ELECTRICAL
        ? "suppliesElectrical"
        : relationship.relationshipId === RelationshipTypeEnum.SUPPLIES_HYDRAULICAL
        ? "suppliesHydraulical"
        : `unknownRelationshipId_${relationship.relationshipId}`;

    const addEdgeMutation = gql`
      mutation AddEdge($partId: ID!, $edgeName: String!, $target: ID!) {
        storeAssetNode(id: $partId, edges: [{ name: $edgeName, ends: [{ id: $target }] }]) {
          assetNode {
            id
          }
        }
      }
    `;

    const result = await request.execute<AddEdgeMutation, AddEdgeMutationVariables>(addEdgeMutation, {
      partId: relationship.partId,
      edgeName: edgeName,
      target: relationship.relatedPartId,
    });

    return await request.post<void>(`${baseURL}/Parts/Properties`, relationship);
  },

  async addSystem(property: AddPartSystemRequest) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const mutation = gql`
      mutation AddSystem(
        $partName: String!
        $linkNodeName: String!
        $extSystemName: String!
        $idInExtSystem: String!
      ) {
        storeAssetNode(
          name: $partName
          edges: [
            {
              name: "externalSystems"
              ends: [
                {
                  name: $linkNodeName
                  attributes: [{ name: "externalSystemId", stringValue: $idInExtSystem }]
                  edges: [{ name: "datasource", ends: [{ name: $extSystemName }] }]
                }
              ]
            }
          ]
        ) {
          assetNode {
            id
          }
        }
      }
    `;

    const result = await request.execute<AddSystemMutation, AddSystemMutationVariables>(mutation, {
      partName: property.partName,
      linkNodeName: `${property.systemIdentifier}[${property.partName}]`,
      extSystemName: property.externalSystemId,
      idInExtSystem: property.externalSystemId,
    });

    return await request.post<void>(`${baseURL}/Parts/Properties`, property);
  },

  async deleteProperty(partId: string, name: string) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const mutation = gql`
      mutation DeleteProperty($partId: ID!, $name: String!) {
        deleteAssetNodeAttribute(nodeId: $partId, name: $name) {
          old {
            id
          }
        }
      }
    `;

    return await request.delete<Part>(`${baseURL}/Parts/Properties/${partId}`);
  },

  async deleteRelationship(id: string) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");

    const mutation = gql`
      mutation DeleteEdge($id: ID!) {
        deleteAssetEdge(id: $id) {
          nodes {
            new {
              id
            }
          }
        }
      }
    `;

    const result = await request.execute<DeleteEdgeMutation, DeleteEdgeMutationVariables>(mutation, { id });

    return await request.delete<Part>(`${baseURL}/Parts/Properties/${id}`);
  },
};

const Relationships = {
  list: () => [
    // TODO:hardcoded for now, RelationshipTypes do not make sense with new API
    {
      id: 1,
      name: "ALLOWED_TYPE",
      description: "Allowed type",
      rule: "attribute",
    },
    {
      id: 2,
      name: "IS_TYPE_OF",
      description: "Is type of",
      rule: "attribute",
    },
    {
      id: 3,
      name: "IS_IN_MATERIALFLOW_BEFORE",
      description: "Is in material flow before",
      rule: "install",
    },
    {
      id: 4,
      name: "SUPPLIES",
      description: "Supplies",
      rule: "install",
    },
    {
      id: 5,
      name: "HAS_INSTALLATION_PLACE",
      description: "Has installation place",
      rule: "install",
    },
    {
      id: 6,
      name: "HAS_ID",
      description: "Has Id",
      rule: "system",
    },
    {
      id: 7,
      name: "CAN_BE_INSTALLED_AT",
      description: "Can be installed at",
      rule: "install_reverse",
    },
    {
      id: 8,
      name: "SUPPLIES_ELECTRICAL",
      description: "Electrical supply",
      rule: "install",
    },
    {
      id: 9,
      name: "SUPPLIES_HYDRAULICAL",
      description: "Hydraulical supply",
      rule: "install",
    },
  ],
};

const PropertyTypes = {
  list: () => [
    // TODO: hardcoded for now, PropertyTypes do not make sense with new API
    {
      id: 1,
      name: "ATTRIBUTE",
      description: "Attribute",
    },
    {
      id: 2,
      name: "RELATIONSHIP",
      description: "Relationship",
    },
  ],
};

const Plantviews = {
  list() {
    return Object.keys(PlantviewTypeEnum);
  },

  async filtered(startingPartName: string, viewType: PlantviewTypes = "MECHANICAL") {
    const query = gql`
      query PlantviewsFiltered($name: String!, $relations: [String!]!) {
        nodeByName(name: $name) {
          ... on AssetNode {
            id
            name
            children: outEdges(names: $relations) {
              end {
                id
                name
              }
            }
          }
        }
      }
    `;
    const relations = {
      HYDRAULICAL: ["suppliesHydraulical"],
      ELECTRICAL: ["suppliesElectrical"],
      MECHANICAL: ["hasInstallationPlace", "installableParts"],
    }[viewType];

    const response = (
      await request.execute<PlantviewsFilteredQuery, PlantviewsFilteredQueryVariables>(query, {
        name: startingPartName.toUpperCase(),
        relations,
      })
    )?.nodeByName;

    if (response) {
      return {
        id: response.id,
        name: response.name,
        children: response.children.map((n) => ({ ...n.end, children: [] })),
      };
    }
  },

  async children(id: string, viewType: PlantviewTypes = "MECHANICAL") {
    const query = gql`
      query PlantviewsChildren($id: ID!, $relations: [String!]!) {
        node(id: $id) {
          ... on AssetNode {
            id
            name
            children: outEdges(names: $relations) {
              end {
                id
                name
              }
            }
          }
        }
      }
    `;
    const relations = {
      HYDRAULICAL: ["suppliesHydraulical"],
      ELECTRICAL: ["suppliesElectrical"],
      MECHANICAL: ["hasInstallationPlace", "installableParts"],
    }[viewType];

    const response = (
      await request.execute<PlantviewsChildrenQuery, PlantviewsChildrenQueryVariables>(query, {
        id,
        relations,
      })
    )?.node;

    if (response) {
      return {
        id: response.id,
        name: response.name,
        children: response.children.map((n) => ({ ...n.end, children: [] })),
      };
    }
  },

  all: (startingPart: string) => {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");
    return request.get<Plantview>(`${baseURL}/Plantviews/${startingPart}`);
  },

  relationships(viewType: PlantviewTypes = "MECHANICAL") {
    return {
      HYDRAULICAL: [{ id: 7, name: "SUPPLIES_HYDRAULICAL" }],
      ELECTRICAL: [{ id: 6, name: "SUPPLIES_ELECTRICAL" }],
      MECHANICAL: [
        { id: 4, name: "HAS_INSTALLATION_PLACE" },
        { id: 5, name: "CAN_BE_INSTALLED_AT" },
      ],
    }[viewType];
  },

  async addRelationship(relationship: AddViewRelationshipRequest) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");
  },

  async deleteRelationship(id: number) {
    throw new DeprecationError("This is part of the legacy Asset Dictionay API");
  },
};

const ReferenceData = {
  async getSystems() {
    const query = gql`
      query ReferenceDataSystems {
        nodes {
          __typename
          ... on Datasource {
            id
            name
            description
          }
        }
      }
    `;

    /*const response =
      (await request.execute<ReferenceDataSystemsQuery, ReferenceDataSystemsQueryVariables>(query, {}))
        ?.nodes ?? [];*/
    const response: any[] = [];

    // Is this really the best you can do with TS?
    // first does not narrow the discriminant union after filter, then
    // does not get the if(...) is always true, and types the result as
    // ({ id: string, name: string, description?: string } | undefined)[]
    // unless explicitly asserted. WTF?
    return response
      .filter((s) => s.__typename === "Datasource")
      .map((s) => {
        if (s.__typename === "Datasource") {
          return { id: s.id, name: s.name, description: s.description };
        }
      }) as { id: string; name: string; description?: string }[];
  },
  getNodeTypes: () => Object.keys(NodeTypesEnum),
};

export const Agent = {
  Parts,
  Relationships,
  PropertyTypes,
  Plantviews,
  Ontology,
  ReferenceData,
  DigitalEquipmentCheck,
  EDocs,
  GeniusCMService,
};
