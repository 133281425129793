/* This is gathering data acquisition via suspense for data fetching

   Resources implement interface `Resource` where the `keys` argument to
   `read` can be arbitrary values to identify the resource (typically a
    single `id: uuid`).

    Resources might cache data and `refresh` should update linked resources
    as well.
*/
import { AddPartPropertyRequest } from "../app/models/property";
import { Agent } from "../app/api/agent";
import { awaitSuspender, Suspender, wrapPromise } from "../utils/suspender";
import { DECJobs } from "../app/models/decJobs";

import { DateFields } from "../components/DateUtils/dates.interface";

export interface Resource<T> {
  read(...key: any[]): T;
  preload(...key: any[]): Suspender<T>;
  refresh(dates?: DateFields): void;
}

export class InstallablePart {
  id: string;
  name: string;
  remarks: string;

  installableAt: InstallationPosition[] = [];
  installationPostions: InstallationPosition[] = [];

  currentlyInstalledAt: InstallationPosition | null = null;
  properties: { [name: string]: any } = {};
  systems: { [name: string]: string } = {};

  constructor(id: string, name: string, remarks: string) {
    this.id = id;
    this.name = name;
    this.remarks = remarks;
  }

  isRoot() {
    return this.installableAt.length === 0;
  }
}

export class InstallationPosition {
  id: string;
  name: string;
  remarks: string;

  belongsTo: InstallablePart;
  installableParts: InstallablePart[] = [];
  currentlyInstalled: InstallablePart | null = null;
  properties: { [name: string]: any } = {};
  systems: { [name: string]: string } = {};

  constructor(id: string, name: string, remarks: string, belongsTo: InstallablePart) {
    this.id = id;
    this.name = name;
    this.remarks = remarks;
    this.belongsTo = belongsTo;
  }
}

export async function loadInstallationTree() {
  const partsById: { [id: string]: any } = {};
  const partSystems: { [id: string]: any } = {};
  const partRelationships: { [id: string]: any } = {};
  const partProperties: { [id: string]: any } = {};

  await Promise.all([
    Agent.Parts.list().then((parts) => {
      parts.forEach((p) => {
        partsById[p.id] = p;
      });
    }),
    Agent.Parts.getAllProperties().then((propertiesSystems) => {
      propertiesSystems?.properties?.forEach((pp) => {
        partProperties[pp.partId] = [...(partProperties[pp.partId] || []), pp];
      });
      propertiesSystems?.relationships?.forEach((pp) => {
        partRelationships[pp.partId] = [...(partRelationships[pp.partId] || []), pp];
      });
      propertiesSystems?.systems?.forEach((ps) => {
        partSystems[ps.partId] = [...(partSystems[ps.partId] || []), ps];
      });
    }),
  ]);

  if (!Object.values(partsById).length) return { roots: [], installableParts: {}, installationPositions: {} };

  // guess the type of part if it has relationship "Has installation place" -> InstallablePart
  // if it has relationship "Can be installed at"  -> InstallationPosition
  // Then do second pass and look at what is referenced by InstallablePart.installationPositions
  // and InstallationPosition.installableParts
  // this corresponds to NodeType (5/UNIT, 6/EQUIPMENTMODULE) -> InstallablePart,
  // (8/INSTALLATIONPLACE) -> InstallationPosition
  Object.values(partsById).forEach((p: any) => {
    const relationships: any[] = [];
    const edges: string[] = [];
    const properties: { [name: string]: string } = {};
    const systems: { [name: string]: string } = {};

    const partId = p.id;

    partProperties[partId]?.forEach((pp: any) => {
      properties[pp.name] = pp.value;
    });

    partRelationships[partId]?.forEach((pp: any) => {
      if (pp.relationship === "Has installation place") {
        edges.push(pp.relatedPartId);
      } else if (pp.relationship === "Can be installed at") {
        edges.push(pp.relatedPartId);
      } else {
        // eslint-disable-next-line no-unused-vars
        const { relationship, name, value, relatedPartId, ...rest } = pp;
        relationships.push({ relationship, name, value, relatedPartId });
      }
    });

    partSystems[partId]?.forEach((ps: any) => {
      systems[ps.externalSystem] = ps.systemIdentifier;
    });

    if (["UNIT", "EQUIPMENTMODULE"].includes(p.nodeType)) {
      partsById[p.id] = {
        ...p,
        installationPositions: edges,
        relationships,
        properties,
        systems,
        installableAt: [],
      };
    } else if (p.nodeType === "INSTALLATIONPLACE") {
      partsById[p.id] = {
        ...p,
        installableParts: edges,
        relationships,
        properties,
        systems,
        belongsTo: undefined,
      };
    } else partsById[p.id] = { ...p, relationships, properties, systems };
  });

  Object.values(partsById).forEach((p) => {
    if (["UNIT", "EQUIPMENTMODULE"].includes(p.nodeType)) {
      p.installationPositions.forEach((ip: string) => {
        const position = partsById[ip];

        if (position.nodeType !== "INSTALLATIONPLACE")
          throw new Error(`Part ${p.id} requires ${ip} to be an 'InstallationPosition'`);
        if (position.belongsTo)
          throw new Error(
            `Position must be belong of at most one part, found [${position.belongsTo}, ${p.id}]`
          );

        position.belongsTo = p.id;
      });
    } else if (p.nodeType === "INSTALLATIONPLACE") {
      p.installableParts.forEach((ip: string) => {
        const part = partsById[ip];
        if (!["UNIT", "EQUIPMENTMODULE"].includes(part.nodeType))
          throw new Error(`Position ${p.id} requires ${ip} to be an 'InstallablePart'`);

        part.installableAt.push(p.id);
      });
    }
  });

  const roots: InstallablePart[] = [];
  const installableParts: { [id: string]: InstallablePart } = {};
  const installationPositions: { [id: string]: InstallationPosition } = {};

  Object.values(partsById).forEach((p) => {
    if (["UNIT", "EQUIPMENTMODULE"].includes(p.nodeType) && p.installableAt.length === 0) {
      const part = new InstallablePart(p.id, p.name, p.remarks);
      part.properties = p.properties;
      part.systems = p.systems;
      roots.push(part);
      installableParts[part.id] = part;
    }
  });

  function traversePart(part: InstallablePart) {
    const data = partsById[part.id];

    data.installationPositions.forEach((positionId: number) => {
      let position = installationPositions[positionId];
      if (!position) {
        const positionData = partsById[positionId];
        installationPositions[positionId] = position = new InstallationPosition(
          positionData.id,
          positionData.name,
          positionData.remarks,
          part
        );
        position.properties = positionData.properties;
        position.systems = positionData.systems;
        traversePosition(position);
      }
      part.installationPostions.push(position);
    });
  }

  function traversePosition(position: InstallationPosition) {
    const data = partsById[position.id];
    data.installableParts.forEach((partId: number) => {
      let part = installableParts[partId];
      if (!part) {
        const partData = partsById[partId];
        installableParts[partId] = part = new InstallablePart(partData.id, partData.name, partData.remarks);
        part.properties = partData.properties;
        part.systems = partData.systems;
        traversePart(part);
      }
      if (!part.installableAt.includes(position)) part.installableAt.push(position);
      position.installableParts.push(part);
    });

    // TODO: this information needs to be retrieved from some other system
    position.currentlyInstalled = position.installableParts[0];
  }

  roots.forEach((r) => traversePart(r));

  // fix-up part.currentlyInstalledAt
  // TODO: this information needs to be retrieved from some other system
  Object.values(installableParts).forEach((part) => {
    part.currentlyInstalledAt = part.installableAt[0];
  });

  return { roots, installableParts, installationPositions };
}

export class InstallationTreeResource
  implements
    Resource<{
      roots: InstallablePart[];
      installableParts: { [id: string]: InstallablePart };
      installationPositions: { [id: string]: InstallationPosition };
    }>
{
  suspender?: Suspender<{
    roots: InstallablePart[];
    installableParts: { [id: string]: InstallablePart };
    installationPositions: { [id: string]: InstallationPosition };
  }>;
  constructor() {
    this.suspender = undefined;
  }
  preload() {
    if (!this.suspender) this.suspender = wrapPromise(loadInstallationTree());
    return this.suspender;
  }
  read() {
    if (!this.suspender) this.suspender = wrapPromise(loadInstallationTree());
    return this.suspender.read();
  }
  readPart(partId: string) {
    const { installableParts } = this.read();
    return installableParts[partId];
  }
  readPosition(positionId: string) {
    const { installationPositions } = this.read();
    return installationPositions[positionId];
  }
  readDefaultRoot() {
    const { roots } = this.read();
    return roots[0];
  }
  updateProperty(payload: AddPartPropertyRequest) {
    const prev = this.preload();

    const worker = Agent.Parts.addProperty(payload).then(() => {
      return loadInstallationTree();
    });

    this.suspender = wrapPromise(worker);

    return worker;
  }
  refresh() {
    this.suspender = wrapPromise(loadInstallationTree());
  }
}

export const installationTreeResource = new InstallationTreeResource();

export class DECResource implements Resource<DECJobs[]> {
  _cache: { [id: string]: Suspender<DECJobs[]> } = {};

  preload(decId: string) {
    let suspender = this._cache[decId];
    if (!suspender) {
      this._cache[decId] = suspender = wrapPromise(Promise.resolve(Agent.DigitalEquipmentCheck.jobs(decId)));
    }
    return suspender;
  }

  read(decId: string) {
    const suspender = this.preload(decId);
    return suspender.read();
  }

  refresh(dates: DateFields) {
    Object.keys(this._cache).forEach((decId) => {
      this._cache[decId] = wrapPromise(Promise.resolve(Agent.DigitalEquipmentCheck.jobs(decId, dates)));
    });
  }
}

export const maintenanceHistoryResource = new DECResource();

/* Below are mocks for data not yet available from the backend(s)
   TODO: these need to be replaced by proper API calls
*/
class ResourceMockUp<T> implements Resource<T> {
  _cache: { [k: string]: Suspender<T> };
  _data: { [k: string]: T };

  constructor(data: { [k: string]: T }) {
    this._cache = {};
    this._data = data;
  }

  preload(id: string) {
    let suspender = this._cache[id];
    if (!suspender) {
      let data = this._data[id];
      if (data === undefined && Object.hasOwnProperty.call(this._data, "default"))
        data = this._data["default"];

      this._cache[id] = suspender = wrapPromise(Promise.resolve(data));
    }
    return suspender;
  }

  read(id: string) {
    let suspender = this.preload(id);
    return suspender.read();
  }

  refresh() {
    Object.keys(this._cache).forEach((id) => {
      this._cache[id] = wrapPromise(Promise.resolve(this._data[id]));
    });
  }
}

class ChangeHistoryResource extends ResourceMockUp<any> {
  preload(id: string) {
    let suspender = this._cache[id];
    if (!suspender) {
      const worker = awaitSuspender(installationTreeResource.preload()).then((result) => {
        const position = (result as any).installationPositions[id];
        return this._data["default"]
          .map((item: any, ii: number) => {
            const part = position.installableParts[ii % position.installableParts.length];
            if (part) return { ...item, id: part.id, partName: part.name };
            else return undefined;
          })
          .filter((v: any) => v !== undefined);
      });

      this._cache[id] = suspender = wrapPromise(worker);
    }
    return suspender;
  }
}

export const changeHistoryResource = new ChangeHistoryResource({
  default: [
    {
      date: new Date().toLocaleDateString(),
      reason: "Heat limit reached",
    },
    {
      date: new Date().toLocaleDateString(),
      reason: "Brearing broken",
    },
    {
      date: new Date().toLocaleDateString(),
      reason: "Hose repair",
    },
    {
      date: new Date().toLocaleDateString(),
      reason: "Planning change",
    },
  ],
});
