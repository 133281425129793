import React, { useEffect, useState, useCallback } from "react";

//charts
import PolarChart from "../availableCharts/polar";
import LineChart from "../availableCharts/line";
import BarChart from "../availableCharts/bar";
import HorizontalChart from "../availableCharts/horizontal";
import DoughnutChart from "../availableCharts/doughnut";
import PieChart from "../availableCharts/pie";
import Gauge from "../availableCharts/gauge";
import { Agent } from "../../../../app/api/agent";

import { colorScheme } from "../../../../components/ResizeblePanels/components/panels/colorScheme";
import { Pannel } from "../../../../app/models/presentation";
import { ChartData, ChartOptions } from "chart.js";
import { RUNNER_ROW_HEIGHT } from "../../../../constants";

interface Props {
  dashboardItem: Pannel;
  presentationMode?: boolean;
  startDate?: Date;
  endDate?: Date;
  daily: number;
}

const DashboardPanels = ({ dashboardItem, presentationMode, startDate, endDate, daily }: Props) => {
  const [chartData, setChartData] = useState<any>();

  const getText = (dashboardItem: Pannel) => {
    let text = "";
    if (dashboardItem.pannelContent) {
      text = `${
        dashboardItem.pannelContent && dashboardItem.pannelContent.alias
          ? dashboardItem.pannelContent.alias
          : dashboardItem.pannelContent.label ?? ""
      } ${
        dashboardItem.pannelContent && dashboardItem.pannelContent.unit
          ? `- in ${dashboardItem.pannelContent?.unit}`
          : ""
      }`;
    }
    return text;
  };

  const [options] = useState<ChartOptions>({
    responsive: true,
  });

  const { data } = chartData ?? [];

  const [chartContent, setChartContent] = useState<ChartData>({ datasets: [] });

  const prepareDataSet = useCallback(
    (day: any, datasets: any[]) => {
      if (!chartData) return [];

      for (let index = 0; index <= day.length; index++) {
        if (!datasets[index]) {
          let datasetItem = {
            data: [day[index + 1]],
            fill: false,
            key: index,
            label: chartData.header[index + 1],
            borderColor: colorScheme[index],
            option: {
              legend: false,
            },
          };
          if (datasetItem.label) {
            datasets.push(datasetItem);
          }
        } else {
          datasets[index].data.push(day[index + 1]);
        }
      }
      return datasets;
    },
    [chartData]
  );

  const prepareDatasources = useCallback(() => {
    if (!chartData) return;
    let currentLabels = [];
    let datasets: any[] = [];

    if (data && Array(data).length > 0) {
      currentLabels = data.map((data: any) => data[0]);
      data.forEach((day: any) => {
        if (day[1] !== undefined) {
          if (Array(day) && !day[1].length) {
            datasets = prepareDataSet(day, datasets);
          } else {
            datasets = prepareDataSet(day[1], datasets);
          }
        }
      });
    }

    setChartContent({
      labels: currentLabels,
      datasets: datasets,
    });
  }, [chartData, data, prepareDataSet]);

  useEffect(() => {
    prepareDatasources();
  }, [data, prepareDatasources]);

  useEffect(() => {
    (async () => {
      if (presentationMode) {
        // for while Genius CM
        let data = await Agent.GeniusCMService.getData(
          startDate,
          endDate,
          dashboardItem.pannelContent?.partId,
          daily
        );
        setChartData(data);
      }
    })();
  }, [startDate, endDate, presentationMode, dashboardItem.pannelContent?.partId, daily]);

  const renderChart = () => {
    const heigth = dashboardItem.pannelLocation ? dashboardItem.pannelLocation.h : 35;
    const maxHeight = RUNNER_ROW_HEIGHT * heigth * 0.4;
    switch (dashboardItem.visualization_type) {
      case "Line":
        return <LineChart data={chartContent} options={options} height={maxHeight} />;
      case "Pie":
        return <PieChart data={chartContent} options={options} height={maxHeight} />;
      case "Polar":
        return <PolarChart data={chartContent} options={options} height={maxHeight} />;
      case "BarChart":
        return <BarChart data={chartContent} options={options} height={maxHeight} />;
      case "Horizontal":
        return <HorizontalChart data={chartContent} options={options} height={maxHeight} />;
      case "Doughnut":
        return <DoughnutChart data={chartContent} options={options} height={maxHeight} />;
      case "Gauge":
        return <Gauge />;

      default:
        return <LineChart data={chartContent} options={options} height={maxHeight} />;
    }
  };

  return renderChart();
};

export default DashboardPanels;
